import React, { FC } from "react";
import { useSelector } from "react-redux";
import InfoText from "../../../components/InfoText/InfoText";
import { StandardRoom } from "../../../types/HeatLoad";
import { RoomDimensions } from "../../types/Room";
import { ProductDetailsForm } from "../../types/ProductDetailsForm";
import ProductDetailsContainer from "./ProductDetailsContainer";

const ProductDetailsParent: FC<{}> = () => {
  const roomDetails: StandardRoom = useSelector((state: any) => {
    return state.heatLoad.room;
  });

  const productDetailsEnabled: boolean = useSelector((state: any) => {
    return state.heatLoad.formSections.productDetailsEnabled;
  });

  const roomDimensions: RoomDimensions = useSelector((state: any) => {
    return state.heatLoad.roomDimensions;
  });

  const roomTemperature = roomDetails.temperature;

  const roomVolume = roomDimensions.volume;

  let defaultValues: ProductDetailsForm | undefined;

  if (productDetailsEnabled) {
    defaultValues = {
      products: [
        {
          product:
            roomTemperature >= 0
              ? {
                  label: "General chilled goods",
                  value: "general-chilled-goods",
                }
              : {
                  label: "General frozen goods",
                  value: "general-frozen-goods",
                },
          amount: Math.round(roomVolume * 16),
          enteringTemp: roomTemperature + 6,
          finalTemp: roomTemperature,
          pullDownTime: 12,
        },
      ], //Initialise produce with 1 entry
    };
  }

  return productDetailsEnabled && defaultValues !== undefined ? (
    <ProductDetailsContainer
      preloadedValues={defaultValues}
      roomTemperature={roomTemperature}
      productDetailsEnabled={productDetailsEnabled}
    />
  ) : (
    <InfoText testid="no-product-details">
      Add your Product details after you’ve defined your room and transmission
      details.
    </InfoText>
  );
};

export default ProductDetailsParent;
