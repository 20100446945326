import React from "react";
import styles from "./Thumbnail.module.css";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";

const ThumbnailLoadingPlaceholder = () => (
  <div className={styles.container}>
    <LoadingPlaceholder />
  </div>
);

export default ThumbnailLoadingPlaceholder;
