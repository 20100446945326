import { useEffect, useState } from "react";
import { Service } from "../../types/Service";
import { EvaporatorProductCapacities } from "../../types/EvaporatorProductCapacity";
import { EvaporatorSearchRequest } from "../../types/EvaporatorSearchRequest";
import { useSelector, useDispatch } from "react-redux";
import { default as actionSelector } from "../duck/selectors";
import getUrl from "../../helpers/api";
import EvaporatorResponse from "../../types/EvaporatorResponse";
import { FilterValues } from "../../types/FilterValues";
import actions from "../duck/actions";
import { addFilterSelectionsToUrl } from "./useEvaporatorProducts";

declare const window: any;
const ENDPOINT_EVAPORATORS = `${window.__runtime_configuration.ENDPOINT_EVAPORATORS}/call/evaporator`;

const useSingleEvaporatorSearch = (): {
  evaporatorService: Service<EvaporatorProductCapacities>;
} => {
  const [result, setResult] = useState<Service<EvaporatorProductCapacities>>({
    status: "init",
  });

  const dispatch = useDispatch();

  const roomTempBelowZero = useSelector(
    (state: any) => state.product.roomTempBelowZero
  );

  const filterSelections: FilterValues = useSelector(
    (state: any) => state.product.evaporatorFilterSelections
  );

  const filter: EvaporatorSearchRequest = useSelector((state: any) => {
    return state.product.evaporatorSingleSearchRequest;
  });

  if (filter.roomTemp < 0) {
    dispatch(actions.roomTempBelowZero(true));
  } else {
    dispatch(actions.roomTempBelowZero(false));
  }

  const getEvaporators = () => {
    setResult({ status: "loading" });
    let url = createEvaporatorSearchUrlWithFilterParameters(
      filter,
      filterSelections,
      roomTempBelowZero
    );

    getUrl<EvaporatorResponse>(url.href)
      .then((response) => {
        dispatch(actionSelector.loadingBalancePerformance(false));
        setResult({
          status: "loaded",
          payload: { ...response, filterSelections },
        });
      })
      .catch((error) => setResult({ status: "error", error }));
  };

  const setResultToInitialState = () => {
    setResult({ status: "init" });
    dispatch(actionSelector.loadingBalancePerformance(false));
  };

  useEffect(() => {
    if (
      filter.desiredEvaporatorCapacity !== undefined &&
      filter.desiredEvaporatorCapacity !== 0
    ) {
      getEvaporators();
    } else {
      setResultToInitialState();
    }
    // eslint-disable-next-line
  }, [filter, filterSelections]);

  return {
    evaporatorService: result,
  };
};

function createEvaporatorSearchUrlWithFilterParameters(
  filter: EvaporatorSearchRequest,
  filterSelections: FilterValues,
  roomTempBelowZero: boolean
) {
  let url = new URL(ENDPOINT_EVAPORATORS);
  let params: { [index: string]: string | number } = {
    refrigeration: filter.refrigerant,
    temperature_difference: filter.td,
    room_temperature: filter.roomTemp,
    mid_or_dew: filter.mid_or_dew,
    desired_evaporator_capacity: Math.round(
      filter.desiredEvaporatorCapacity / filter.noOfEvaporatorUnits
    ),
    ...(filter.ambientTemp && { ambient_temperature: filter.ambientTemp }),
    ...(filter.standardsRating && { condition: filter.standardsRating }),
    ...(filter.noOfEvaporatorUnits && {
      no_of_evaporators: filter.noOfEvaporatorUnits,
    }),
    ...(filter.startingEvaporatorTemp && {
      starting_evaporator_temp_guess: filter.startingEvaporatorTemp,
    }),
    ...(filter.startingCondensingTemp && {
      starting_condensing_temp_guess: filter.startingCondensingTemp,
    }),
    ...(filter.condensingUnitCode && {
      condensing_unit_code: filter.condensingUnitCode,
    }),
    ...(filter.compressorInletSuperheat && {
      super_heat: filter.compressorInletSuperheat,
    }),
    ...(filter.liquidSubcooling && { sub_cooling: filter.liquidSubcooling }),
  };
  // @ts-ignore
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key].toString())
  );

  addFilterSelectionsToUrl(url, filterSelections, roomTempBelowZero);

  return url;
}

export default useSingleEvaporatorSearch;
