import React, { Fragment } from "react";
import styles from "./ProductCard.module.css";
import Thumbnail from "../Thumbnail/Thumbnail";

export interface ProductDescriptionValues {
  label: string;
  value: string | React.ReactNode;
}

interface Props {
  title: string;
  productDescriptionValues: ProductDescriptionValues[];
  thumbnail?: string | null;
  additionalInfo?: string | React.ReactNode;
  linkId?: string | number;
}

const ProductCard: React.FC<Props> = ({
  title,
  productDescriptionValues,
  thumbnail,
  additionalInfo,
  linkId = "",
}) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>{title}</div>
      <div
        className={`${styles.grid} ${
          thumbnail === undefined || thumbnail === null ? styles.noGridGap : ``
        }`}
      >
        <div className={styles.thumbnailContainer}>
          {thumbnail && thumbnail !== null && (
            <Thumbnail thumbnailUrl={thumbnail} />
          )}
        </div>
        <div>
          {productDescriptionValues.map((productDesc, i) => {
            return (
              <Fragment key={i}>
                {productDesc.value && (
                  <div className={styles.productDescription}>
                    {productDesc.label}: <b>{productDesc.value}</b>
                  </div>
                )}
              </Fragment>
            );
          })}
          {linkId !== "" && (
            <div>
              <a
                href={`https://actrol.com.au/product/-${linkId}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.cardLink}
              >
                More technical info
              </a>
            </div>
          )}
        </div>
      </div>
      <div>{additionalInfo}</div>
    </div>
  );
};

export default ProductCard;
