import React, { forwardRef, Fragment } from "react";
import styles from "./Switch.module.css";
import { UseFormRegister } from "react-hook-form";

export interface SwitchValue {
  label: string;
  value: string;
}

export interface SwitchProps {
  values: SwitchValue[];
  labelText?: string;
  hasErrors: boolean;
  errorMessage?: string;
  defaultChecked?: string;
}

const Switch = forwardRef<
  HTMLInputElement,
  SwitchProps & ReturnType<UseFormRegister<any>>
>(
  (
    {
      name,
      values,
      labelText = "",
      hasErrors,
      errorMessage = "",
      onChange,
      defaultChecked,
      onBlur,
    },
    ref
  ) => {
    return (
      <div className={styles.switchContainer}>
        {labelText !== "" && <label id={name}>{labelText}</label>}

        <div className={styles.switch}>
          {values.map((v, i) => {
            return (
              <Fragment key={i}>
                <input
                  type="radio"
                  name={name}
                  value={v.value}
                  id={`${name}-${v.value}`}
                  aria-labelledby={`${name}-${v.value}`}
                  ref={ref}
                  onChange={onChange}
                  defaultChecked={defaultChecked === v.value}
                  onBlur={onBlur}
                />
                <label
                  htmlFor={`${name}-${v.value}`}
                  data-testid={`${name}-${v.value}`}
                >
                  {v.label}
                </label>
              </Fragment>
            );
          })}
        </div>
      </div>
    );
  }
);

export default Switch;
