import { Component } from "react";
import { navigate } from "hookrouter";

type ErrorBoundaryState = {
  hasError: boolean;
};

// DESCRIPTION: This class wraps the entire app and catches any JS/TS errors
// NOTE: when running app in local dev env, you will momentarily see the error
// page and then a stack trace.
// In production environments, the error page will shown only. To see error
// page locally:
// 1. Install serve app with        -->     yarn global add serve
// 2. Build prod version of the app -->     npm run build
// 3. Run the built version locally -->     serve -s build

class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      navigate("/error");
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
