import React, { FC } from "react";
import AlertMessage from "../../../components/AlertMessage/AlertMessage";
import { SearchStatus } from "../../../types/SearchStatus";

interface ResultInformationProps {
  condensingUnitSearchStatus?: SearchStatus;
  evaporatorSearchStatus?: SearchStatus;
}

const getProductsToAlert = (
  alertCondensingUnit: boolean,
  alertEvaporator: boolean
) => {
  if (alertCondensingUnit && alertEvaporator)
    return "condensing units and evaporators";
  else if (alertCondensingUnit) return "condensing units";
  else if (alertEvaporator) return "evaporators";

  return "equipments";
};

const alertMessage = (
  alertCondensingUnit: boolean,
  alertEvaporator: boolean
) => {
  return `We have provided selections of ${getProductsToAlert(
    alertCondensingUnit,
    alertEvaporator
  )} available for the selected product range `;
};

const ResultInformation: FC<ResultInformationProps> = ({
  condensingUnitSearchStatus = "NOT_FOUND",
  evaporatorSearchStatus = "NOT_FOUND",
}) => {
  const title = "For your information";

  return (
    <>
      {(condensingUnitSearchStatus === "FOUND_INSUFFICIENT" ||
        evaporatorSearchStatus === "FOUND_INSUFFICIENT") && (
        <AlertMessage
          title={title}
          description={`${alertMessage(
            condensingUnitSearchStatus === "FOUND_INSUFFICIENT",
            evaporatorSearchStatus === "FOUND_INSUFFICIENT"
          )}`}
          alertType="info"
          showCloseIcon
        />
      )}
    </>
  );
};

export default ResultInformation;
