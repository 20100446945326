import React, { FC } from "react";
import styles from "./CalculationSummary.module.css";
import { HeatLoadTotalCapacity } from "../../types/HeatLoadCapacity";
import InfoText from "../../../components/InfoText/InfoText";
import CalculationSummaryTable from "./CalculationSummaryTable";
import CalculationSummaryPieChart from "./CalculationSummaryPieChart";

const CalculationSummary: FC<{
  heatLoadSummary: HeatLoadTotalCapacity;
  roomTemperature: number;
}> = ({ heatLoadSummary, roomTemperature }) => {
  let totalHeatLoad =
    heatLoadSummary.totalLoad.sensible +
    heatLoadSummary.totalLoad.latent +
    heatLoadSummary.otherLoad.total;

  return (
    <div data-testid="calculation-summary">
      {totalHeatLoad > 0 && (
        <div className={styles.calcSummaryContainer}>
          <CalculationSummaryPieChart heatLoadSummary={heatLoadSummary} />
          <div>
            <CalculationSummaryTable
              heatLoadSummary={heatLoadSummary}
              roomTemperature={roomTemperature}
            />
          </div>
        </div>
      )}
      {totalHeatLoad === 0 && (
        <InfoText testid="no-heat-load-summary">
          Your heat load summary will appear here as you add room details.
        </InfoText>
      )}
    </div>
  );
};

export default CalculationSummary;
