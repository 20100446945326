import {
  CondensingUnitProductCapacities,
  CondensingUnitProductCapacity,
} from "../../types/CondensingUnitProductCapacity";
import { Service } from "../../types/Service";
import useProductWithThumbnails from "./useProductsWithThumbnails";

import useCondensingUnits from "./useCondensingUnits";

const useCapitalProductService = (): {
  condenserUnitService: Service<CondensingUnitProductCapacities>;
} => {
  const { condenserUnitService } = useCondensingUnits();

  const { productsWithThumbnails: condensingUnitsWithThumbnailsService } =
    useProductWithThumbnails<CondensingUnitProductCapacity>(
      condenserUnitService
    );

  return {
    condenserUnitService: condensingUnitsWithThumbnailsService,
  };
};

export default useCapitalProductService;
