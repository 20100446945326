import React, { FC } from "react";
import { useSelector } from "react-redux";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";
import { CeilingDetails, FloorDetails, WallDetails } from "../../types/Room";

const OutputWallDetails: FC<{ wallDetails: WallDetails }> = ({
  wallDetails,
}) => {
  return (
    <>
      {wallDetails &&
        wallDetails.material !== undefined &&
        wallDetails.thickness !== undefined &&
        wallDetails.ambientTemp !== undefined && (
          <>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Material
              </td>
              <td width="60%">{wallDetails.materialDisplay}</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Thickness
              </td>
              <td width="60%">{wallDetails.thickness} mm</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Ambient temp.
              </td>
              <td width="60%">{wallDetails.ambientTemp}°C</td>
            </tr>
          </>
        )}
    </>
  );
};

const OutputCeilingDetails: FC<{ ceilingDetails: CeilingDetails }> = ({
  ceilingDetails,
}) => {
  return (
    <>
      {ceilingDetails &&
        ceilingDetails.material !== undefined &&
        ceilingDetails.thickness !== undefined &&
        ceilingDetails.ambientTemp !== undefined && (
          <>
            <tr>
              <td width="40%">
                <strong>Ceiling</strong>
              </td>
              <td width="60%">&nbsp;</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Material
              </td>
              <td width="60%">{ceilingDetails.materialDisplay}</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Thickness
              </td>
              <td width="60%">{ceilingDetails.thickness} mm</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Ambient temp.
              </td>
              <td width="60%">{ceilingDetails.ambientTemp}°C</td>
            </tr>
          </>
        )}
    </>
  );
};

const OutputFloorDetails: FC<{ floorDetails: FloorDetails }> = ({
  floorDetails,
}) => {
  return (
    <>
      {floorDetails &&
        floorDetails.floorCover !== undefined &&
        floorDetails.groundTemp !== undefined && (
          <>
            <tr>
              <td width="40%">
                <strong>Floor</strong>
              </td>
              <td width="60%">&nbsp;</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Material
              </td>
              <td width="60%">{floorDetails.floorCover.materialDisplay}</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Thickness
              </td>
              <td width="60%">{floorDetails.floorCover.thickness} mm</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Ground temp.
              </td>
              <td width="60%">{floorDetails.groundTemp}°C</td>
            </tr>
            {floorDetails.floorInsulation?.material !== undefined &&
              floorDetails.floorInsulation?.thickness !== undefined && (
                <>
                  <tr>
                    <td width="40%" className={styles.indentTableCell}>
                      Insulation
                    </td>
                    <td width="60%">
                      {floorDetails.floorInsulation.materialDisplay}
                    </td>
                  </tr>
                  <tr>
                    <td width="40%" className={styles.indentTableCell}>
                      Insulation thickness
                    </td>
                    <td width="60%">
                      {floorDetails.floorInsulation.thickness} mm
                    </td>
                  </tr>
                </>
              )}
            {floorDetails.floorHeating?.wattagePerSqm !== undefined && (
              <tr>
                <td width="40%" className={styles.indentTableCell}>
                  Heating
                </td>
                <td width="60%">
                  {floorDetails.floorHeating.wattagePerSqm} W/m²
                </td>
              </tr>
            )}
          </>
        )}
    </>
  );
};

const RoomConstructionOutput: FC = () => {
  const wallDetails: WallDetails[] = useSelector((state: any) => {
    return state.heatLoad.walls;
  });

  const ceilingDetails: CeilingDetails = useSelector((state: any) => {
    return state.heatLoad.ceiling;
  });

  const floorDetails: FloorDetails = useSelector((state: any) => {
    return state.heatLoad.floor;
  });

  let allWallsSame: boolean = wallDetails.every((value, index, array) => {
    return (
      value.material === array[0].material &&
      value.ambientTemp === array[0].ambientTemp &&
      value.thickness === array[0].thickness
    );
  });

  return (
    <table className={styles.tablePrint}>
      <tbody>
        <tr>
          <td width="50%">
            <table className={styles.tablePrint}>
              <tbody>
                {allWallsSame && (
                  <>
                    <tr>
                      <td width="40%">
                        <strong>Walls</strong>
                      </td>
                      <td width="60%">&nbsp;</td>
                    </tr>
                    <OutputWallDetails wallDetails={wallDetails[0]} />
                    <OutputCeilingDetails ceilingDetails={ceilingDetails} />
                  </>
                )}
                {!allWallsSame &&
                  wallDetails.map((w, i) => {
                    return (
                      <>
                        <tr>
                          <td width="40%">
                            <strong>Wall {i + 1}</strong>
                          </td>
                          <td width="60%">&nbsp;</td>
                        </tr>
                        <OutputWallDetails wallDetails={wallDetails[i]} />
                      </>
                    );
                  })}
              </tbody>
            </table>
          </td>
          <td width="50%">
            <table className={styles.tablePrint}>
              <tbody>
                {!allWallsSame && (
                  <OutputCeilingDetails ceilingDetails={ceilingDetails} />
                )}
                <OutputFloorDetails floorDetails={floorDetails} />
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default RoomConstructionOutput;
