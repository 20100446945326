import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import actionSelector from "../../duck/selectors";
import gridStyles from "../../../css/GridForm.module.css";
import styles from "./CapitalEquipmentFilter.module.css";
import HeatLoadSection from "./FormSections/HeatLoadSection";
import calculateSst from "../../../calculations/sst/calculateSst";
import DesignDetailsSection from "./FormSections/DesignDetailsSection";
import ClimateDetailsSection from "./FormSections/ClimateDetailsSection";
import { CapitalEquipmentFilterModel } from "../../../types/CapitalEquipmentFilterModel";
import Button from "../../../components/Button/Button";
import { SelectOption } from "../../../types/SelectOption";
import { logPageEvent } from "../../../helpers/amplitude";
import { StandardRoom } from "../../../types/HeatLoad";
import { HeatLoadTotalCapacity } from "../../../heatLoad/types/HeatLoadCapacity";
import { SafetyAndRuntime } from "../../../heatLoad/types/SafetyAndRuntime";
import Humidity from "../../../calculations/humidity/TDtoRelativeHumidity";

interface EquipmentSelectionForm {
  load: string;
  refrigerant: string;
  runtime: string;
  ambient: string;
  roomTemp: string;
  td: string;
  humidity: string;
  noOfCondensingUnits: string;
  noOfEvaporatorUnits: string;
  mid_or_dew: string;
  standardsRating: string;
  compressorInletSuperheat: string;
  liquidSubcooling: string;
}

export const calculateTD = (roomTemperature: number, humidity: number) => {
  let tempDifference = null;

  if (roomTemperature >= 0 && humidity < 50) {
    tempDifference = 12;
  }
  if (roomTemperature < 0) {
    tempDifference = 6;
  }
  if (roomTemperature >= 0 && humidity >= 50) {
    tempDifference = Humidity.humidityToTd(humidity);
  }
  return tempDifference;
};

const CapitalEquipmentFilter: React.FC<{
  scrollToEquipmentSelection: () => void;
}> = ({ scrollToEquipmentSelection }) => {
  let dispatch = useDispatch();

  const validSst = (sst: number) => {
    return sst > -50 && sst < 40;
  };

  const roomDetails: StandardRoom = useSelector((state: any) => {
    return state.heatLoad.room;
  });

  const heatLoadSummary: HeatLoadTotalCapacity = useSelector((state: any) => {
    return state.heatLoad.heatLoadCapacity;
  });

  const runtime: SafetyAndRuntime = useSelector((state: any) => {
    return state.heatLoad.safetyAndRuntime;
  });

  const roomTemperature = roomDetails.temperature;
  const equipmentCapacity = Math.round(heatLoadSummary.fanLoad.total);
  const equipmentRunTime = runtime.equipmentRuntime;
  const equipmentAmbient = roomDetails.outsideTemperature;
  const humidity = roomDetails.humidity;
  const tempDifference = calculateTD(roomTemperature, humidity);

  const defaultValues: EquipmentSelectionForm = {
    load: equipmentCapacity > 0 ? String(equipmentCapacity) : "3600",
    refrigerant: "R404A",
    runtime: equipmentCapacity > 0 ? String(equipmentRunTime) : "16",
    ambient: equipmentCapacity > 0 ? String(equipmentAmbient) : "38",
    roomTemp: equipmentCapacity > 0 ? String(roomTemperature) : "2",
    td: equipmentCapacity > 0 ? String(tempDifference) : "6",
    humidity: equipmentCapacity > 0 ? String(humidity) : "80",
    noOfCondensingUnits: "1",
    noOfEvaporatorUnits: "1",
    mid_or_dew: "mid",
    standardsRating: "EN13215",
    compressorInletSuperheat: "15",
    liquidSubcooling: "2",
  };

  const submit = (values: EquipmentSelectionForm) => {
    let sst = calculateSst(+values.roomTemp, +values.td);
    if (validSst(sst)) {
      let submitValues: CapitalEquipmentFilterModel = {
        refrigerant: values.refrigerant,
        load: +values.load,
        runtime: +values.runtime,
        ambient: +values.ambient,
        td: +values.td,
        roomTemp: +values.roomTemp,
        noOfCondensingUnits: +values.noOfCondensingUnits,
        noOfEvaporatorUnits: +values.noOfEvaporatorUnits,
        mid_or_dew: values.mid_or_dew,
        standardsRating: values.standardsRating,
        compressorInletSuperheat: +values.compressorInletSuperheat,
        liquidSubcooling: +values.liquidSubcooling,
      };
      dispatch(actionSelector.loadingBalancePerformance(true));
      dispatch(actionSelector.searchCondensingUnits(submitValues));
      scrollToEquipmentSelection();
    } else {
      console.log(`Invalid calculated Sst value: ${sst}`);
    }
  };

  const methods = useForm<EquipmentSelectionForm>({
    defaultValues,
    mode: "onBlur",
  });

  const { setValue, handleSubmit } = methods;

  const refrigerantOptions: SelectOption[] = useSelector(
    (state: any) => state.product.equipmentRefrigerantOptions
  );

  useEffect(() => {
    setValue("refrigerant", "R404A");
  }, [refrigerantOptions, setValue]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)} className={styles.formContainer}>
        <div className={gridStyles.gridContainer}>
          <div>
            <div className="section-title">System details</div>

            <HeatLoadSection refrigerantOptions={refrigerantOptions} />
          </div>
          <div>
            <div className="section-title">Temperature details</div>

            <DesignDetailsSection />
          </div>
          <div>
            <div className="section-title">Ambient conditions</div>

            <ClimateDetailsSection />
          </div>
        </div>
        <div>
          <Button
            buttonStyle="primary"
            buttonType="submit"
            dataTestId="display_equipment"
            onClickHandler={(e) =>
              logPageEvent("Display equipment - Equipment Selection")
            }
          >
            Display equipment
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default CapitalEquipmentFilter;
