import React from "react";
import {
  CondensingUnitProductCapacities,
  CondensingUnitProductCapacity,
} from "../../../../types/CondensingUnitProductCapacity";
import { Service } from "../../../../types/Service";
import CapitalEquipmentLoadItem from "./CondensingUnitProductItem";
import { ProductFilter } from "../../../../types/ProductFilter";
import FiltersLoadingPlaceholder from "../../../../components/Filters/FiltersLoadingPlaceholder";
import TableLoadingPlaceholder from "../../../../components/Table/TableLoadingPlaceholder";
import Error from "../../../../components/Error/Error";
import TablePlaceholder from "../../../../components/TablePlaceholder/TablePlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { default as actionSelector } from "../../../duck/selectors";
import {
  sortProductFilterArray,
  sortFilterOptionArray,
} from "../../../../helpers/sortArray";

interface Props {
  condenserUnitService: Service<CondensingUnitProductCapacities>;
  handleCondenserUnitSelect: (
    selectedCondensingUnit: CondensingUnitProductCapacity
  ) => void;
  selectedCondensingUnit: CondensingUnitProductCapacity;
}

const CondensingUnitProductItemContainer: React.FC<Props> = ({
  condenserUnitService,
  handleCondenserUnitSelect,
  selectedCondensingUnit,
}) => {
  const dispatch = useDispatch();
  const productFilters: ProductFilter[] = useSelector(
    (state: any) => state.product.condensingUnitFilters
  );

  if (productFilters && productFilters.length > 0) {
    productFilters.sort((a, b) => sortProductFilterArray(a, b));
    productFilters.forEach((pf) => {
      pf.options.sort((a, b) => sortFilterOptionArray(a, b));
    });
  }

  const selectCondensingUnitFilter = (
    filterType: string,
    selection: string
  ) => {
    dispatch(actionSelector.selectCondensingUnitFilter(filterType, selection));
  };

  const resetCondensingUnitFilters = () => {
    dispatch(actionSelector.resetCondensingUnitFilters());
  };

  return (
    <div>
      {condenserUnitService.status === "loading" && (
        <>
          <FiltersLoadingPlaceholder />
          <TableLoadingPlaceholder />
        </>
      )}
      {condenserUnitService.status === "bad_request" && (
        <div>Enter product filter values.</div>
      )}
      {condenserUnitService.status === "init" && (
        <TablePlaceholder
          heading="Nothing to display"
          subheading="Add required system details to see matching products"
        />
      )}
      {condenserUnitService.status === "loaded" && (
        <CapitalEquipmentLoadItem
          products={condenserUnitService.payload.results}
          selectedCondenserUnit={selectedCondensingUnit}
          handleCondenserUnitSelect={handleCondenserUnitSelect}
          condensingUnitFilters={productFilters}
          filterSelections={condenserUnitService.payload.filterSelections}
          setFilterValues={selectCondensingUnitFilter}
          resetCondensingUnitFilters={resetCondensingUnitFilters}
        />
      )}
      {condenserUnitService.status === "error" && <Error />}
    </div>
  );
};

export default CondensingUnitProductItemContainer;
