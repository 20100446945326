import React, { FC, useCallback, useEffect, useState } from "react";
import TransmissionDetails from "./TransmissionDetails";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import actionSelector from "../../duck/selectors";
import isNaNReturnDefault from "../../../helpers/isNaNReturnNumber";
import {
  CeilingDetails,
  FloorDetails,
  RoomDimensions,
  WallDetails,
} from "../../types/Room";
import { ProductFilter } from "../../../types/ProductFilter";
import { TransmissionDetailsForm } from "../../types/TransmissionDetailsForm";
import {
  wallErrors,
  validateCeiling,
  validateFloor,
  validateWalls,
  validateAllWallsEntered,
} from "./SurfaceDetailsValidation";
import { EMPTY_FILTER } from "../../hooks/ExtractHeatloadFilters";
import { StandardRoom } from "../../../types/HeatLoad";
import { logPageEventWithData } from "../../../helpers/amplitude";

interface Wall {
  wallId?: string | undefined;
  material?: string | undefined;
  thickness?: number | undefined;
  ambientTemp?: number | undefined;
}

const TransmissionDetailsContainer: FC<{
  preloadedValues: TransmissionDetailsForm;
  roomDetails: StandardRoom;
  transmissionFormEnabled: boolean;
}> = ({ preloadedValues, roomDetails, transmissionFormEnabled }) => {
  let methods = useForm<TransmissionDetailsForm>({
    defaultValues: preloadedValues,
    mode: "onBlur",
  });

  const roomDimensions: RoomDimensions = useSelector((state: any) => {
    return state.heatLoad.roomDimensions;
  });

  let [defaultWallFormReady, setDefaultWallFormReady] = useState(true);
  let [defaultCeilingFormReady, setDefaultCeilingFormReady] = useState(true);
  let [defaultFloorFormReady, setDefaultFloorFormReady] = useState(true);
  let [transmissionDetailsSaved, setTransmissionDetailsSaved] = useState(false);

  const dispatch = useDispatch();

  const { watch, setValue, getValues } = methods;
  const walls = watch("walls");
  const ceiling = watch("ceiling");
  const floor = watch("floor");
  const wallsAreTheSame = watch("wallsAreTheSame");
  const { errors, isValidating } = methods.formState;

  const wallInsulationFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.miscellaneous
      ? state.heatLoad.formHeatLoadFilters.transmission["wall_insulation"]
      : EMPTY_FILTER
  );

  const ceilingInsulationFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.miscellaneous
      ? state.heatLoad.formHeatLoadFilters.transmission["ceiling_insulation"]
      : EMPTY_FILTER
  );

  const floorMaterialFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.miscellaneous
      ? state.heatLoad.formHeatLoadFilters.transmission["floor_material"]
      : EMPTY_FILTER
  );

  const floorInsulationFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.miscellaneous
      ? state.heatLoad.formHeatLoadFilters.transmission["floor_insulation"]
      : EMPTY_FILTER
  );

  const floorHeatingFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.miscellaneous
      ? state.heatLoad.formHeatLoadFilters.transmission["floor_heating"]
      : EMPTY_FILTER
  );

  const setupWall = useCallback(
    (w: Wall, i: number) => {
      if (
        w.material &&
        wallInsulationFilter.options &&
        validateWalls(errors, i)
      ) {
        let extendedValues;
        let option = wallInsulationFilter.options.find(
          (o) => o.key === w.material
        );
        let materialName = option?.value ?? "";

        if (option && "extended_values" in option) {
          extendedValues = option["extended_values"];
        }

        if (
          extendedValues &&
          w.material !== "" &&
          w.thickness !== undefined &&
          !isNaN(w.thickness) &&
          w.ambientTemp !== undefined &&
          !isNaN(w.ambientTemp)
        ) {
          let wallDetails: WallDetails = {
            id: w.wallId ?? "",
            material: w.material,
            materialDisplay: materialName,
            thickness: w.thickness,
            ambientTemp: w.ambientTemp,
            extendedValues: {
              conductivity: parseFloat(extendedValues["conductivity"]),
              thickness: parseFloat(extendedValues["thickness"]),
            },
          };

          return wallDetails;
        }
      }

      return undefined;
    },
    [errors, wallInsulationFilter.options]
  );

  const setAllWalls = useCallback(() => {
    let w0Material: string | undefined = "";
    let w0Thickness: number | undefined = 0;
    let w0Ambient: number | undefined = 0;
    const newWalls = watch("walls");
    newWalls.forEach((w, i) => {
      if (i === 0) {
        w0Material = getValues(`walls.0.material`);
        w0Thickness = getValues(`walls.0.thickness`);
        w0Ambient = getValues(`walls.0.ambientTemp`);
      } else {
        if (
          w0Material !== "" &&
          w0Thickness !== undefined &&
          !isNaN(w0Thickness) &&
          w0Ambient !== undefined &&
          !isNaN(w0Ambient)
        ) {
          setValue(`walls.${i}.thickness`, w0Thickness, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue(`walls.${i}.material`, w0Material, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue(`walls.${i}.ambientTemp`, w0Ambient, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
    });
  }, [getValues, setValue, watch]);

  const saveAllWallsWhenSame = useCallback(() => {
    let newWalls: WallDetails[] = [];
    let newWall = setupWall(walls[0], 0);

    if (newWall) {
      roomDimensions.walls.forEach((w, i) => {
        if (newWall !== undefined) {
          let updatedWall = Object.assign({}, newWall);
          updatedWall.id = w.id;
          newWalls.push(updatedWall);
        }
      });
    }

    return newWalls;
  }, [roomDimensions.walls, setupWall, walls]);

  const switchWallsView = useCallback(
    (wallsAreSame: string) => {
      if (wallsAreSame === "yes") {
        let newWalls: WallDetails[] = saveAllWallsWhenSame();

        if (newWalls.length > 0) {
          dispatch(actionSelector.saveWallDetails(newWalls));
        }
      } else {
        setAllWalls();
      }
    },
    [dispatch, saveAllWallsWhenSame, setAllWalls]
  );

  useEffect(() => {
    if (walls && (isValidating || defaultWallFormReady)) {
      let newWalls: WallDetails[] = [];

      if (wallsAreTheSame === "no") {
        walls.forEach((w, i) => {
          let newWall = setupWall(w, i);
          if (newWall !== undefined) {
            newWalls.push(newWall);
          }
        });
      } else {
        newWalls = saveAllWallsWhenSame();
      }

      if (newWalls.length > 0) {
        dispatch(actionSelector.saveWallDetails(newWalls));
        setDefaultWallFormReady(false);
        setTransmissionDetailsSaved(true);

        logPageEventWithData("Heat Load - Wall Details saved", { newWalls });
      }
    }
  }, [
    isValidating,
    dispatch,
    walls,
    wallsAreTheSame,
    setupWall,
    saveAllWallsWhenSame,
    defaultWallFormReady,
  ]);

  useEffect(() => {
    if (isValidating && wallsAreTheSame === "no") {
      let firstFilled: boolean = true;
      let restEmpty: boolean = true;
      walls.forEach((w, i) => {
        if (i === 0) {
          firstFilled =
            !wallErrors(errors, i) &&
            w.material !== "" &&
            w.thickness !== undefined &&
            !isNaN(w.thickness) &&
            w.ambientTemp !== undefined &&
            !isNaN(w.ambientTemp);
        } else {
          restEmpty =
            restEmpty &&
            w.material === "" &&
            (w.thickness === undefined || isNaN(w.thickness)) &&
            (w.ambientTemp === undefined || isNaN(w.ambientTemp));
        }
      });

      if (firstFilled && restEmpty) {
        setAllWalls();
        // TODO: set Focus to floor materials when switch to v7 of react-hook-form
      }
    }
  }, [isValidating, errors, dispatch, walls, wallsAreTheSame, setAllWalls]);

  useEffect(() => {
    if (
      (isValidating || defaultCeilingFormReady) &&
      ceiling &&
      ceiling.material &&
      ceilingInsulationFilter.options &&
      validateCeiling(errors)
    ) {
      let extendedValues;
      let option = ceilingInsulationFilter.options.find(
        (o) => o.key === ceiling.material
      );
      let ceilingMaterial = option?.value ?? "";

      if (option && "extended_values" in option) {
        extendedValues = option["extended_values"];
      }

      if (extendedValues) {
        const ceilingDetails: CeilingDetails = {
          id: roomDimensions.ceiling.id,
          material: ceiling.material,
          materialDisplay: ceilingMaterial,
          thickness: isNaNReturnDefault(ceiling.thickness),
          ambientTemp: isNaNReturnDefault(ceiling.ambientTemp),
          extendedValues: {
            conductivity: parseFloat(extendedValues["conductivity"]),
            thickness: parseFloat(extendedValues["thickness"]),
          },
        };
        dispatch(actionSelector.saveCeilingDetails(ceilingDetails));
        setDefaultCeilingFormReady(false);
        logPageEventWithData(
          "Heat Load - Ceiling Details saved",
          ceilingDetails
        );
      }
    }
  }, [
    errors,
    isValidating,
    dispatch,
    ceilingInsulationFilter,
    ceiling,
    roomDimensions.ceiling,
    defaultCeilingFormReady,
  ]);

  useEffect(() => {
    if (
      (isValidating || defaultFloorFormReady) &&
      floor &&
      floor.material &&
      floorMaterialFilter.options &&
      floorInsulationFilter.options &&
      floorHeatingFilter.options &&
      validateFloor(errors)
    ) {
      let floorMaterialExtendedValues;
      let floorMaterialOption = floorMaterialFilter.options.find(
        (o) => o.key === floor.material
      );
      let floorMaterial = floorMaterialOption?.value ?? "";

      if (floorMaterialOption && "extended_values" in floorMaterialOption) {
        floorMaterialExtendedValues = floorMaterialOption["extended_values"];
      }

      if (floorMaterialExtendedValues) {
        const floorDetails: FloorDetails = {
          id: roomDimensions.floor.id,
          groundTemp: isNaNReturnDefault(floor.groundTemp),
          floorCover: {
            material: floor.material,
            materialDisplay: floorMaterial,
            thickness: isNaNReturnDefault(floor.thickness),
            extendedValues: {
              conductivity: parseFloat(
                floorMaterialExtendedValues["conductivity"]
              ),
              thickness: parseFloat(floorMaterialExtendedValues["thickness"]),
            },
          },
        };

        let floorInsulationExtendedValues;
        let floorInsulationMaterial = "";

        if (floor.insulationMaterial) {
          let floorInsulationOption = floorInsulationFilter.options.find(
            (o) => o.key === floor.insulationMaterial
          );
          floorInsulationMaterial = floorInsulationOption?.value ?? "";

          if (
            floorInsulationOption &&
            "extended_values" in floorInsulationOption
          ) {
            floorInsulationExtendedValues =
              floorInsulationOption["extended_values"];
          }
        }

        if (floorInsulationExtendedValues && floor.insulationThickness) {
          floorDetails["floorInsulation"] = {
            material: floor.insulationMaterial ?? "",
            materialDisplay: floorInsulationMaterial,
            thickness: isNaNReturnDefault(floor.insulationThickness),
            extendedValues: {
              conductivity: parseFloat(
                floorInsulationExtendedValues["conductivity"]
              ),
              thickness: isNaNReturnDefault(floor.insulationThickness),
            },
          };
        }

        if (floor.heating) {
          let floorHeatingOption = floorHeatingFilter.options.find(
            (o) => o.key === floor.heating
          );

          if (
            floorHeatingOption &&
            "extended_values" in floorHeatingOption &&
            floorHeatingOption["extended_values"] &&
            "wattagePerSqm" in floorHeatingOption["extended_values"]
          ) {
            floorDetails["floorHeating"] = {
              wattagePerSqm: parseFloat(
                floorHeatingOption["extended_values"]["wattagePerSqm"]
              ),
            };
          }

          if (
            floorHeatingOption &&
            floor.heating === "custom" &&
            floor.customWattage
          ) {
            floorDetails["floorHeating"] = {
              wattagePerSqm: floor.customWattage,
            };
          }
        }

        dispatch(actionSelector.saveFloorDetails(floorDetails));
        setDefaultFloorFormReady(false);

        logPageEventWithData("Heat Load - Floor Details saved", floorDetails);
      }
    }
  }, [
    errors,
    isValidating,
    dispatch,
    floorMaterialFilter,
    floorInsulationFilter,
    floorHeatingFilter,
    floor,
    roomDimensions.floor.id,
    defaultFloorFormReady,
  ]);

  useEffect(() => {
    if (
      transmissionDetailsSaved &&
      validateCeiling(errors) &&
      validateAllWallsEntered(walls, errors)
    ) {
      // Enable the forms on the rest of the page.
      dispatch(
        actionSelector.saveFormSectionState({
          doorDetailsEnabled: true,
          productDetailsEnabled: true,
          additionalLoadsEnabled: true,
        })
      );
    }
  }, [dispatch, errors, isValidating, walls, transmissionDetailsSaved]);

  return (
    <FormProvider {...methods}>
      <form>
        <TransmissionDetails
          roomDimensions={roomDimensions}
          wallInsulationFilter={wallInsulationFilter}
          ceilingInsulationFilter={ceilingInsulationFilter}
          floorMaterialFilter={floorMaterialFilter}
          floorInsulationFilter={floorInsulationFilter}
          floorHeatingFilter={floorHeatingFilter}
          formEnabled={transmissionFormEnabled}
          roomDetails={roomDetails}
          switchWallsViewHandler={switchWallsView}
        />
      </form>
    </FormProvider>
  );
};

export default TransmissionDetailsContainer;
