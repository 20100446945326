import React from "react";
import styles from "./ToolTip.module.css";

type ToolTipPosition = "top" | "bottom" | "left" | "right";

export interface ToolTipProps {
  tooltipText: string[];
  tooltipPosition?: ToolTipPosition;
  hideBulletList?: boolean;
}

const ToolTip: React.FC<ToolTipProps> = ({
  tooltipText,
  tooltipPosition = "top",
  hideBulletList = false,
  children,
}) => {
  const tooltipPositionClass: string =
    tooltipPosition === "top"
      ? styles.tooltipTop
      : tooltipPosition === "bottom"
      ? styles.tooltipBottom
      : tooltipPosition === "left"
      ? styles.tooltipLeft
      : styles.tooltipRight;

  return (
    <div className={styles.tooltip}>
      {children}
      <span className={`${styles.tooltiptext} ${tooltipPositionClass}`}>
        <ul
          className={`${styles.listStyle} ${
            hideBulletList && styles.withoutBullets
          }`}
        >
          {tooltipText.map((text, index) => {
            return (
              <li key={index} data-testid="listitem">
                {text}
              </li>
            );
          })}
        </ul>
      </span>
    </div>
  );
};

export default ToolTip;
