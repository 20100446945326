import { ProductFilter } from "../types/ProductFilter";

export const addAnyOptionToFilters = (
  filters: ProductFilter[]
): ProductFilter[] => {
  return filters.map((filter) => {
    return {
      ...filter,
      options: [{ key: "Any", value: "Any" }, ...filter.options],
    };
  });
};

export const getInitialFilterStateFromProductFilters = (
  filters: ProductFilter[]
) => {
  let filterTypes: string[] = filters.map((filter) => filter.type);
  return filterTypes.reduce((obj, filterType) => {
    return {
      ...obj,
      [filterType]: "Any",
    };
  }, {});
};
