import { Service } from "../../types/Service";
import useProductWithThumbnails from "./useProductsWithThumbnails";
import {
  EvaporatorProductCapacities,
  EvaporatorProductCapacity,
} from "../../types/EvaporatorProductCapacity";
import useEvaporatorProductSearchService from "./useEvaporatorProducts";

const useEvaporatorProductService = (): {
  evaporatorService: Service<EvaporatorProductCapacities>;
} => {
  const { evaporatorService } = useEvaporatorProductSearchService();

  const { productsWithThumbnails: evaporatorsWithThumbnailsService } =
    useProductWithThumbnails<EvaporatorProductCapacity>(evaporatorService);

  return {
    evaporatorService: evaporatorsWithThumbnailsService,
  };
};

export default useEvaporatorProductService;
