import React, { FC } from "react";
import { useSelector } from "react-redux";
import { StandardRoom } from "../../../types/HeatLoad";
import { SafetyAndRuntimeDetailsForm } from "./SafetyAndRuntimeDetailsContainer";
import SafetyAndRuntimeDetailsContainer from "./SafetyAndRuntimeDetailsContainer";

const SafetyAndRuntimeDetailsParent: FC<{}> = () => {
  const roomDetails: StandardRoom = useSelector((state: any) => {
    return state.heatLoad.room;
  });

  const safetyAndRuntimeDetailsEnabled: boolean = useSelector((state: any) => {
    return state.heatLoad.formSections.safetyAndRuntimeDetailsEnabled;
  });

  const roomTemperature = roomDetails.temperature;

  let defaultValues: SafetyAndRuntimeDetailsForm | undefined;

  if (safetyAndRuntimeDetailsEnabled) {
    defaultValues = {
      equipmentRuntime: roomTemperature >= 0 ? 16 : 18,
      safetyFactor: 10,
      fanFactor: roomTemperature >= 0 ? 6 : 14,
    };
  }

  return safetyAndRuntimeDetailsEnabled && defaultValues !== undefined ? (
    <SafetyAndRuntimeDetailsContainer
      preloadedValues={defaultValues}
      roomDetails={roomDetails}
      safetyAndRuntimeDetailsEnabled={safetyAndRuntimeDetailsEnabled}
    />
  ) : (
    <></>
  );
};

export default SafetyAndRuntimeDetailsParent;
