import React, { forwardRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EvaporatorProductCapacity } from "../../../types/EvaporatorProductCapacity";
import { default as actionSelector } from "../../duck/selectors";
import styles from "../EquipmentSelection/EquipmentSelectionContainer.module.css";
import EvaporatorProductItemContainer from "../EquipmentSelection/EvaporatorUnitProductItem/EvaporatorProductItemContainer";
import { findBestMatchWithImage } from "../../../helpers/findBestMatchWithImage";
import useSingleEvaporatorService from "../../hooks/useSingleEvaporatorService";

const EvaporatorSelectionContainer = forwardRef<HTMLHeadingElement, {}>(
  (_, ref) => {
    const dispatch = useDispatch();
    const { evaporatorService } = useSingleEvaporatorService();

    const selectEvaporator = useCallback(
      (
        selectedEvaporator: EvaporatorProductCapacity,
        triggerLoading: boolean
      ) => {
        if (triggerLoading) {
          dispatch(actionSelector.loadingBalancePerformance(true));
        }
        dispatch(actionSelector.selectSingleEvaporator(selectedEvaporator));
        if (triggerLoading) {
          setTimeout(() => {
            dispatch(actionSelector.loadingBalancePerformance(false));
          }, 200);
        }
      },
      [dispatch]
    );

    useEffect(() => {
      if (
        evaporatorService.status === "loaded" &&
        evaporatorService.payload.results.length
      ) {
        let bestMatch = findBestMatchWithImage(
          evaporatorService.payload.results,
          evaporatorService.payload.best_match
        );
        if (bestMatch !== undefined) {
          selectEvaporator(bestMatch as EvaporatorProductCapacity, false);
        }
      }

      if (evaporatorService.status === "loaded") {
        dispatch(
          actionSelector.setSingleEvaporatorSearchStatus(
            evaporatorService.payload.search_status
          )
        );
      } else {
        dispatch(actionSelector.setSingleEvaporatorSearchStatus("NOT_FOUND"));
      }
    }, [evaporatorService, selectEvaporator, dispatch]);

    const selectedEvaporator: EvaporatorProductCapacity = useSelector(
      (state: any) => state.product.selectedSingleEvaporator
    );

    return (
      <section>
        <div ref={ref} className={styles.resultsContainer}>
          <EvaporatorProductItemContainer
            evaporatorService={evaporatorService}
            handleEvaporatorSelect={selectEvaporator}
            selectedEvaporator={selectedEvaporator}
          />
        </div>
      </section>
    );
  }
);

export default EvaporatorSelectionContainer;
