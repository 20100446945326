import React, { FC } from "react";
import { useSelector } from "react-redux";
import PrintSectionHeader from "../../../components/PrintTemplate/PrintSectionHeader";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";
import { PipeSizeCalculationRequest } from "../../../types/PipeSizeCalculationRequest";

const PipeSizeInputs: FC = () => {
  const pipeSizeCalc: PipeSizeCalculationRequest = useSelector((state: any) => {
    return state.pipes.pipeSizeCalculationRequest;
  });

  let showFullLiquid =
    pipeSizeCalc.horizontalLiquid !== 0 && pipeSizeCalc.verticalLiquid !== 0;
  let showFullSuction =
    pipeSizeCalc.horizontalSuction !== 0 && pipeSizeCalc.verticalSuction !== 0;
  let showFullDischarge =
    pipeSizeCalc.horizontalDischarge !== 0 &&
    pipeSizeCalc.verticalDischarge !== 0;

  let locationOfCondensingUnit =
    pipeSizeCalc.locationOfCondensingUnit === "same"
      ? "Same level"
      : pipeSizeCalc.locationOfCondensingUnit === "above"
      ? "Above"
      : "Below";

  return (
    <>
      <PrintSectionHeader>Refrigerant inputs</PrintSectionHeader>

      <table className={styles.tableHalfWidth}>
        <tbody>
          <tr>
            <td width="50%">Refrigerant</td>
            <td width="50%">{pipeSizeCalc.refrigerant}</td>
          </tr>
          <tr>
            <td width="50%">Required capacity</td>
            <td width="50%">{pipeSizeCalc.operatingCapacity} W</td>
          </tr>
        </tbody>
      </table>

      <PrintSectionHeader>Temperature inputs</PrintSectionHeader>

      <table className={styles.tablePrint}>
        <tbody>
          <tr>
            <td width="30%">Reference temp. method</td>
            <td width="20%">
              {pipeSizeCalc.mid_or_dew === "mid" ? "Mid point" : "Dew point"}
            </td>
            <td width="30%">Compressor inlet superheat</td>
            <td width="20%">{pipeSizeCalc.compressorInletTemp} K</td>
          </tr>
          <tr>
            <td width="30%">Saturated suction temp. (SST)</td>
            <td width="20%">{pipeSizeCalc.saturatedSuctionTemp}°C</td>
            <td width="30%">Liquid subcooling</td>
            <td width="20%">{pipeSizeCalc.liquidSubcooling} K</td>
          </tr>
          <tr>
            <td width="30%">Saturated condensing temp. (SCT)</td>
            <td width="20%">{pipeSizeCalc.saturatedCondensingTemp}°C</td>
            <td width="30%">&nbsp;</td>
            <td width="20%">&nbsp;</td>
          </tr>
        </tbody>
      </table>

      <PrintSectionHeader>Line length inputs</PrintSectionHeader>

      <table className={styles.tablePrint}>
        <tbody>
          <tr>
            <td width="40%">Location of condensing unit from evaporator</td>
            <td width="10%">{locationOfCondensingUnit}</td>
            {showFullDischarge && (
              <>
                <td width="30%">Discharge - Horizontal</td>
                <td width="20%">{pipeSizeCalc.horizontalDischarge} m</td>
              </>
            )}
            {!showFullDischarge && (
              <>
                <td width="30%">&nbsp;</td>
                <td width="20%">&nbsp;</td>
              </>
            )}
          </tr>
          <tr>
            <td width="40%">
              {!showFullLiquid ? "Liquid" : "Liquid - Horizontal"}
            </td>
            <td width="10%">{pipeSizeCalc.horizontalLiquid} m</td>
            {showFullDischarge && (
              <>
                <td width="30%">
                  Discharge - Vertical{" "}
                  {pipeSizeCalc.verticalDischarge < 0 ? "drop" : "lift"}
                </td>
                <td width="20%">{pipeSizeCalc.verticalDischarge} m</td>
              </>
            )}
            {!showFullDischarge && (
              <>
                <td width="30%">&nbsp;</td>
                <td width="20%">&nbsp;</td>
              </>
            )}
          </tr>
          <tr>
            <td width="30%">
              {pipeSizeCalc.verticalLiquid !== 0
                ? "Liquid - Vertical " +
                  (pipeSizeCalc.verticalLiquid < 0 ? "drop" : "lift")
                : "Suction"}
            </td>
            <td width="10%">
              {pipeSizeCalc.verticalLiquid !== 0
                ? Math.abs(pipeSizeCalc.verticalLiquid)
                : pipeSizeCalc.horizontalSuction}{" "}
              m
            </td>
            <td width="30%">&nbsp;</td>
            <td width="20%">&nbsp;</td>
          </tr>
          <tr>
            <td width="40%">
              {showFullSuction ? "Suction - Horizontal" : "Discharge"}
            </td>
            <td width="10%">
              {showFullSuction
                ? pipeSizeCalc.horizontalSuction
                : pipeSizeCalc.horizontalDischarge}{" "}
              m
            </td>
            <td width="30%">&nbsp;</td>
            <td width="20%">&nbsp;</td>
          </tr>
          {showFullSuction && (
            <tr>
              <td width="40%">
                Suction - Vertical{" "}
                {pipeSizeCalc.verticalSuction < 0 ? "drop" : "lift"}
              </td>
              <td width="10%">{Math.abs(pipeSizeCalc.verticalSuction)} m</td>
              <td width="30%">&nbsp;</td>
              <td width="20%">&nbsp;</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default PipeSizeInputs;
