import React from "react";
import styles from "./TablePlaceholder.module.css";
import Placeholder from "../Placeholder/Placeholder";

interface Props {
  heading: string;
  subheading: string;
}

const TablePlaceholder: React.FC<Props> = ({ heading, subheading }) => (
  <section className={styles.container}>
    <Placeholder heading={heading} subheading={subheading} />
  </section>
);

export default TablePlaceholder;
