import { ProductFilter } from "../../types/ProductFilter";
import { default as actionSelector } from "../duck/selectors";
import { useDispatch } from "react-redux";
import {
  addAnyOptionToFilters,
  getInitialFilterStateFromProductFilters,
} from "../../helpers/addAnyOptionToFilters";
import getUrl from "../../helpers/api";
import { useEffect } from "react";

declare const window: any;
interface ProductFiltersModel {
  evaporator: ProductFilter[];
  condensing_unit: ProductFilter[];
  pipe_selection: ProductFilter[];
  equipment_selection: ProductFilter[];
}

const useFilters = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const ENDPOINT_PRODUCT_FILTER = `${window.__runtime_configuration.ENDPOINT_FILTERS}/call/capital-product-filter`;
    let filterValuesResponse: ProductFiltersModel = {
      condensing_unit: [],
      evaporator: [],
      equipment_selection: [],
      pipe_selection: [],
    };

    const initialFilterValues = (filterValuesResponse: ProductFiltersModel) => {
      if (filterValuesResponse) {
        let condensingUnitFilters = filterValuesResponse.condensing_unit;
        dispatch(
          actionSelector.defaultCondensingUnitFilterSelections(
            getInitialFilterStateFromProductFilters(condensingUnitFilters)
          )
        );
        let condensingUnitFiltersWithAnyOption = addAnyOptionToFilters(
          condensingUnitFilters
        );
        dispatch(
          actionSelector.displayCondensingUnitFilters(
            condensingUnitFiltersWithAnyOption
          )
        );

        let evaporatorFilters = filterValuesResponse.evaporator;
        dispatch(
          actionSelector.defaultEvaporatorFilterSelections(
            getInitialFilterStateFromProductFilters(evaporatorFilters)
          )
        );
        let evaporatorFiltersWithAnyOption =
          addAnyOptionToFilters(evaporatorFilters);
        dispatch(
          actionSelector.displayEvaporatorFilters(
            evaporatorFiltersWithAnyOption
          )
        );

        // Refrigerants
        let pipeSizeRefrigerantOptions =
          filterValuesResponse.pipe_selection[0].options;
        dispatch(
          actionSelector.displayPipeSizeRefrigerantOptions(
            pipeSizeRefrigerantOptions
          )
        );

        let equipmentRefrigerantOptions =
          filterValuesResponse.equipment_selection[0].options;
        dispatch(
          actionSelector.displayEquipmentRefrigerantOptions(
            equipmentRefrigerantOptions
          )
        );
      }
    };

    let u = new URL(ENDPOINT_PRODUCT_FILTER);

    getUrl<ProductFiltersModel>(u.href)
      .then((response) => {
        filterValuesResponse = response;
        initialFilterValues(filterValuesResponse);
      })
      .catch((error) => {
        // Need to alert
      });
  }, [dispatch]);
};

export default useFilters;
