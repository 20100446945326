import React, { FC, Fragment } from "react";
import { useSelector } from "react-redux";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";
import { DoorDetails } from "../../types/Room";

const DoorsOutput: FC<{ advancedCalc: boolean }> = ({ advancedCalc }) => {
  const doorDetails: DoorDetails[] = useSelector((state: any) => {
    return state.heatLoad.doors;
  });

  return (
    <>
      {doorDetails &&
        doorDetails.length > 0 &&
        doorDetails.map((d, i) => {
          const location = Number(d.location.split("_").pop()) + 1;
          return (
            <Fragment key={i}>
              <div className={styles.tableTitle}>
                <strong>Door style {i + 1}</strong>
              </div>
              <table className={styles.tablePrint}>
                <tbody>
                  <tr>
                    <td width="20%" className={styles.indentTableCell}>
                      Door location
                    </td>
                    <td width="30%">{"Wall " + location}</td>
                    <td width="20%">Door width</td>
                    <td width="30%">{d.width} mm</td>
                  </tr>
                  <tr>
                    <td width="20%" className={styles.indentTableCell}>
                      Door type
                    </td>
                    <td width="30%">{d.typeDisplay}</td>
                    <td width="20%">Door height</td>
                    <td width="30%">{d.height} mm</td>
                  </tr>
                  <tr>
                    <td width="20%" className={styles.indentTableCell}>
                      {d.type === "solid" ? "Door material" : "Glazing"}
                    </td>
                    <td width="30%">
                      {d.type === "solid"
                        ? d.materialDisplay
                        : d.glazingDisplay}
                    </td>
                    <td width="20%">Heated fascia</td>
                    <td width="30%">
                      {d.heatedFascia === "custom"
                        ? d.customHeatedFascia
                        : d.heatedFascia}{" "}
                      W/m
                    </td>
                  </tr>
                  {d.heatedGlass ? (
                    <tr>
                      <td width="20%" className={styles.indentTableCell}>
                        No. of doors
                      </td>
                      <td width="30%">{d.numberOfDoors}</td>
                      <td width="20%">Heated glass</td>
                      <td width="30%">
                        {d.heatedGlass === "custom"
                          ? d.customHeatedGlass
                          : d.heatedGlass}
                        {` W/m\u00B2`}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td width="20%" className={styles.indentTableCell}>
                        No. of doors
                      </td>
                      <td width="30%">{d.numberOfDoors}</td>
                      <td width="20%">&nbsp;</td>
                      <td width="30%">&nbsp;</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Fragment>
          );
        })}
    </>
  );
};

export default DoorsOutput;
