import React, { FC } from "react";
import PrintSectionHeader from "../../../components/PrintTemplate/PrintSectionHeader";
import { CapitalEquipmentFilterModel } from "../../../types/CapitalEquipmentFilterModel";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";

const CombinedSearchInputs: FC<{
  equipmentSearch: CapitalEquipmentFilterModel;
}> = ({ equipmentSearch }) => {
  return (
    <>
      <PrintSectionHeader>Heat load and refrigerant</PrintSectionHeader>

      <table className={styles.tablePrint}>
        <tbody>
          <tr>
            <td width="30%">Standards rating</td>
            <td width="20%">{equipmentSearch.standardsRating}</td>
            <td width="25%">Run time per day</td>
            <td width="25%">{equipmentSearch.runtime} hours</td>
          </tr>
          <tr>
            <td width="30%">Required capacity</td>
            <td width="20%">{equipmentSearch.load} W</td>
            <td width="25%">No. of systems</td>
            <td width="25%">{equipmentSearch.noOfCondensingUnits}</td>
          </tr>
          <tr>
            <td width="30%">Refrigerant</td>
            <td width="20%">{equipmentSearch.refrigerant}</td>
            <td width="25%">No. of evaporators per system</td>
            <td width="25%">{equipmentSearch.noOfEvaporatorUnits}</td>
          </tr>
        </tbody>
      </table>

      <PrintSectionHeader>
        Temperatures &amp; ambient conditions
      </PrintSectionHeader>

      <table className={styles.tablePrint}>
        <tbody>
          <tr>
            <td width="30%">Reference temp. method</td>
            <td width="20%">
              {equipmentSearch.mid_or_dew === "mid" ? "Mid point" : "Dew point"}
            </td>
            <td width="30%">Compressor inlet superheat</td>
            <td width="20%">{equipmentSearch.compressorInletSuperheat} K</td>
          </tr>
          <tr>
            <td width="30%">Design room temp.</td>
            <td width="20%">{equipmentSearch.roomTemp}°C</td>
            <td width="30%">Liquid subcooling</td>
            <td width="20%">{equipmentSearch.liquidSubcooling} K</td>
          </tr>
          <tr>
            <td width="30%">Design temp. difference</td>
            <td width="20%">{equipmentSearch.td} K</td>
            <td width="30%">Ambient temp. on condensing unit</td>
            <td width="20%">{equipmentSearch.ambient}°C</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default CombinedSearchInputs;
