import { FieldError, RegisterOptions } from "react-hook-form";
import isAnEmptyObject from "../../../helpers/emptyObject";

interface SafetyAndRuntimeDetailsErrors {
  otherLoads?: FieldError | undefined;
  equipmentRuntime?: FieldError | undefined;
  safetyFactor?: FieldError | undefined;
  fanFactor?: FieldError | undefined;
}

const equipmentRuntimeRule: RegisterOptions = {
  required: "Required",
  min: {
    value: 12,
    message: "Must be between 12 and 24 Hours",
  },
  max: {
    value: 24,
    message: "Must be between 12 and 24 Hours",
  },
  valueAsNumber: true,
  validate: {
    validEquipRuntime: (value) => {
      const matches = value.toString().match(/^\d*\.?\d*$/);
      return matches?.length > 0 || "Must be a number";
    },
  },
};

const safetyFactorRule: RegisterOptions = {
  required: "Required",
  min: {
    value: 0,
    message: "Must be between 0 and 20 Percent",
  },
  max: {
    value: 20,
    message: "Must be between 0 and 20 Percent",
  },
  valueAsNumber: true,
  validate: {
    validSafetyFactor: (value) => {
      const matches = value.toString().match(/^\d*\.?\d*$/);
      return matches?.length > 0 || "Must be a number";
    },
  },
};

const otherLoadsRule: RegisterOptions = {
  required: false,
  min: {
    value: 0,
    message: "Must be between 0 and 100000 Watts",
  },
  max: {
    value: 100000,
    message: "Must be between 0 and 100000 Watts",
  },
  validate: {
    validOtherLoads: (value) => {
      const matches = value.toString().match(/^\d*\.?\d*$/);
      return matches?.length > 0 || "Must be a number";
    },
  },
};

function safetyAndRuntimeDetailsHasNoErrors(
  errors: SafetyAndRuntimeDetailsErrors
) {
  return isAnEmptyObject(errors);
}

export {
  equipmentRuntimeRule,
  safetyFactorRule,
  otherLoadsRule,
  safetyAndRuntimeDetailsHasNoErrors,
};
