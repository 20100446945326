import { ProductFilter } from "../../types/ProductFilter";
import { default as actionSelector } from "../duck/selectors";
import { useDispatch } from "react-redux";
import getUrl from "../../helpers/api";
import { useEffect } from "react";
import { extractFilterGroups } from "./ExtractHeatloadFilters";

declare const window: any;
interface HeatLoadFiltersModel {
  heat_load: ProductFilter[];
}

const useHeadLoadFilters = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const ENDPOINT_PRODUCT_FILTER = `${window.__runtime_configuration.ENDPOINT_FILTERS}/call/capital-product-filter`;
    let filterValuesResponse: HeatLoadFiltersModel = {
      heat_load: [],
    };

    const initialFilterValues = (
      filterValuesResponse: HeatLoadFiltersModel
    ) => {
      if (filterValuesResponse) {
        let heatLoadFilters = filterValuesResponse.heat_load;

        let formHeatLoadFilters = extractFilterGroups(heatLoadFilters);
        dispatch(actionSelector.saveHeatLoadFilters(formHeatLoadFilters));
      }
    };

    let u = new URL(ENDPOINT_PRODUCT_FILTER);

    getUrl<HeatLoadFiltersModel>(u.href)
      .then((response) => {
        filterValuesResponse = response;
        initialFilterValues(filterValuesResponse);
      })
      .catch((error) => {
        // Need to alert
      });
  }, [dispatch]);
};

export default useHeadLoadFilters;
