import HeatCapacity from "./HeatCapacity";
import ServiceEndpoints from "./ServiceEndpoints";
import getUrl from "../../../helpers/api";
import DoorInfiltrationRequest from "./DoorInfiltrationRequest";

const calculateDoorInfiltrationLoad = (
  request: DoorInfiltrationRequest
): Promise<HeatCapacity> => {
  let url = new ServiceEndpoints().getDoorInfiltrationLoad(request);

  return getUrl<HeatCapacity>(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      // TODO: Just a reminder use this for cleanups
    });
};

export { calculateDoorInfiltrationLoad };
