import React, { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import isNaNReturnDefault from "../../../../helpers/isNaNReturnNumber";
import actionSelector from "../../../duck/actions";
import ForkliftDetails from "./ForkliftDetails";
import { ForkliftDetails as ForkliftDetailsModel } from "../../../types/Room";
import { validateForkliftDetails } from "./ForkliftDetailsValidation";
import { EMPTY_FILTER } from "../../../hooks/ExtractHeatloadFilters";
import { ProductFilter } from "../../../../types/ProductFilter";

export interface ForkliftDetailsForm {
  forkliftType?: string;
  heatGain?: number;
  forkliftUsage?: number;
}

const defaultValues: ForkliftDetailsForm = {
  forkliftType: "none",
  heatGain: undefined,
  forkliftUsage: undefined,
};

const ForkliftDetailsContainer: FC<{}> = () => {
  const methods = useForm<ForkliftDetailsForm>({
    defaultValues,
    mode: "onBlur",
  });
  const dispatch = useDispatch();
  const { watch, formState } = methods;
  const { errors, dirtyFields, isValidating } = formState;

  const forkliftTypeOptions: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.miscellaneous
      ? state.heatLoad.formHeatLoadFilters.miscellaneous["forklift_type"]
      : EMPTY_FILTER
  );

  useEffect(() => {
    const forkliftDetails = watch();

    if (
      isValidating &&
      forkliftDetails &&
      validateForkliftDetails(errors, dirtyFields)
    ) {
      let findForkliftType = forkliftTypeOptions.options.find(
        (forklift) => forklift.key === forkliftDetails.forkliftType
      );

      const forklift: ForkliftDetailsModel = {
        heatGain: isNaNReturnDefault(forkliftDetails.heatGain ?? NaN),
        hours: isNaNReturnDefault(forkliftDetails.forkliftUsage ?? NaN),
        forkliftType: findForkliftType?.value ?? "No forklifts",
      };

      dispatch(actionSelector.saveForkliftDetails(forklift));
    }
  }, [
    errors,
    isValidating,
    dirtyFields,
    dispatch,
    watch,
    forkliftTypeOptions.options,
  ]);

  return (
    <FormProvider {...methods}>
      <form>
        <ForkliftDetails forkliftTypeOptions={forkliftTypeOptions} />
      </form>
    </FormProvider>
  );
};

export default ForkliftDetailsContainer;
