import { ProductCapacity } from "../types/ProductCapacity";
import { ThumbnailsResponse } from "../types/ThumbnailsResponse";

export const enrichProductsWithThumbnails = <T extends ProductCapacity>(
  products: T[],
  thumbnailResponses: ThumbnailsResponse[]
): T[] => {
  let productsWithThumbnails: T[] = products.map((product) => {
    let correnpsondingProductWithThumbnail = thumbnailResponses.find(
      (thumbnailResponse: any) =>
        thumbnailResponse.product_code === product.product_code
    );
    return correnpsondingProductWithThumbnail
      ? {
          ...product,
          thumbnail: correnpsondingProductWithThumbnail.thumbnail,
        }
      : {
          ...product,
          thumbnail: null,
        };
  });
  return productsWithThumbnails;
};
