const selectStyles = {
  container: (provided: any, state: any) => ({
    ...provided,
    marginTop: "4px",
    zIndex: "0",
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    fontSize: "14px",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    fontSize: "14px",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: "14px",
    paddingTop: "5px",
    paddingBottom: "5px",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: "4px",
  }),
};

const errorStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: "1px solid var(--trs-destructive-red)",
    color: "var(--trs-destructive-red)",
  }),
};

export { selectStyles, errorStyle };
