import React, { FC } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import RadioGroup, { RadioValue } from "../../components/RadioGroup/RadioGroup";
import Select from "../../components/Select/Select";
import { SelectOption } from "../../types/SelectOption";
import LineLengthDetails from "../sections/LineLengthDetails";
import styles from "../../css/GridForm.module.css";
import {
  capacityRules,
  sstRules,
  compressorInletSuperheatRules,
  liquidSubcoolingRules,
} from "./PipeSizeCalculationValidation";
import { logPageEvent } from "../../helpers/amplitude";

interface PipeSizeCalculationFormProps {
  refrigerantOptions: SelectOption[];
}

const PipeSizeCalculationForm: FC<PipeSizeCalculationFormProps> = ({
  refrigerantOptions,
}) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();
  const refTempMethodRadioList: RadioValue[] = [
    { label: "Mid point", value: "mid" },
    { label: "Dew point", value: "dew" },
  ];

  // Could not move the following rules out into PipeSizeCalculationValidation
  // file because they are reliant on the getValues() functions
  const sctRules: RegisterOptions = {
    required: "Required",
    pattern: {
      value: /^-?\d*\.?\d*$/,
      message: "Must be a number",
    },
    validate: {
      minToBeMoreThanSST: (value) => {
        const saturatedSuctionTemp = getValues("saturatedSuctionTemp");
        return value >= saturatedSuctionTemp + 10 || `Must exceed SST by 10 K`;
      },
    },
    max: {
      value: 65,
      message: "Must be less than 65\u00b0 Celsius",
    },
    valueAsNumber: true,
  };

  return (
    <>
      <div className={styles.gridContainer}>
        <div>
          <div className="section-title">System details</div>

          <Select
            {...register("refrigerant", {
              required: "Required",
            })}
            labelText="Refrigerant"
            options={refrigerantOptions}
            hasErrors={errors.refrigerant}
            errorMessage={errors.refrigerant?.message}
          />

          <Input
            {...register("operatingCapacity", capacityRules)}
            labelText="Required capacity"
            placeholderText="0 to 999000"
            innerLabel="W"
            hasErrors={errors.operatingCapacity}
            errorMessage={errors.operatingCapacity?.message}
          />
        </div>
        <div>
          <div className="section-title">Temperature details</div>

          <RadioGroup
            {...register("referenceTemperatureMethod", {
              required: "Required",
            })}
            labelText="Reference temp. method"
            values={refTempMethodRadioList}
            hasErrors={errors.referenceTemperatureMethod}
            errorMessage={errors.referenceTemperatureMethod?.message}
          />

          <Input
            {...register("saturatedSuctionTemp", sstRules)}
            labelText="Saturated suction temp. (SST)"
            placeholderText={`-50\u00b0 to +25\u00b0`}
            innerLabel="°C"
            hasErrors={errors.saturatedSuctionTemp}
            errorMessage={errors.saturatedSuctionTemp?.message}
          />

          <Input
            {...register("saturatedCondensingTemp", sctRules)}
            labelText="Saturated condensing temp. (SCT)"
            placeholderText={`-50\u00b0 to +65\u00b0`}
            innerLabel="°C"
            hasErrors={errors.saturatedCondensingTemp}
            errorMessage={errors.saturatedCondensingTemp?.message}
          />

          <Input
            {...register("compressorInletTemp", compressorInletSuperheatRules)}
            labelText="Compressor inlet superheat"
            placeholderText="0 to +80"
            innerLabel="K"
            hasErrors={errors.compressorInletTemp}
            errorMessage={errors.compressorInletTemp?.message}
          />

          <Input
            {...register("liquidSubcooling", liquidSubcoolingRules)}
            labelText="Liquid subcooling"
            placeholderText="0 to +15"
            innerLabel="K"
            hasErrors={errors.liquidSubcooling}
            errorMessage={errors.liquidSubcooling?.message}
          />
        </div>

        <div>
          <div className="section-title">Equivalent line length details</div>

          <LineLengthDetails />
        </div>
      </div>
      <div>
        <Button
          buttonStyle="primary"
          buttonType="submit"
          onClickHandler={(e) =>
            logPageEvent("Calculate - Pipe Size Calculation")
          }
        >
          Calculate
        </Button>
      </div>
    </>
  );
};

export default PipeSizeCalculationForm;
