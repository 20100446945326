import React from "react";
import { navigate } from "hookrouter";
import styles from "./NavigationBar.module.css";
import Image from "../Image/Image";

export interface NavigationBarProps {
  linkName: string;
  linkURL: string;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ linkName, linkURL }) => {
  const onClickBack = () => {
    if (
      window.confirm(
        "OK to Continue?\n\nThe data you've customised on this page will be lost if you return to the dashboard."
      )
    ) {
      navigate(linkURL);
    }
  };
  return (
    <div className={styles.navigationBarContainer}>
      <button className={styles.navigationLink} onClick={onClickBack}>
        <Image filename="arrow_back" altText={`Go back to ${linkURL}`} />
        <span className={styles.linkText}>{linkName}</span>
      </button>
    </div>
  );
};

export default NavigationBar;
