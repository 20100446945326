import React, { FC } from "react";
import PrintSectionHeader from "../../../components/PrintTemplate/PrintSectionHeader";
import { CondensingUnitProductCapacity } from "../../../types/CondensingUnitProductCapacity";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";
import isAnEmptyObject from "../../../helpers/emptyObject";

const CondensingUnitPerformanceData: FC<{
  selectedCondensingUnit: CondensingUnitProductCapacity;
}> = ({ selectedCondensingUnit }) => {
  let capacity: number = isAnEmptyObject(selectedCondensingUnit)
    ? 0
    : selectedCondensingUnit.capacity;
  let power: number = isAnEmptyObject(selectedCondensingUnit)
    ? 0
    : selectedCondensingUnit.power;
  let sst: number = isAnEmptyObject(selectedCondensingUnit)
    ? 0
    : selectedCondensingUnit.evaporator_temperature;
  let sct: number = isAnEmptyObject(selectedCondensingUnit)
    ? 0
    : selectedCondensingUnit.condenser_temperature;

  return (
    <>
      <PrintSectionHeader>Performance data</PrintSectionHeader>

      <table className={styles.tablePrint}>
        <tbody>
          <tr>
            <td width="30%">Saturated suction temp. (SST)</td>
            <td width="20%">{sst.toFixed(2)}°C</td>
            <td width="30%">Power</td>
            <td width="20%">{power.toFixed(0)} W</td>
          </tr>
          <tr>
            <td width="30%">Saturated condensing temp. (SCT)</td>
            <td width="20%">{sct.toFixed(2)}°C</td>
            <td width="30%">Coefficient of performance (COP)</td>
            <td width="20%">{(capacity / power).toFixed(2)}</td>
          </tr>
          <tr>
            <td width="30%">Capacity</td>
            <td width="20%">{capacity} W</td>
            <td width="30%">&nbsp;</td>
            <td width="20%">&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default CondensingUnitPerformanceData;
