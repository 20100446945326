import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Input from "../../../../components/Input/Input";
import { usageRules, heatGainRules } from "./ForkliftDetailsValidation";
import heatLoadStyles from "../../../page/HeatLoad.module.css";
import Select from "../../../../components/Select/Select";
import { ProductFilter } from "../../../../types/ProductFilter";
import FormRow from "../../../../components/FormRow/FormRow";

const ForkliftDetails: FC<{ forkliftTypeOptions: ProductFilter }> = ({
  forkliftTypeOptions,
}) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();
  const forkliftOptions = forkliftTypeOptions.options;
  const forkliftType = watch("forkliftType");

  useEffect(() => {
    let extendedValues;
    let option = forkliftOptions.find((o) => o.key === forkliftType);

    if (option && "extended_values" in option) {
      extendedValues = option["extended_values"];
      if (extendedValues)
        setValue("heatGain", extendedValues["heat_generated"], {
          shouldDirty: true,
        });
    }
  }, [forkliftType, setValue, forkliftOptions]);

  return (
    <div
      data-testid="forklift-details"
      className={heatLoadStyles.narrowFormContainer}
    >
      <div>
        <FormRow>
          <Select
            {...register("forkliftType", {
              required: "Required",
            })}
            labelText="Forklifts"
            options={forkliftOptions}
            hasErrors={errors.forkliftType}
            errorMessage={errors.forkliftType?.message}
            hideDefaultOption
            fieldSize="medium"
          />

          {watch("forkliftType") !== "none" && (
            <Input
              {...register("heatGain", heatGainRules)}
              labelText="Heat gain"
              innerLabel="W"
              placeholderText="0 to 100000"
              hasErrors={errors.heatGain}
              errorMessage={errors.heatGain?.message}
              fieldSize="medium"
            />
          )}
        </FormRow>

        {watch("forkliftType") !== "none" && (
          <Input
            {...register("forkliftUsage", usageRules)}
            labelText="Usage per day"
            innerLabel="hrs"
            placeholderText="0 to 24"
            hasErrors={errors.forkliftUsage}
            errorMessage={errors.forkliftUsage?.message}
            fieldSize="small"
          />
        )}
      </div>
    </div>
  );
};

export default ForkliftDetails;
