import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { SelectOption } from "../../../../types/SelectOption";
import Select from "../../../../components/Select/Select";
import Input from "../../../../components/Input/Input";
import {
  loadCapacityRules,
  runtimeRules,
  noOfCondensingUnitsRules,
  noOfEvaporatorUnitsRules,
} from "../CapitalEquipmentValidation";
import { standardsRatingOptions } from "../../../../types/StandardsRating";

interface HeatLoadFormProps {
  refrigerantOptions: SelectOption[];
}

const HeatLoadSection: FC<HeatLoadFormProps> = ({ refrigerantOptions }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Select
        {...register("standardsRating", { required: "Required" })}
        labelText="Standards rating"
        options={standardsRatingOptions}
        hasErrors={errors.standardsRating}
        errorMessage={errors.standardsRating?.message}
      />

      <Input
        {...register("load", loadCapacityRules)}
        labelText="Required capacity"
        placeholderText="0 to 999000"
        innerLabel="W"
        hasErrors={errors.load}
        errorMessage={errors.load?.message}
      />

      <Select
        {...register("refrigerant", { required: "Required" })}
        labelText="Refrigerant"
        options={refrigerantOptions}
        hasErrors={errors.refrigerant}
        errorMessage={errors.refrigerant?.message}
      />

      <Input
        {...register("runtime", runtimeRules)}
        labelText="Design run time per day"
        placeholderText="0.00 to 24.00"
        innerLabel="hrs"
        hasErrors={errors.runtime}
        errorMessage={errors.runtime?.message}
      />

      <Input
        {...register("noOfCondensingUnits", noOfCondensingUnitsRules)}
        labelText="No. of systems"
        placeholderText="1 to 20"
        hasErrors={errors.noOfCondensingUnits}
        errorMessage={errors.noOfCondensingUnits?.message}
      />

      <Input
        {...register("noOfEvaporatorUnits", noOfEvaporatorUnitsRules)}
        labelText="No. of evaporators per system"
        placeholderText="1 to 20"
        hasErrors={errors.noOfEvaporatorUnits}
        errorMessage={errors.noOfEvaporatorUnits?.message}
      />
    </>
  );
};

export default HeatLoadSection;
