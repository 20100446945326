import React from "react";
import Layout from "./components/Layout/Layout";
import Error from "./components/Error/Error";

const ErrorPage: React.FC<{}> = () => {
  return (
    <Layout>
      <Error />
    </Layout>
  );
};

export default ErrorPage;
