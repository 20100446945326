import React, { FC, useCallback, useEffect, useState } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import Humidity from "../../../../calculations/humidity/TDtoRelativeHumidity";
import DefaultRuntime from "../../../../calculations/runtime/DefaultRuntime";
import Input from "../../../../components/Input/Input";
import {
  liquidSubcoolingRules,
  roomTempRules,
  tempDiffRules,
} from "../CapitalEquipmentValidation";
import calculateSuperheatAndLiquidSubcooling from "../../../../calculations/superheatAndLiquidSubcooling/calculateSuperheatAndLiquidSubcooling";
import { useSelector } from "react-redux";
import { HeatLoadTotalCapacity } from "../../../../heatLoad/types/HeatLoadCapacity";

const DesignDetailsSection: FC = () => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const [customStandardsRating, setCustomStandardsRating] = useState(false);
  const watchRoomTemp = watch("roomTemp");
  const watchHumidity = watch("humidity");
  const watchTempDiff = watch("td");
  const watchStandardsRating = watch("standardsRating");
  const validHumidity = (humidity: number) => {
    return humidity >= 50 && humidity <= 99;
  };

  const setInletSuperheatAndLiquidSubcooling = useCallback(
    (
      roomTemperature: number,
      tempDifference: number,
      standardsRating: string
    ) => {
      const evaporatorTemperature = +roomTemperature - +tempDifference;
      let { compressorInletSuperheat, liquidSubcooling } =
        calculateSuperheatAndLiquidSubcooling(
          standardsRating,
          evaporatorTemperature
        );

      setValue("liquidSubcooling", liquidSubcooling);
      setValue("compressorInletSuperheat", compressorInletSuperheat);
    },
    [setValue]
  );

  useEffect(() => {
    let tempDifference = getValues("td");
    let standardsRating = getValues("standardsRating");
    let roomTemperature = getValues("roomTemp");

    if (tempDifference && +tempDifference >= 3 && +tempDifference <= 12) {
      setValue(
        "humidity",
        Humidity.tDToHumidityFormat(parseInt(tempDifference)).toString()
      );
    }

    setInletSuperheatAndLiquidSubcooling(
      +roomTemperature,
      +tempDifference,
      standardsRating
    );
  }, [
    watchTempDiff,
    setValue,
    getValues,
    setInletSuperheatAndLiquidSubcooling,
  ]);

  useEffect(() => {
    let humidity = getValues("humidity");
    if (validHumidity(+humidity)) {
      let td: number = Humidity.formatHumidityToTemperatureDifference(
        +humidity
      );
      setValue("td", td);
    }
  }, [watchHumidity, setValue, getValues]);

  const heatLoadSummary: HeatLoadTotalCapacity = useSelector((state: any) => {
    return state.heatLoad.heatLoadCapacity;
  });

  const equipmentCapacity = Math.round(heatLoadSummary.fanLoad.total);

  useEffect(() => {
    let roomTemperature = getValues("roomTemp");
    let standardsRating = getValues("standardsRating");
    let tempDifference = getValues("td");

    if (roomTemperature && equipmentCapacity === 0) {
      let defaultRuntime = DefaultRuntime.runtimeDefaults(+roomTemperature);
      setValue("runtime", defaultRuntime);
    }

    setInletSuperheatAndLiquidSubcooling(
      +roomTemperature,
      +tempDifference,
      standardsRating
    );
  }, [
    watchRoomTemp,
    setValue,
    getValues,
    setInletSuperheatAndLiquidSubcooling,
    equipmentCapacity,
  ]);

  useEffect(() => {
    let standardsRating = getValues("standardsRating");
    let roomTemperature = getValues("roomTemp");
    let tempDifference = getValues("td");

    setCustomStandardsRating(standardsRating === "Custom");
    setInletSuperheatAndLiquidSubcooling(
      +roomTemperature,
      +tempDifference,
      standardsRating
    );
  }, [
    watchStandardsRating,
    getValues,
    setValue,
    setInletSuperheatAndLiquidSubcooling,
  ]);

  const compressorInletSuperheatRules: RegisterOptions = {
    required: "Required",
    pattern: {
      value: /^\d*\.?\d*$/,
      message: "Must be a number",
    },
    validate: {
      maxToBeCheckedForCustomOnly: (value) => {
        let standardsRating = getValues("standardsRating");

        return (
          (standardsRating === "Custom" && value <= 80) ||
          standardsRating !== "Custom" ||
          `Must be between 0 and +80 Kelvin`
        );
      },
    },
    min: {
      value: 0,
      message: "Must be between 0 and +80 Kelvin",
    },
    valueAsNumber: true,
  };

  return (
    <>
      <Input
        {...register("roomTemp", roomTempRules)}
        labelText="Design room temp."
        placeholderText="-40° to +20°"
        innerLabel="°C"
        hasErrors={errors.roomTemp}
        errorMessage={errors.roomTemp?.message}
      />

      <Input
        {...register("td", tempDiffRules)}
        labelText="Design temp. difference (TD)"
        placeholderText="3 to 12"
        innerLabel="K"
        hasErrors={errors.td}
        errorMessage={errors.td?.message}
      />

      {/* <Input
         {...register("humidity", humidityRules)}
        labelText="Design room humidity"
        placeholderText="50 to 99"
        innerLabel="%"
        hasErrors={errors.humidity}
        errorMessage={errors.humidity?.message}
      /> */}

      <Input
        {...register("compressorInletSuperheat", compressorInletSuperheatRules)}
        labelText="Compressor inlet superheat"
        placeholderText="0 to 25"
        innerLabel="K"
        hasErrors={errors.compressorInletSuperheat}
        errorMessage={errors.compressorInletSuperheat?.message}
        disabled={!customStandardsRating}
      />

      <Input
        {...register("liquidSubcooling", liquidSubcoolingRules)}
        labelText="Liquid subcooling"
        placeholderText="0 to 15"
        innerLabel="K"
        hasErrors={errors.liquidSubcooling}
        errorMessage={errors.liquidSubcooling?.message}
        disabled={!customStandardsRating}
      />
    </>
  );
};

export default DesignDetailsSection;
