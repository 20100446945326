// TODO - all values used below will have propor names confirmed by Rafi (still waiting for this)

const roomInfiltrationLoadCalculation = (
  wetBulbTemp: number,
  roomTemp: number,
  roomVolume: number,
  roomUsageFactor: number
) => {
  let heatRemovedToCoolAir = calculateHeatRemoveToCoolAir(
    wetBulbTemp,
    roomTemp
  );
  let starting_value;
  if (roomTemp < 0) {
    starting_value = 1.01366;
  } else {
    starting_value = 1.3426;
  }

  return (
    starting_value *
    roomVolume ** 0.43876 *
    roomUsageFactor *
    heatRemovedToCoolAir
  );
};

const calculateHeatRemoveToCoolAir = (
  wetBulbTemp: number,
  roomTemp: number
) => {
  return (
    0.961 * wetBulbTemp ** 1.4049 - 1.77 * 1.0094 ** wetBulbTemp * roomTemp
  );
};

export default roomInfiltrationLoadCalculation;
