import { useEffect, useState } from "react";
import { Service } from "../../types/Service";
import { CondensingUnitProductCapacities } from "../../types/CondensingUnitProductCapacity";
import { CondenserSearchRequest } from "../../types/CondenserSearchRequest";
import { useDispatch, useSelector } from "react-redux";
import { default as actionSelector } from "../duck/selectors";
import getUrl from "../../helpers/api";
import CondensingUnitResponse from "../../types/CondensingUnitResponse";
import { FilterValues } from "../../types/FilterValues";
import { addFilterSelectionsToUrl } from "./useEvaporatorProducts";

declare const window: any;
const ENDPOINT_CONDENSING_UNITS = `${window.__runtime_configuration.ENDPOINT_CONDENSING_UNITS}/call/condensing-unit`;

const useCondensingUnits = (): {
  condenserUnitService: Service<CondensingUnitProductCapacities>;
} => {
  const [result, setResult] = useState<
    Service<CondensingUnitProductCapacities>
  >({ status: "init" });

  const dispatch = useDispatch();

  const filterSelections: FilterValues = useSelector(
    (state: any) => state.product.condensingUnitFilterSelections
  );

  const filter: CondenserSearchRequest = useSelector((state: any) => {
    return state.product.condensingUnitSearchRequest;
  });

  const capacityPerCondensingUnit = (
    load: number,
    noOfCondensingUnits: number
  ) => {
    return Math.round(load / noOfCondensingUnits);
  };

  const roomTempBelowZero = useSelector(
    (state: any) => state.product.roomTempBelowZero
  );

  useEffect(() => {
    if (filter.load !== undefined && filter.load !== 0) {
      setResult({ status: "loading" });
      let u = new URL(ENDPOINT_CONDENSING_UNITS);
      let params: { [index: string]: string | number } = {
        refrigerant: filter.refrigerant,
        capacity: capacityPerCondensingUnit(
          filter.load,
          filter.noOfCondensingUnits
        ),
        evaporator_temperature:
          filter.roomTemperature - filter.temperatureDifference,
        ambient_temperature: filter.ambientTemperature,
        mid_or_dew: filter.mid_or_dew,
        condition: filter.standardsRating,
        super_heat: filter.compressorInletSuperheat,
        sub_cooling: filter.liquidSubcooling,
      };
      // @ts-ignore
      Object.keys(params).forEach((key) =>
        u.searchParams.append(key, params[key].toString())
      );

      addFilterSelectionsToUrl(u, filterSelections);

      dispatch(actionSelector.clearSelectedProducts());

      getUrl<CondensingUnitResponse>(u.href)
        .then((response) => {
          setResult({
            status: "loaded",
            payload: { ...response, filterSelections },
          });
        })
        .catch((error) => {
          setResult({ status: "error", error });
        })
        .finally(() => {
          dispatch(actionSelector.loadingBalancePerformance(false));
        });
    }
  }, [filter, filterSelections, roomTempBelowZero, dispatch]);

  return {
    condenserUnitService: result,
  };
};

export default useCondensingUnits;
