import React, { FC } from "react";
import { useSelector } from "react-redux";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";
import { SafetyAndRuntime } from "../../types/SafetyAndRuntime";

const SafetyFactorAndRuntimeOutput: FC = () => {
  const safetyAndRuntime: SafetyAndRuntime = useSelector((state: any) => {
    return state.heatLoad.safetyAndRuntime;
  });

  return (
    <>
      {safetyAndRuntime && (
        <table className={styles.tableHalfWidth}>
          <tbody>
            <tr>
              <td width="40%">Run time per day</td>
              <td width="60%">{safetyAndRuntime.equipmentRuntime} hrs</td>
            </tr>
            <tr>
              <td width="40%">Safety factor</td>
              <td width="60%">{safetyAndRuntime.safetyFactor}%</td>
            </tr>
            <tr>
              <td width="40%">Fan load</td>
              <td width="60%">{safetyAndRuntime.fanFactor}%</td>
            </tr>
            {safetyAndRuntime.otherLoads > 0 && (
              <tr>
                <td width="40%">Other loads</td>
                <td width="60%">{safetyAndRuntime.otherLoads} W</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default SafetyFactorAndRuntimeOutput;
