import React, { FC } from "react";
import { useSelector } from "react-redux";
import isAnEmptyObject from "../../../helpers/emptyObject";
import { PipeSizeCalculations } from "../../../types/PipeSizeCalculationResponse";
import styles from "./PipeSizePrint.module.css";

const DisplayPipeLine: FC<{
  productCode: number;
  pipeSize: number;
  wallThickness: number;
  velocity: number;
  totalPressureDrop: number;
  uomPressure: string;
  temperatureDifference: number;
}> = ({
  productCode,
  pipeSize,
  wallThickness,
  velocity,
  totalPressureDrop,
  uomPressure,
  temperatureDifference,
  children,
}) => {
  return (
    <tr>
      <td>{children}</td>
      <td>
        <a
          href={`https://actrol.com.au/product/-${productCode}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {pipeSize}"
        </a>
      </td>
      <td className={styles.alignRight}>{wallThickness} mm</td>
      <td className={styles.alignRight}>{velocity} m/s</td>
      <td className={styles.alignRight}>
        {totalPressureDrop} {uomPressure}
      </td>
      <td className={styles.alignRight}>{temperatureDifference} K</td>
    </tr>
  );
};

const SelectedPipesOutput: FC<{ uomPressure: string }> = ({ uomPressure }) => {
  const selectedLiquidLineHorizontalPipe: PipeSizeCalculations = useSelector(
    (state: any) => state.pipes.selectedLiquidLinePipes.horizontal
  );

  const selectedLiquidLineVerticalPipe: PipeSizeCalculations = useSelector(
    (state: any) => state.pipes.selectedLiquidLinePipes.vertical
  );

  const selectedSuctionLineHorizontalPipe: PipeSizeCalculations = useSelector(
    (state: any) => state.pipes.selectedSuctionLinePipes.horizontal
  );

  const selectedSuctionLineVerticalPipe: PipeSizeCalculations = useSelector(
    (state: any) => state.pipes.selectedSuctionLinePipes.vertical
  );

  const selectedDischargeLineHorizontalPipe: PipeSizeCalculations = useSelector(
    (state: any) => state.pipes.selectedDischargeLinePipes.horizontal
  );

  const selectedDischargeLineVerticalPipe: PipeSizeCalculations = useSelector(
    (state: any) => state.pipes.selectedDischargeLinePipes.vertical
  );

  return (
    <table className={styles.pipeTable}>
      <thead>
        <tr>
          <th className={styles.alignLeft}>Line</th>
          <th className={styles.alignLeft}>Size</th>
          <th className={styles.alignRight}>Thickness</th>
          <th className={styles.alignRight}>Velocity</th>
          <th className={styles.alignRight}>&#916; Pressure</th>
          <th className={styles.alignRight}>&#916; Saturation temp.</th>
        </tr>
      </thead>
      <tbody>
        {!isAnEmptyObject(selectedLiquidLineHorizontalPipe) && (
          <DisplayPipeLine
            productCode={selectedLiquidLineHorizontalPipe.id}
            pipeSize={selectedLiquidLineHorizontalPipe.od_in}
            wallThickness={selectedLiquidLineHorizontalPipe.wall_thickness}
            velocity={selectedLiquidLineHorizontalPipe.horizontal.velocity}
            totalPressureDrop={
              uomPressure === "kPa"
                ? selectedLiquidLineHorizontalPipe.horizontal
                    .total_pressure_drop
                : selectedLiquidLineHorizontalPipe.horizontal
                    .total_pressure_drop_psi
            }
            uomPressure={uomPressure}
            temperatureDifference={
              selectedLiquidLineHorizontalPipe.horizontal.temperature_difference
            }
          >
            Liquid - Horizontal
          </DisplayPipeLine>
        )}
        {!isAnEmptyObject(selectedLiquidLineVerticalPipe) && (
          <DisplayPipeLine
            productCode={selectedLiquidLineVerticalPipe.id}
            pipeSize={selectedLiquidLineVerticalPipe.od_in}
            wallThickness={selectedLiquidLineVerticalPipe.wall_thickness}
            velocity={selectedLiquidLineVerticalPipe.vertical.velocity}
            totalPressureDrop={
              uomPressure === "kPa"
                ? selectedLiquidLineVerticalPipe.vertical.total_pressure_drop
                : selectedLiquidLineVerticalPipe.vertical
                    .total_pressure_drop_psi
            }
            uomPressure={uomPressure}
            temperatureDifference={
              selectedLiquidLineVerticalPipe.vertical.temperature_difference
            }
          >
            Liquid - Vertical
          </DisplayPipeLine>
        )}
        {!isAnEmptyObject(selectedSuctionLineHorizontalPipe) && (
          <DisplayPipeLine
            productCode={selectedSuctionLineHorizontalPipe.id}
            pipeSize={selectedSuctionLineHorizontalPipe.od_in}
            wallThickness={selectedSuctionLineHorizontalPipe.wall_thickness}
            velocity={selectedSuctionLineHorizontalPipe.horizontal.velocity}
            totalPressureDrop={
              uomPressure === "kPa"
                ? selectedSuctionLineHorizontalPipe.horizontal
                    .total_pressure_drop
                : selectedSuctionLineHorizontalPipe.horizontal
                    .total_pressure_drop_psi
            }
            uomPressure={uomPressure}
            temperatureDifference={
              selectedSuctionLineHorizontalPipe.horizontal
                .temperature_difference
            }
          >
            Suction - Horizontal
          </DisplayPipeLine>
        )}
        {!isAnEmptyObject(selectedSuctionLineVerticalPipe) && (
          <DisplayPipeLine
            productCode={selectedSuctionLineVerticalPipe.id}
            pipeSize={selectedSuctionLineVerticalPipe.od_in}
            wallThickness={selectedSuctionLineVerticalPipe.wall_thickness}
            velocity={selectedSuctionLineVerticalPipe.vertical.velocity}
            totalPressureDrop={
              uomPressure === "kPa"
                ? selectedSuctionLineVerticalPipe.vertical.total_pressure_drop
                : selectedSuctionLineVerticalPipe.vertical
                    .total_pressure_drop_psi
            }
            uomPressure={uomPressure}
            temperatureDifference={
              selectedSuctionLineVerticalPipe.vertical.temperature_difference
            }
          >
            Suction - Vertical
          </DisplayPipeLine>
        )}
        {!isAnEmptyObject(selectedDischargeLineHorizontalPipe) && (
          <DisplayPipeLine
            productCode={selectedDischargeLineHorizontalPipe.id}
            pipeSize={selectedDischargeLineHorizontalPipe.od_in}
            wallThickness={selectedDischargeLineHorizontalPipe.wall_thickness}
            velocity={selectedDischargeLineHorizontalPipe.horizontal.velocity}
            totalPressureDrop={
              uomPressure === "kPa"
                ? selectedDischargeLineHorizontalPipe.horizontal
                    .total_pressure_drop
                : selectedDischargeLineHorizontalPipe.horizontal
                    .total_pressure_drop_psi
            }
            uomPressure={uomPressure}
            temperatureDifference={
              selectedDischargeLineHorizontalPipe.horizontal
                .temperature_difference
            }
          >
            Discharge - Horizontal
          </DisplayPipeLine>
        )}
        {!isAnEmptyObject(selectedDischargeLineVerticalPipe) && (
          <DisplayPipeLine
            productCode={selectedDischargeLineVerticalPipe.id}
            pipeSize={selectedDischargeLineVerticalPipe.od_in}
            wallThickness={selectedDischargeLineVerticalPipe.wall_thickness}
            velocity={selectedDischargeLineVerticalPipe.vertical.velocity}
            totalPressureDrop={
              uomPressure === "kPa"
                ? selectedDischargeLineVerticalPipe.vertical.total_pressure_drop
                : selectedDischargeLineVerticalPipe.vertical
                    .total_pressure_drop_psi
            }
            uomPressure={uomPressure}
            temperatureDifference={
              selectedDischargeLineVerticalPipe.vertical.temperature_difference
            }
          >
            Discharge - Vertical
          </DisplayPipeLine>
        )}
      </tbody>
    </table>
  );
};

export default SelectedPipesOutput;
