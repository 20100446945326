import React from "react";
import { components } from "react-select";
import Image from "../../components/Image/Image";

const SearchIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Image filename="search_black" altText="search expand" />
      </components.DropdownIndicator>
    )
  );
};

export default SearchIndicator;
