import React, { FC } from "react";
import { useSelector } from "react-redux";
import PrintTemplate from "../../../components/PrintTemplate/PrintTemplate";
import { EvaporatorProductCapacity } from "../../../types/EvaporatorProductCapacity";
import { EvaporatorSearchRequest } from "../../../types/EvaporatorSearchRequest";
import EvaporatorInputs from "./EvaporatorInputs";
import EvaporatorPerformanceData from "./EvaporatorPerformanceData";
import SelectedEvaporatorPrint from "./SelectedEvaporatorPrint";

const EvaporatorPrint: FC = () => {
  const selectedEvaporator: EvaporatorProductCapacity = useSelector(
    (state: any) => state.product.selectedSingleEvaporator
  );

  const evaporatorSearchRequest: EvaporatorSearchRequest = useSelector(
    (state: any) => state.product.evaporatorSingleSearchRequest
  );

  return (
    <PrintTemplate pageTitle="Equipment selection">
      <div className="page-break">
        <div className="page-header">Evaporator inputs</div>

        <EvaporatorInputs evaporatorSearchRequest={evaporatorSearchRequest} />

        <div className="page-header">Evaporator selection</div>

        <EvaporatorPerformanceData
          selectedEvaporator={selectedEvaporator}
          evaporatorSearchRequest={evaporatorSearchRequest}
        />

        <SelectedEvaporatorPrint
          selectedEvaporator={selectedEvaporator}
          selectedRefrigerant={evaporatorSearchRequest.refrigerant}
          numberOfEvaporators={1}
        />
      </div>
    </PrintTemplate>
  );
};

export default EvaporatorPrint;
