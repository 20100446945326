import RoomFloor from "./RoomFloor";
import Wall from "./Wall";
import Edge from "./Edge";
import Vertex from "./Vertex";
import { RoomCeiling } from "./RoomCeiling";

const vertexAt = (x: number, y: number, z: number = 0) => {
  return new Vertex(x, y, z);
};
class SimpleRoomFloor extends RoomFloor {
  private readonly _length: number;
  private readonly _width: number;
  constructor(length: number, width: number) {
    let edgers: Edge[] = [];
    edgers.push(new Edge(vertexAt(0, 0), vertexAt(length, 0), length));
    edgers.push(new Edge(vertexAt(length, 0), vertexAt(length, width), width));
    edgers.push(new Edge(vertexAt(length, width), vertexAt(0, width), length));
    edgers.push(new Edge(vertexAt(0, width), vertexAt(0, 0), width));

    super(edgers);
    this._length = length;
    this._width = width;
  }

  static getVertexAt() {
    return vertexAt(0, 0);
  }

  getWalls(height: number) {
    let walls: Wall[] = [];
    let count = 0;
    walls = this.edgers.map((x) => {
      let edgers: Edge[] = [];
      let start = x.vertexStart;
      let end = x.vertexEnd;
      edgers.push(
        new Edge(
          vertexAt(start.x, start.y, start.z),
          vertexAt(end.x, end.y, end.z),
          x.edgeLength
        )
      );
      edgers.push(
        new Edge(
          vertexAt(end.x, end.y, end.z),
          vertexAt(end.x, end.y, end.z + height),
          height
        )
      );
      edgers.push(
        new Edge(
          vertexAt(end.x, end.y, end.z + height),
          vertexAt(start.x, start.y, start.z + height),
          x.edgeLength
        )
      );
      edgers.push(
        new Edge(
          vertexAt(start.x, start.y, start.z + height),
          vertexAt(start.x, start.y, start.z),
          height
        )
      );
      return new Wall("wall_" + count++, edgers);
    });
    return walls;
  }

  ceiling(height: number) {
    let edgers: Edge[] = [];
    edgers.push(
      new Edge(
        vertexAt(0, 0, height),
        vertexAt(this._length, 0, height),
        this._length
      )
    );
    edgers.push(
      new Edge(
        vertexAt(this._length, 0, height),
        vertexAt(this._length, this._width, height),
        this._width
      )
    );
    edgers.push(
      new Edge(
        vertexAt(this._length, this._width, height),
        vertexAt(0, this._width, height),
        this._length
      )
    );
    edgers.push(
      new Edge(
        vertexAt(0, this._width, height),
        vertexAt(0, 0, height),
        this._width
      )
    );

    return new RoomCeiling(edgers);
  }
}

export default SimpleRoomFloor;
