import React, { FC } from "react";
import ToolTip from "../../components/ToolTip/ToolTip";
import { PipeSizeCalculations } from "../../types/PipeSizeCalculationResponse";
import { SectionType } from "./PipeCalculationResults";
import Image from "../../components/Image/Image";

interface TooltipProps {
  pipe_gauge: PipeSizeCalculations;
  section: SectionType;
}

const status_files = {
  GOOD: "check_circle_outline",
  WARN: "error_outline",
  RECOMMENDED: "check_circle_outline",
};

const MeetsCriteriaTooltip: FC<TooltipProps> = ({ pipe_gauge, section }) => {
  return (
    <ToolTip
      tooltipText={pipe_gauge[section].selection_criteria.message}
      tooltipPosition="top"
    >
      <Image
        filename={status_files[pipe_gauge[section].selection_criteria.status]}
        altText={pipe_gauge[section].selection_criteria.status}
      />
    </ToolTip>
  );
};

export default MeetsCriteriaTooltip;
