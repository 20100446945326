import {
  ExtendedValues,
  FilterOption,
  ProductFilter,
} from "../../../types/ProductFilter";
import { GroupedOption, Option } from "../../../types/SearchSelect";

const filterExtendedValues = (
  productValue: string,
  productFilter: ProductFilter
) => {
  let extendedValues: ExtendedValues = {};
  if (productValue && productFilter.options) {
    let option = productFilter.options.find((o) => o.key === productValue);
    if (option && "extended_values" in option && option["extended_values"]) {
      extendedValues = option["extended_values"];
    }
  }
  return extendedValues;
};

const buildProductGroupOptions = (
  productFilter: ProductFilter,
  productCategoryFilter: ProductFilter
) => {
  const categoryOptions = productCategoryFilter.options;
  const productOptions = productFilter.options;

  const productGroupedOptions: GroupedOption[] = categoryOptions.map((cat) => {
    let productsForCategory: FilterOption[] = productOptions.filter(
      (p) => p.parent === cat.key
    );
    let products: Option[] = productsForCategory.map((p) => {
      return {
        value: p.key,
        label: p.value,
      };
    });

    return {
      label: cat.value,
      options: products,
    };
  });

  return productGroupedOptions;
};

const buildProductGroupOptionsWithGroupAppended = (
  optionsFilter: ProductFilter,
  groupsFilter: ProductFilter
) => {
  const groups = groupsFilter.options;
  const options = optionsFilter.options;

  const productGroupedOptions: GroupedOption[] = groups.map((cat) => {
    let optionsForGroup: FilterOption[] = options.filter(
      (p) => p.parent === cat.key
    );
    let products: Option[] = optionsForGroup.map((p) => {
      if (cat.value === " ") {
        return {
          value: p.key,
          label: p.value,
        };
      }

      if (cat.value === "{New Zealand}") {
        return {
          value: p.key,
          label: p.value + ", NZ",
        };
      } else {
        return {
          value: p.key,
          label: p.value + ", " + cat.value,
        };
      }
    });

    if (cat.value === "{New Zealand}") {
      return {
        label: "New Zealand",
        options: products,
      };
    } else {
      return {
        label: cat.value,
        options: products,
      };
    }
  });

  return productGroupedOptions;
};

export {
  filterExtendedValues,
  buildProductGroupOptions,
  buildProductGroupOptionsWithGroupAppended,
};
