class Vertex {
  private readonly _x: number;
  private readonly _y: number;
  private readonly _z: number;
  constructor(x: number, y: number, z: number = 0) {
    this._x = x;
    this._y = y;
    this._z = z;
  }

  get x() {
    return this._x;
  }
  get y() {
    return this._y;
  }
  get z() {
    return this._z;
  }
}

export default Vertex;
