import { FilterOption, ProductFilter } from "../types/ProductFilter";

export function sortProductFilterArray(a: ProductFilter, b: ProductFilter) {
  var nameA = a.type.toUpperCase(); // ignore upper and lowercase
  var nameB = b.type.toUpperCase(); // ignore upper and lowercase

  return sortArrayDescending(nameA, nameB);
}

export function sortFilterOptionArray(a: FilterOption, b: FilterOption) {
  var valueA = a.value; // ignore upper and lowercase
  var valueB = b.value; // ignore upper and lowercase

  return sortArrayAscending(valueA, valueB);
}

function sortArrayDescending(a: any, b: any) {
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }

  // names must be equal
  return 0;
}

function sortArrayAscending(a: any, b: any) {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }

  // names must be equal
  return 0;
}
