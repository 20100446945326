const calcRelativeHumidity = (
  dryBulbTemp: number,
  wetBulbTemp: number
): number => {
  // http://www.1728.org/relhum.htm
  // Numbers are used to prevent floating point calculation errors
  var e = Number("2.71828182845904");
  var N = Number("0.6687451584");
  var ed =
    Number("6.112") *
    e ** ((Number("17.502") * dryBulbTemp) / (Number("240.97") + dryBulbTemp));
  var ew =
    Number("6.112") *
    e ** ((Number("17.502") * wetBulbTemp) / (Number("240.97") + wetBulbTemp));
  var relative_humidity =
    ((ew -
      N * (1 + Number("0.00115") * wetBulbTemp) * (dryBulbTemp - wetBulbTemp)) *
      100) /
    ed;

  return Math.round(relative_humidity);
};

export default calcRelativeHumidity;
