import { FieldError, RegisterOptions } from "react-hook-form";

interface ProductDetailsErrors {
  products?:
    | {
        product?:
          | {
              value?: FieldError | undefined;
              label?: FieldError | undefined;
              isFixed?: FieldError | undefined;
              isDisabled?: FieldError | undefined;
            }
          | undefined;
        amount?: FieldError | undefined;
        enteringTemp?: FieldError | undefined;
        finalTemp?: FieldError | undefined;
        pullDownTime?: FieldError | undefined;
      }[]
    | undefined;
}

interface ProductDetailsDirtyFields {
  products?:
    | {
        product?:
          | {
              value?: boolean | undefined;
              label?: boolean | undefined;
              isFixed?: boolean | undefined;
              isDisabled?: boolean | undefined;
            }
          | undefined;
        amount?: boolean | undefined;
        enteringTemp?: boolean | undefined;
        finalTemp?: boolean | undefined;
        pullDownTime?: boolean | undefined;
      }[]
    | undefined;
}

const productAmountRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Must be between 0 and 600000 Kilograms",
  },
  max: {
    value: 600000,
    message: "Must be between 0 and 600000 Kilograms",
  },
  valueAsNumber: true,
};

const enteringTempRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^-?\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: -40,
    message: "Must be between -40\u00b0 and +100\u00b0 Celsius",
  },
  max: {
    value: 100,
    message: "Must be between -40\u00b0 and +100\u00b0 Celsius",
  },
  valueAsNumber: true,
};

const pullDownTimeRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 1,
    message: "Must be between 1 and 120 hours",
  },
  max: {
    value: 120,
    message: "Must be between 1 and 120 hours",
  },
  valueAsNumber: true,
};

const isValidateProducts = (errors: ProductDetailsErrors, index: number) => {
  return (
    !errors.products?.[index]?.product &&
    !errors.products?.[index]?.amount &&
    !errors.products?.[index]?.enteringTemp &&
    !errors.products?.[index]?.finalTemp &&
    !errors.products?.[index]?.pullDownTime
  );
};

const isDirtyProducts = (
  dirtyFields: ProductDetailsDirtyFields,
  index: number
) => {
  return (
    dirtyFields.products?.[index]?.product &&
    dirtyFields.products?.[index]?.amount &&
    dirtyFields.products?.[index]?.enteringTemp &&
    dirtyFields.products?.[index]?.finalTemp &&
    dirtyFields.products?.[index]?.pullDownTime
  );
};

export {
  productAmountRules,
  enteringTempRules,
  pullDownTimeRules,
  isValidateProducts,
  isDirtyProducts,
};
