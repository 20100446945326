import React, { FC } from "react";
import PrintSectionHeader from "../../../components/PrintTemplate/PrintSectionHeader";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";
import { EvaporatorSearchRequest } from "../../../types/EvaporatorSearchRequest";

const EvaporatorInputs: FC<{
  evaporatorSearchRequest: EvaporatorSearchRequest;
}> = ({ evaporatorSearchRequest }) => {
  return (
    <>
      <PrintSectionHeader>Heat load and refrigerant</PrintSectionHeader>

      <table className={styles.tableHalfWidth}>
        <tbody>
          <tr>
            <td width="60%">Required capacity</td>
            <td width="40%">
              {evaporatorSearchRequest.desiredEvaporatorCapacity} W
            </td>
          </tr>
          <tr>
            <td width="60%">Refrigerant</td>
            <td width="40%">{evaporatorSearchRequest.refrigerant}</td>
          </tr>
        </tbody>
      </table>

      <PrintSectionHeader>Temperature details</PrintSectionHeader>

      <table className={styles.tableHalfWidth}>
        <tbody>
          <tr>
            <td width="60%">Reference temp. method</td>
            <td width="40%">
              {evaporatorSearchRequest.mid_or_dew === "mid"
                ? "Mid point"
                : "Dew point"}
            </td>
          </tr>
          <tr>
            <td width="60%">Design room temp.</td>
            <td width="40%">{evaporatorSearchRequest.roomTemp}°C</td>
          </tr>
          <tr>
            <td width="60%">Design temp. difference</td>
            <td width="40%">{evaporatorSearchRequest.td} K</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default EvaporatorInputs;
