import React from "react";
import styles from "./ResultGrid.module.css";

interface ResultGridProps {
  tableComponent: React.ReactNode;
  asideComponent: React.ReactNode;
}

const ResultGrid: React.FC<ResultGridProps> = ({
  tableComponent,
  asideComponent,
}) => {
  return (
    <div className={styles.resultGrid}>
      {tableComponent}
      <aside className={styles.resultGridAside}>{asideComponent}</aside>
    </div>
  );
};

export default ResultGrid;
