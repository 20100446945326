import {
  PIPE_SIZE_CALCULATION,
  SELECT_DISCHARGE_LINE_PIPE_HORIZONTAL,
  SELECT_DISCHARGE_LINE_PIPE_VERTICAL,
  SELECT_LIQUID_LINE_PIPE_HORIZONTAL,
  SELECT_LIQUID_LINE_PIPE_VERTICAL,
  SELECT_SUCTION_LINE_PIPE_HORIZONTAL,
  SELECT_SUCTION_LINE_PIPE_VERTICAL,
  CLEAR_ALL_PIPE_SELECTIONS,
} from "./types";

import { PipeSizeCalculationRequest } from "../../types/PipeSizeCalculationRequest";
import { PipeSizeCalculations } from "../../types/PipeSizeCalculationResponse";

const pipeSizeCalculation = (
  pipeSizeCalculationRequest: PipeSizeCalculationRequest
) => {
  return {
    type: PIPE_SIZE_CALCULATION,
    pipeSizeCalculationRequest,
  };
};

const selectLiquidLinePipeHorizontal = (
  selectedLiquidLinePipe: PipeSizeCalculations
) => {
  return {
    type: SELECT_LIQUID_LINE_PIPE_HORIZONTAL,
    selectedLiquidLinePipe,
  };
};

const selectLiquidLinePipeVertical = (
  selectedLiquidLinePipe: PipeSizeCalculations
) => {
  return {
    type: SELECT_LIQUID_LINE_PIPE_VERTICAL,
    selectedLiquidLinePipe,
  };
};

const selectDischargeLinePipeHorizontal = (
  selectedDischargeLinePipe: PipeSizeCalculations
) => {
  return {
    type: SELECT_DISCHARGE_LINE_PIPE_HORIZONTAL,
    selectedDischargeLinePipe,
  };
};

const selectDischargeLinePipeVertical = (
  selectedDischargeLinePipe: PipeSizeCalculations
) => {
  return {
    type: SELECT_DISCHARGE_LINE_PIPE_VERTICAL,
    selectedDischargeLinePipe,
  };
};

const selectSuctionLinePipeHorizontal = (
  selectedSuctionLinePipe: PipeSizeCalculations
) => {
  return {
    type: SELECT_SUCTION_LINE_PIPE_HORIZONTAL,
    selectedSuctionLinePipe,
  };
};

const selectSuctionLinePipeVertical = (
  selectedSuctionLinePipe: PipeSizeCalculations
) => {
  return {
    type: SELECT_SUCTION_LINE_PIPE_VERTICAL,
    selectedSuctionLinePipe,
  };
};

const clearAllPipeSelections = () => {
  return {
    type: CLEAR_ALL_PIPE_SELECTIONS,
  };
};

export default {
  pipeSizeCalculation,
  selectLiquidLinePipeHorizontal,
  selectLiquidLinePipeVertical,
  selectSuctionLinePipeHorizontal,
  selectSuctionLinePipeVertical,
  selectDischargeLinePipeHorizontal,
  selectDischargeLinePipeVertical,
  clearAllPipeSelections,
};
