import Face from "./Face";
import { Door, DoorAttributes, Opening } from "./Door";
import Edge from "./Edge";
import AirCondition from "./AirCondition";
import { calculateDoorInfiltrationLoad } from "./service/DoorInfiltrationServices";
import HeatCapacity from "./service/HeatCapacity";

class Wall extends Face {
  private _multiDoors: Opening[];

  constructor(faceId: string, edgers: Edge[]) {
    super(faceId, edgers);
    this._multiDoors = [];
  }

  addOrUpdateDoor(
    doorId: string | undefined,
    height: number,
    width: number,
    doorAttributes: DoorAttributes,
    noOfDoors: number
  ): string {
    this._multiDoors = this.opening.filter((d) => d.door.id !== doorId);
    let newDoor = new Door(doorId ? doorId : this.doorId(), height, width);
    if (doorAttributes["uValue"] && !isNaN(doorAttributes["uValue"])) {
      newDoor.uValue = doorAttributes["uValue"];
    } else if (
      doorAttributes["conductivity"] &&
      !isNaN(doorAttributes["conductivity"])
    ) {
      newDoor.conductivity = doorAttributes["conductivity"];
      newDoor.thickness = this.thickness;
    }
    newDoor.doorAttributes = doorAttributes;
    newDoor.outsideAirCondition = this.outsideAirCondition;
    this._multiDoors.push(new Opening(newDoor, noOfDoors));
    return newDoor.id;
  }

  private doorId() {
    return "door_" + (this._multiDoors.length + 1);
  }

  get opening() {
    return this._multiDoors;
  }

  transmittableSurfaceArea() {
    let area = super.surfaceArea();

    this._multiDoors.forEach((d) => {
      area -= d.door.surfaceArea() * d.noOfDoors;
    });

    return area;
  }

  infiltrationLoad = (doorId: string, roomAirCondition: AirCondition) => {
    if (!roomAirCondition)
      throw new TypeError(
        "Missing mandatory attributes room temperature and room humidity"
      );

    if (!this.outsideAirCondition)
      throw new TypeError(
        "Missing mandatory attributes outside temperature and outside humidity"
      );

    let door: Opening | undefined = this._multiDoors.find(
      (d) => d.door.id === doorId
    );

    if (door === undefined)
      throw new TypeError(
        "Cannot find the door :" + doorId + " in " + this._multiDoors
      );

    let request = {
      doorArea: door.door.surfaceArea() / (1000 * 1000),
      doorHeight: door.door.height / 1000,
      doorwayUseFactor: door.door.doorAttributes.doorwayUseFactor,
      openingTime: door.door.doorAttributes.openingTime,
      doorwayOpeningFactor: door.door.doorAttributes.doorwayOpeningFactor,
      effectiveness: door.door.doorAttributes.effectiveness,
      roomTemperature: roomAirCondition.roomTemperature,
      roomHumidity: roomAirCondition.roomHumidity,
      outsideTemperature: this.outsideAirCondition.roomTemperature,
      outsideHumidity: this.outsideAirCondition.roomHumidity,
    };

    return calculateDoorInfiltrationLoad(request).then((heatCapacity) => {
      let noOfDoors = door?.noOfDoors ?? 1;
      let heatLoadForOpening: HeatCapacity = {
        airflow: heatCapacity.airflow,
        latent: heatCapacity.latent * noOfDoors,
        sensible: heatCapacity.sensible * noOfDoors,
      };
      return heatLoadForOpening;
    });
  };

  findDoor(doorId: string): Opening {
    let door = this._multiDoors.find((o) => o.door.id === doorId);

    if (!door) {
      throw new TypeError("Invalid door Id");
    }
    return door;
  }

  removeDoor(doorId: string) {
    this._multiDoors = this.opening.filter((d) => d.door.id !== doorId);
  }
}

export default Wall;
