import React from "react";
import SelectedEquipment from "./SelectedEquipment";
import { useSelector } from "react-redux";
import isAnEmptyObject from "../../../helpers/emptyObject";

const SelectedEquipmentContainer: React.FC<{}> = () => {
  const noOfCondensingUnits = useSelector((state: any) => {
    return state.product.filter.noOfCondensingUnits;
  });
  const noOfEvaporatorUnits = useSelector((state: any) => {
    return state.product.filter.noOfEvaporatorUnits;
  });
  const selectedCondensingUnit = useSelector((state: any) => {
    return state.product.selectedCondensingUnit;
  });
  const selectedEvaporator = useSelector((state: any) => {
    return state.product.selectedEvaporator;
  });
  const selectedRefrigerant = useSelector((state: any) => {
    return state.product.filter.refrigerant;
  });

  return isAnEmptyObject(selectedCondensingUnit) &&
    isAnEmptyObject(selectedEvaporator) ? (
    <SelectedEquipment loading={true} />
  ) : (
    <SelectedEquipment
      noOfCondensingUnits={noOfCondensingUnits}
      noOfEvaporatorUnits={noOfCondensingUnits * noOfEvaporatorUnits}
      condensingUnit={selectedCondensingUnit}
      evaporatorUnit={selectedEvaporator}
      refrigerant={selectedRefrigerant}
    />
  );
};

export default SelectedEquipmentContainer;
