import React from "react";
import SelectedCondensingUnit from "./SelectedCondensingUnit";
import { useSelector } from "react-redux";
import isAnEmptyObject from "../../../helpers/emptyObject";

const SelectedCondensingUnitContainer: React.FC<{}> = () => {
  const selectedCondensingUnit = useSelector((state: any) => {
    return state.product.selectedSingleCondensingUnit;
  });
  const selectedRefrigerant = useSelector((state: any) => {
    return state.product.condensingUnitSearchFilter.refrigerant;
  });

  return isAnEmptyObject(selectedCondensingUnit) ? (
    <SelectedCondensingUnit loading={true} />
  ) : (
    <SelectedCondensingUnit
      condensingUnit={selectedCondensingUnit}
      refrigerant={selectedRefrigerant}
    />
  );
};

export default SelectedCondensingUnitContainer;
