import { WallDimensions } from "../heatLoad/types/Room";

interface WallSize {
  length: number;
  height: number;
}

export const findWallSize = (wall: WallDimensions | undefined): WallSize => {
  if (!wall || !wall.vertices || wall.vertices.length !== 4) {
    return { length: 0, height: 0 };
  }
  let vs = wall.vertices;
  let length = vs[1].x - vs[0].x;
  let width = vs[1].y - vs[0].y;
  let height = vs[2].z - vs[1].z;
  return { length: Math.sqrt(length * length + width * width), height: height };
};
