import React, { FC } from "react";
import { useSelector } from "react-redux";
import PrintSectionHeader from "../../../components/PrintTemplate/PrintSectionHeader";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";
import isAnEmptyObject from "../../../helpers/emptyObject";
import { CapitalEquipmentFilterModel } from "../../../types/CapitalEquipmentFilterModel";
import { EvaporatorProductCapacity } from "../../../types/EvaporatorProductCapacity";

const CombinedSearchBalancedPerformance: FC<{
  equipmentSearch: CapitalEquipmentFilterModel;
}> = ({ equipmentSearch }) => {
  const selectedEvaporator: EvaporatorProductCapacity = useSelector(
    (state: any) => state.product.selectedEvaporator
  );

  let balance_capacity = isAnEmptyObject(selectedEvaporator)
    ? 0
    : selectedEvaporator.balance_performance.balance_capacity;

  const capacity: number = isAnEmptyObject(selectedEvaporator)
    ? 0
    : balance_capacity * equipmentSearch.noOfCondensingUnits;
  const power: number = isAnEmptyObject(selectedEvaporator)
    ? 0
    : selectedEvaporator.balance_performance.balance_power;
  const runtime = isAnEmptyObject(selectedEvaporator)
    ? 0
    : (equipmentSearch.runtime * equipmentSearch.load) /
      (balance_capacity * equipmentSearch.noOfCondensingUnits);

  return (
    <>
      <PrintSectionHeader>Balanced performance</PrintSectionHeader>

      <table className={styles.tablePrint}>
        <tbody>
          <tr>
            <td width="30%">Temperature difference</td>
            <td width="20%">
              {(
                equipmentSearch.roomTemp -
                (isAnEmptyObject(selectedEvaporator)
                  ? 0
                  : selectedEvaporator.balance_performance
                      .evaporator_temperature)
              ).toFixed(2)}{" "}
              K
            </td>
            <td width="30%">Power</td>
            <td width="20%">
              {isAnEmptyObject(selectedEvaporator)
                ? 0
                : selectedEvaporator.balance_performance.balance_power.toFixed(
                    0
                  )}{" "}
              W
            </td>
          </tr>
          <tr>
            <td width="30%">Saturated suction temp. (SST)</td>
            <td width="20%">
              {isAnEmptyObject(selectedEvaporator)
                ? 0
                : selectedEvaporator.balance_performance.evaporator_temperature.toFixed(
                    2
                  )}
              °C
            </td>
            <td width="30%">Coefficient of performance (COP)</td>
            <td width="20%">{(capacity / power).toFixed(2)}</td>
          </tr>
          <tr>
            <td width="30%">Saturated condensing temp. (SCT)</td>
            <td width="20%">
              {isAnEmptyObject(selectedEvaporator)
                ? 0
                : selectedEvaporator.balance_performance.condenser_temperature.toFixed(
                    2
                  )}
              °C
            </td>
            <td width="30%">Run time per day</td>
            <td width="20%">{runtime.toFixed(1)} hours</td>
          </tr>
          <tr>
            <td width="30%">Capacity</td>
            <td width="20%">{capacity.toFixed(0)} W</td>
            <td width="30%">&nbsp;</td>
            <td width="20%">&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default CombinedSearchBalancedPerformance;
