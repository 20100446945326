import ColdStorage from "./ColdStorage";
import SimpleRoomFloor from "./SimpleRoomFloor";
import { RoomCeiling } from "./RoomCeiling";

class SimpleCoolStorage extends ColdStorage {
  private readonly _length: number;
  private readonly _width: number;
  private readonly _height: number;

  constructor(l: number, w: number, h: number) {
    let floor = new SimpleRoomFloor(l, w);
    let ceiling: RoomCeiling = floor.ceiling(h);
    let volume = (l / 1000) * (w / 1000) * (h / 1000);
    super(floor, floor.getWalls(h), ceiling, volume);
    this._length = l;
    this._width = w;
    this._height = h;
  }

  get height(): number {
    return this._height;
  }

  get width(): number {
    return this._width;
  }

  get length(): number {
    return this._length;
  }

  isValid() {
    return (
      this.length > 100 &&
      this.width > 100 &&
      this.height > 100 &&
      super.isValid()
    );
  }
}

export default SimpleCoolStorage;
