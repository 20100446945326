import { ProductCapacity } from "../types/ProductCapacity";

export const findBestMatchWithImage = (
  results: ProductCapacity[],
  best_match: ProductCapacity
) => {
  if (results === undefined || best_match === undefined) {
    return undefined;
  }
  let bestMatch = results.find((item) => item.model === best_match.model);
  if (bestMatch === undefined) {
    bestMatch = best_match;
  }
  return bestMatch;
};
