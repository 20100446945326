import React from "react";
import styles from "./SelectedProductPlaceholder.module.css";
import Placeholder from "../Placeholder/Placeholder";

interface Props {
  heading: string;
  subheading: string;
}

const SelectedProductPlaceholder: React.FC<Props> = ({
  heading,
  subheading,
}) => (
  <div className={styles.container}>
    <Placeholder heading={heading} subheading={subheading} />
  </div>
);

export default SelectedProductPlaceholder;
