import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../BalancedPerformance/BalancedPerformance.module.css";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder/LoadingPlaceholder";
import { EvaporatorPerformanceData } from "../../../types/EvaporatorPerformanceData";
import { EvaporatorProductCapacity } from "../../../types/EvaporatorProductCapacity";
import { EvaporatorSearchRequest } from "../../../types/EvaporatorSearchRequest";

export const EvaporatorPerformance: React.FC<{}> = () => {
  const [performanceData, setPerformanceData] =
    useState<EvaporatorPerformanceData>({
      requestedTempDiff: 0,
      requestedSst: 0,
      capacity: 0,
      tempDiffAtRequiredCapacity: 0,
    });

  const loadingBalancePerformance: boolean = useSelector(
    (state: any) => state.product.loadingBalancePerformance
  );

  const selectedEvaporator: EvaporatorProductCapacity = useSelector(
    (state: any) => state.product.selectedSingleEvaporator
  );

  const evaporatorSearchRequest: EvaporatorSearchRequest = useSelector(
    (state: any) => state.product.evaporatorSingleSearchRequest
  );

  useEffect(() => {
    if (evaporatorSearchRequest.td && selectedEvaporator.operating_capacity) {
      const capacity: number = selectedEvaporator.operating_capacity.capacity;

      setPerformanceData({
        requestedTempDiff: evaporatorSearchRequest.td,
        requestedSst:
          evaporatorSearchRequest.roomTemp - evaporatorSearchRequest.td,
        capacity: capacity,
        tempDiffAtRequiredCapacity:
          (evaporatorSearchRequest.desiredEvaporatorCapacity *
            evaporatorSearchRequest.td) /
          capacity,
      });
    }
  }, [selectedEvaporator, evaporatorSearchRequest]);

  return (
    <section className={styles.container}>
      <div>
        <div className="section-header">Performance data</div>
        <div className={styles.performanceValues}>
          <div className={styles.performanceAttributeGrid}>
            <p>Requested temp. difference (TD)</p>
            {loadingBalancePerformance && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!loadingBalancePerformance && (
              <p>
                <b>{performanceData.requestedTempDiff.toFixed(2)}</b> K
              </p>
            )}
          </div>
          <div className={styles.performanceAttributeGrid}>
            <p>Requested saturated suction temp. (SST)</p>
            {loadingBalancePerformance && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!loadingBalancePerformance && (
              <p>
                <b>{performanceData.requestedSst.toFixed(2)}</b> °C
              </p>
            )}
          </div>
          <div className={styles.performanceAttributeGrid}>
            <p>Capacity</p>
            {loadingBalancePerformance && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!loadingBalancePerformance && (
              <p>
                <b>{performanceData.capacity.toFixed(0)}</b> W
              </p>
            )}
          </div>
          <div className={styles.performanceAttributeGrid}>
            <p>Temp. difference (TD) at required capacity</p>
            {loadingBalancePerformance && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!loadingBalancePerformance && (
              <p>
                <b>{performanceData.tempDiffAtRequiredCapacity.toFixed(2)}</b> K
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
