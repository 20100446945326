import React, { FC } from "react";
import { useSelector } from "react-redux";
import InfoText from "../../../components/InfoText/InfoText";
import { StandardRoom } from "../../../types/HeatLoad";
import { TransmissionDetailsForm } from "../../types/TransmissionDetailsForm";
import TransmissionDetailsContainer from "./TransmissionDetailsContainer";

const TransmissionDetailsParent: FC<{}> = () => {
  const roomDetails: StandardRoom = useSelector((state: any) => {
    return state.heatLoad.room;
  });

  const transmissionFormEnabled: boolean = useSelector((state: any) => {
    return state.heatLoad.formSections.transmissionDetailsEnabled;
  });

  const roomTemperature = roomDetails.temperature;
  const outsideTemp = roomDetails.outsideTemperature;
  const groundTemp = roomDetails.groundTemperature;

  let defaultValues: TransmissionDetailsForm | undefined;

  if (transmissionFormEnabled) {
    defaultValues = {
      wallsAreTheSame: "yes",
      walls: [
        {
          wallId: "1",
          material: "eps",
          thickness: roomTemperature >= 0 ? 75 : 150,
          ambientTemp: outsideTemp,
        },
      ], //Initialise walls with 1 entry
      ceiling: {
        material: "eps",
        thickness: roomTemperature >= 0 ? 75 : 150,
        ambientTemp: outsideTemp,
      },
      floor: {
        material: "concrete_ground",
        thickness: roomTemperature >= 0 ? 100 : 200,
        groundTemp: groundTemp !== undefined ? +groundTemp : 27,
        ...(roomTemperature < 0 && {
          hasInsulation: "true",
          insulationMaterial: "eps",
          insulationThickness: 100,
          heating: "16_5_wattage_per_sqm",
        }),
      },
    };
  }

  return transmissionFormEnabled && defaultValues !== undefined ? (
    <TransmissionDetailsContainer
      preloadedValues={defaultValues}
      roomDetails={roomDetails}
      transmissionFormEnabled={transmissionFormEnabled}
    />
  ) : (
    <InfoText testid="no-transmission-details">
      Add your Transmission details after you’ve defined your room details.
    </InfoText>
  );
};

export default TransmissionDetailsParent;
