import Face from "./Face";
import Edge from "./Edge";
import { LoadCapacity } from "./load/TotalHeatLoad";

export interface FloorInsulation {
  conductivity?: number;
  thickness?: number;
}

export interface FloorHeating {
  wattagePerSqm?: number;
}

class RoomFloor extends Face {
  private _floorInsulation: FloorInsulation;
  private _floorHeating: FloorHeating;

  constructor(sides: Edge[]) {
    super("room_floor", sides);
    this._floorHeating = { wattagePerSqm: 0 };
  }

  set floorInsulation(i: FloorInsulation) {
    this._floorInsulation = i;
  }

  get floorInsulation() {
    return this._floorInsulation;
  }

  set floorHeating(i: FloorHeating) {
    this._floorHeating = i;
  }

  get floorHeating() {
    return this._floorHeating;
  }

  get uValue() {
    let uValue = this.conductivity / (this.thickness / 1000);
    if (
      this.floorInsulation &&
      this.floorInsulation.thickness &&
      this.floorInsulation.conductivity
    ) {
      uValue =
        1 /
        (this.thickness / 1000 / this.conductivity +
          this.floorInsulation.thickness /
            1000 /
            this.floorInsulation.conductivity);
    }
    return +uValue.toFixed(3);
  }

  transmissionLoad(room_temperature: number): LoadCapacity {
    if (!this.outsideAirCondition)
      throw new Error("Missing outside room temperature");

    if (!this.conductivity && !this.uValue)
      throw new Error(
        "Missing conductivity and uValue, require either one of them"
      );
    if (this.conductivity && !this.thickness)
      throw new Error(
        "Missing thickness. Provide uValue or thickness with conductivity"
      );

    let outsideTemperature = this.outsideAirCondition.roomTemperature;
    let surfaceAreaInSQM = this.transmittableSurfaceArea() / (1000 * 1000);

    outsideTemperature += this.facingSun ? 5 : 0;
    let temperatureDifference = outsideTemperature - room_temperature;

    let uValue = this.uValue;

    let sensibleHeat = 0;
    if (uValue) {
      sensibleHeat = this.surfaceTransmissionLoad(
        surfaceAreaInSQM,
        uValue,
        temperatureDifference
      );
    }

    return { sensible: Math.round(sensibleHeat * 1000) / 1000, latent: 0 };
  }

  floorHeatingLoad(wattage_per_sqm: number): LoadCapacity {
    let surfaceAreaInSQM = this.transmittableSurfaceArea() / (1000 * 1000);

    return { sensible: wattage_per_sqm * surfaceAreaInSQM, latent: 0 };
  }

  clearFloorInsulation() {
    this._floorInsulation = { conductivity: undefined, thickness: undefined };
  }

  clearFloorHeating() {
    this._floorHeating = { wattagePerSqm: 0 };
  }
}

export default RoomFloor;
