import React, { FC, useState } from "react";
import Layout from "../../components/Layout/Layout";
import styles from "./HeatLoad.module.css";
import layoutStyles from "../../components/Layout/Layout.module.css";
import RoomDetailsContainer from "../sections/RoomDetails/RoomDetailsContainer";
import HeatLoadCalculationContainer from "../sections/HeatLoadCalculaton/HeatLoadCalculationContainer";
import { SummaryViewContainer } from "../sections/CalculationSummary/SummaryViewContainer";
import { PreviewContainer } from "../sections/RoomPreview/PreviewContainer";
import useHeatLoadFilters from "../hooks/useHeatLoadFilters";
import AdditionalLoadsContainer from "../sections/AdditionalLoads/AdditionalLoadsContainer";
import Accordion from "../../components/Accordion/Accordion";
import HeatLoadLineDetailsContainer from "../sections/CalculationSummary/HeatLoadLineDetailsContainer";
import TransmissionDetailsParent from "../sections/TransmissionDetails/TransmissionDetailsParent";
import DoorDetailsParent from "../sections/TransmissionDetails/DoorDetails/DoorDetailsParent";
import ProductDetailsParent from "../sections/ProductDetails/ProductDetailsParent";
import SafetyAndRuntimeDetailsParent from "../sections/SafetyAndRuntimeDetails/SafetyAndRuntimeDetailsParent";
import HeatLoadPrint from "../sections/HeatLoadPrint/HeatLoadPrint";
import Button from "../../components/Button/Button";
import { logPageEvent } from "../../helpers/amplitude";
import { navigate } from "hookrouter";
import Image from "../../components/Image/Image";
import PageTitleSection from "../../components/PageTitleSection/PageTitleSection";

declare const window: any;
const HEAT_LOAD_TEST_LOGS_ENABLE = `${window.__runtime_configuration.HEAT_LOAD_TEST_LOGS_ENABLE}`;

const HeatLoad: FC = () => {
  // disabling this for now since Rafi has requested to hide the advanced calc option for now
  // see https://reecegroup.atlassian.net/browse/MAX-10750 for further details
  // this will most likely be reverted at some point
  //
  // const dispatch = useDispatch();
  //
  // const advancedCalcState = useSelector((state: any) => {
  //   return state.heatLoad.advancedCalculation;
  // });
  //
  // const saveAdvancedCalcState = (newState: boolean) => {
  //   dispatch(actionSelector.saveAdvancedCalcState(newState));
  // };

  const [enableNavigateToESButton, setEnabledNavigateToESButton] =
    useState(false);

  useHeatLoadFilters();
  const [openHeatLoadSummary, setOpenHeatLoadSummary] = useState({});
  const [enablePrint, setEnablePrint] = useState(false);
  const [showPrintTemplate, setShowPrintTemplate] = useState(false);
  const confirmationText =
    "OK to Continue?\n\nThe data you've customised on this page will be lost if you continue to the next page.";
  // useEffect(() => {
  //   return () => {
  //     dispatch(actionSelector.clearAll());
  //   };
  // }, [dispatch]);

  const showHeatLoadSummary = () => {
    setOpenHeatLoadSummary({ heatLoadSummary: true });
    setEnablePrint(true);
    setEnabledNavigateToESButton(true);
  };

  return (
    <Layout>
      <PageTitleSection title="Heat load calculator">
        <Button
          isDisabled={!enablePrint}
          buttonStyle="secondary"
          onClickHandler={() => {
            setShowPrintTemplate(true);
            logPageEvent("Heat Load Calculation - Print");
            setTimeout(() => {
              window.print();
              setShowPrintTemplate(false);
            }, 100);
          }}
        >
          Print
        </Button>
        <Button
          isDisabled={!enableNavigateToESButton}
          onClickHandler={() => {
            if (window.confirm(confirmationText)) {
              logPageEvent("Next button clicked from HLC to ES");
              navigate("/equipment-selection");
            }
          }}
        >
          Next: Equipment selection
          <Image filename="navigate_next_white" altText="" />
        </Button>
      </PageTitleSection>

      {/*disabling this for now since Rafi has requested to hide the advanced calc option for now*/}
      {/*see https://reecegroup.atlassian.net/browse/MAX-10750 for further details*/}
      {/*this will most likely be reverted at some point*/}
      {/* */}
      {/*<CalcTypeSelection*/}
      {/*  initialState={advancedCalcState}*/}
      {/*  handlerFunction={saveAdvancedCalcState}*/}
      {/*/>*/}

      <div
        className={`${styles.heatLoadContainer} ${layoutStyles.contentContainer}`}
      >
        <div>
          <Accordion
            defaultOpenSections={{ locationDetails: true }}
            multiOpen={false}
          >
            <div title="Location and room details" id="locationDetails">
              <RoomDetailsContainer showHeatLoadSummary={showHeatLoadSummary} />
            </div>
            <div title="Transmission details" id="transmissionDetails">
              <TransmissionDetailsParent />
            </div>
            <div title="Door details" id="doorDetails">
              <DoorDetailsParent />
            </div>
            <div title="Product details" id="productDetails">
              <ProductDetailsParent />
            </div>
            <div title="Additional loads" id="additionalLoads">
              <AdditionalLoadsContainer />
            </div>
            <div title="Safety factor and runtime" id="safetyFactorAndRuntime">
              <SafetyAndRuntimeDetailsParent />
            </div>
          </Accordion>

          <HeatLoadCalculationContainer
            enableHeatLoadLogs={HEAT_LOAD_TEST_LOGS_ENABLE === "true"}
          />
        </div>

        <div className={styles.summaryContainer}>
          <Accordion multiOpen resetOpenSections={openHeatLoadSummary}>
            <div title="Preview" id="previewContainer">
              <PreviewContainer />
            </div>
            <div title="Heat load summary" id="heatLoadSummary">
              <SummaryViewContainer />
            </div>
            <div title="Heat load details" id="heatLoadDetails">
              <HeatLoadLineDetailsContainer />
            </div>
          </Accordion>
        </div>
      </div>
      <div>
        <HeatLoadPrint showPrintTemplate={showPrintTemplate} />
      </div>
    </Layout>
  );
};

export default HeatLoad;
