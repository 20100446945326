import React, { FC } from "react";
import styles from "./Section.module.css";
import cx from "classnames";

const Section: FC<{ noPadding?: boolean }> = ({
  noPadding = false,
  children,
}) => {
  return (
    <section className={cx(styles.sectionStyle, noPadding && styles.noPadding)}>
      {children}
    </section>
  );
};

export default Section;
