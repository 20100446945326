import React from "react";
import SelectedEvaporator from "./SelectedEvaporator";
import { useSelector } from "react-redux";
import isAnEmptyObject from "../../../helpers/emptyObject";

const SelectedEvaporatorContainer: React.FC<{}> = () => {
  const selectedEvaporator = useSelector((state: any) => {
    return state.product.selectedSingleEvaporator;
  });
  const selectedRefrigerant = useSelector((state: any) => {
    return state.product.evaporatorSingleSearchRequest.refrigerant;
  });

  return isAnEmptyObject(selectedEvaporator) ? (
    <SelectedEvaporator loading={true} />
  ) : (
    <SelectedEvaporator
      evaporator={selectedEvaporator}
      refrigerant={selectedRefrigerant}
    />
  );
};

export default SelectedEvaporatorContainer;
