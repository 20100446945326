import React, { FC, useEffect, useState } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import Input from "../../../components/Input/Input";
import {
  outsideHumidityRules,
  outsideTemperatureRules,
  roomHumidityRules,
  roomLengthRules,
} from "./RoomDetailsValidation";
import heatLoadStyles from "../../page/HeatLoad.module.css";
import SearchGroupSelect from "../../../hook-form-components/SearchSelect/SearchGroupSelect";
import { GroupedOption } from "../../../types/SearchSelect";
import { ProductFilter } from "../../../types/ProductFilter";
import calcRelativeHumidity from "../../../calculations/humidity/RelativeHumidityGivenBulbTemp";
import FormRow from "../../../components/FormRow/FormRow";
import Button from "../../../components/Button/Button";
import styles from "./RoomDetails.module.css";
import { useSelector } from "react-redux";
import Select from "../../../components/Select/Select";

const RoomDetails: FC<{
  locationGroupedOptions: GroupedOption[];
  locationFilter: ProductFilter;
  roomType: string;
  roomUsage: ProductFilter;
  initialHeatLoadButtonEnabled: boolean;
  initialHeatLoadHandler: () => void;
}> = ({
  locationGroupedOptions,
  locationFilter,
  roomType,
  roomUsage,
  initialHeatLoadButtonEnabled,
  initialHeatLoadHandler,
}) => {
  const {
    control,
    register,
    formState: { errors, dirtyFields },
    watch,
    setValue,
    getValues,
    trigger,
    formState,
  } = useFormContext();
  const [initialHeatLoadClicked, setInitialHeatLoadClicked] = useState(false);
  const locationOptions = locationFilter.options;
  const roomUsageOptions = roomUsage.options;
  const [locationSelected, outsideTemperature, wetBulbTemperature] = watch([
    "locationSelected",
    "outsideTemperature",
    "wetBulbTemperature",
  ]);
  const advancedCalc = useSelector((state: any) => {
    return state.heatLoad.advancedCalculation;
  });

  useEffect(() => {
    // Fill location related fields based on location selected
    let extendedValues;
    let option = locationOptions.find((o) => o.key === locationSelected?.value);

    if (option && "extended_values" in option) {
      extendedValues = option["extended_values"];
      if (extendedValues) {
        setValue("outsideTemperature", extendedValues["dry_bulb_temp_celsius"]);
        setValue("wetBulbTemperature", extendedValues["wet_bulb_temp_celsius"]);
        trigger(["outsideTemperature", "wetBulbTemperature"]);

        setValue("groundTemperature", extendedValues.ground_temp_celsius);
      }
    }
  }, [locationSelected, setValue, locationOptions, trigger]);

  useEffect(() => {
    // Recalculate relative humidity on user inputs
    const outsideTemperature = getValues("outsideTemperature");
    const wetBulbTemperature = getValues("wetBulbTemperature");

    if (
      formState.isValidating &&
      !isNaN(outsideTemperature) &&
      !isNaN(wetBulbTemperature)
    ) {
      setValue(
        "outsideHumidity",
        calcRelativeHumidity(
          Number(outsideTemperature),
          Number(wetBulbTemperature)
        )
      );
      trigger("outsideHumidity");
    }
  }, [
    outsideTemperature,
    wetBulbTemperature,
    formState.isValidating,
    getValues,
    setValue,
    trigger,
  ]);

  const temperatureRules: RegisterOptions = {
    required: "Required",
    min: {
      value: -40,
      message: "Must be between -40\u00b0 and +30\u00b0 Celsius",
    },
    max: {
      value: 30,
      message: "Must be between -40\u00b0 and +30\u00b0 Celsius",
    },
    valueAsNumber: true,
    validate: {
      setFreezerDefault: (v) => {
        if (v < 0) {
          setValue("humidity", 0, {
            shouldDirty: true,
          });
        }
        return true;
      },
      validRoomTemp: (value) => {
        const matches = value.toString().match(/^-?\d*\.?\d*$/);
        return matches?.length > 0 || "Must be a number";
      },
    },
  };

  let roomLocation = (
    <div>
      Room location
      <div style={{ float: "right" }}>
        <a
          href="https://www.google.com/maps/d/viewer?mid=1IMmc3s4TommA7Y7eym7Wl4_SoTmatiM&usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          View weather station map
        </a>
      </div>
    </div>
  );

  return (
    <div data-testid="room-details">
      <div className={heatLoadStyles.narrowFormContainer}>
        <div>
          <SearchGroupSelect
            name="locationSelected"
            labelText={roomLocation}
            options={locationGroupedOptions}
            hasErrors={errors.locationSelected?.message}
            errorMessage={errors.locationSelected?.message}
            control={control}
            rules={{ required: "Required" }}
          />

          <FormRow>
            <Input
              {...register("outsideTemperature", outsideTemperatureRules)}
              labelText="Dry bulb temp."
              placeholderText="-40° to +60°"
              innerLabel="°C"
              hasErrors={errors.outsideTemperature}
              errorMessage={errors.outsideTemperature?.message}
              fieldSize="medium"
            />

            <Input
              {...register("wetBulbTemperature", outsideTemperatureRules)}
              labelText="Wet bulb temp."
              placeholderText="-40° to +60°"
              innerLabel="°C"
              hasErrors={errors.wetBulbTemperature}
              errorMessage={errors.wetBulbTemperature?.message}
              fieldSize="medium"
            />
          </FormRow>

          <FormRow>
            <Input
              {...register("outsideHumidity", outsideHumidityRules)}
              labelText="Outside humidity"
              placeholderText=""
              innerLabel="%"
              disabled={true}
              hasErrors={errors.outsideHumidity}
              errorMessage={errors.outsideHumidity?.message}
              fieldSize="medium"
            />

            <Input
              {...register("groundTemperature")}
              labelText="Ground temp."
              placeholderText=""
              innerLabel="°C"
              disabled={true}
              hasErrors={errors.groundTemperature}
              errorMessage={errors.groundTemperature?.message}
              fieldSize="medium"
            />
          </FormRow>

          <FormRow>
            <Input
              {...register("temperature", temperatureRules)}
              labelText="Design room temp."
              placeholderText="-40° to +30°"
              innerLabel="°C"
              hasErrors={errors.temperature}
              errorMessage={errors.temperature?.message}
              hasInitialPrompt={roomType === "" && !dirtyFields?.temperature}
              fieldSize="medium"
            />

            <Input
              {...register("humidity", roomHumidityRules)}
              labelText="Design room humidity"
              placeholderText="0% to 100%"
              innerLabel="%"
              hasErrors={errors.humidity}
              errorMessage={errors.humidity?.message}
              hasInitialPrompt={roomType === "" && !dirtyFields?.humidity}
              fieldSize="medium"
            />
          </FormRow>

          <FormRow>
            <Input
              {...register("length", roomLengthRules)}
              labelText="Length"
              placeholderText="100 to 150000"
              innerLabel="mm"
              hasErrors={errors.length}
              errorMessage={errors.length?.message}
              hasInitialPrompt={!dirtyFields?.length}
              fieldSize="medium"
            />

            <Input
              {...register("width", roomLengthRules)}
              labelText="Width"
              placeholderText="100 to 150000"
              innerLabel="mm"
              hasErrors={errors.width}
              errorMessage={errors.width?.message}
              hasInitialPrompt={!dirtyFields?.width}
              fieldSize="medium"
            />
          </FormRow>

          <FormRow>
            <Input
              {...register("height", roomLengthRules)}
              labelText="Height"
              placeholderText="100 to 150000"
              innerLabel="mm"
              hasErrors={errors.height}
              errorMessage={errors.height?.message}
              hasInitialPrompt={!dirtyFields?.height}
              fieldSize="medium"
            />

            {!advancedCalc && (
              <Select
                {...register(`roomUsage` as const, { required: "Required" })}
                labelText="Room Usage"
                options={roomUsageOptions}
                hasErrors={errors.roomUsage}
                errorMessage={errors.roomUsage?.message}
                fieldSize="medium"
                hasInitialPrompt={!dirtyFields?.roomUsage}
              />
            )}
          </FormRow>
          <div className={styles.buttonContainer}>
            {!initialHeatLoadClicked && (
              <Button
                isDisabled={!initialHeatLoadButtonEnabled}
                onClickHandler={() => {
                  setInitialHeatLoadClicked(true);
                  initialHeatLoadHandler();
                }}
              >
                Get initial heat load
              </Button>
            )}
          </div>
          <FormRow></FormRow>
        </div>
      </div>
    </div>
  );
};

export default RoomDetails;
