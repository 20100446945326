class AirCondition {
  private readonly _roomTemperature: number;
  private readonly _roomHumidity: number;

  constructor(roomTemperature: number, roomHumidity: number) {
    this._roomTemperature = roomTemperature;
    this._roomHumidity = roomHumidity;
  }

  get roomTemperature() {
    return this._roomTemperature;
  }

  get roomHumidity() {
    return this._roomHumidity;
  }
}

export default AirCondition;
