import amplitude from "amplitude-js";
import { setAndGetMonitor, uniqUserReference } from "./applicationSession";

declare const window: any;
const API_KEY = window.__runtime_configuration.AMPLITUDE_API_KEY;

const registerAmplitudeAPI = () => {
  if (!isMonitoring()) {
    amplitude.getInstance().init(API_KEY);

    amplitude.getInstance().setUserId(uniqUserReference());
  }
};

const isMonitoring = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let monitor = urlParams.get("monitor");

  return setAndGetMonitor(monitor);
};

const logPageVisit = () => {
  if (!isMonitoring())
    amplitude
      .getInstance()
      .logEvent("Page load", { url: window.location.href });
};

const logPageEvent = (eventName: string) => {
  if (!isMonitoring()) amplitude.getInstance().logEvent(eventName);
};

const logPageEventWithData = (eventName: string, eventData: any) => {
  if (!isMonitoring()) amplitude.getInstance().logEvent(eventName, eventData);
};

export {
  registerAmplitudeAPI,
  logPageVisit,
  logPageEvent,
  logPageEventWithData,
};
