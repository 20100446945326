import types from "./types";
import { CapitalEquipmentFilterModel } from "../../types/CapitalEquipmentFilterModel";
import { CondenserSearchRequest } from "../../types/CondenserSearchRequest";
import { EvaporatorSearchRequest } from "../../types/EvaporatorSearchRequest";
import { getInitialFilterStateFromProductFilters } from "../../helpers/addAnyOptionToFilters";

const INITIAL_STATE: any = {
  filter: {
    refrigerant: "R404A",
    load: 0,
    runtime: 16,
    ambient: 35,
    sst: -4,
    td: 6,
    roomTemp: 2,
    noOfCondensingUnits: 1,
    noOfEvaporatorUnits: 1,
    mid_or_dew: "mid",
    standardsRating: "EN12900",
    compressorInletSuperheat: 15,
    liquidSubcooling: 2,
  },
  condensingUnitSearchFilter: {
    refrigerant: "R404A",
    capacity: 0,
    ambient: 35,
    sst: -4,
    mid_or_dew: "mid",
    standardsRating: "EN12900",
    compressorInletSuperheat: 15,
    liquidSubcooling: 2,
  },
  loadingBalancePerformance: false,
  selectedEvaporator: {},
  selectedCondensingUnit: {},
  selectedSingleCondensingUnit: {},
  selectedSingleEvaporator: {},
  evaporatorSearchRequest: { desiredEvaporatorCapacity: 0 },
  evaporatorSingleSearchRequest: {},
  condensingUnitSearchRequest: {},
  condensingUnitFilters: {},
  condensingUnitFilterSelections: {},
  evaporatorFilters: {},
  evaporatorFilterSelections: {},
  equipmentRefrigerantOptions: {},
  pipeSizeRefrigerantOptions: {},
  condensingUnitSearchStatus: "NOT_FOUND",
  evaporatorSearchStatus: "NOT_FOUND",
  singleCondensingUnitSearchStatus: "NOT_FOUND",
  singleEvaporatorSearchStatus: "NOT_FOUND",
  roomTempBelowZero: false,
};

const productReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case types.SEARCH_CONDENSING_UNITS: {
      let f: CapitalEquipmentFilterModel = action.filter;
      let cUnit: CondenserSearchRequest = {
        refrigerant: f.refrigerant,
        load: f.load,
        ambientTemperature: f.ambient,
        roomTemperature: f.roomTemp,
        temperatureDifference: f.td,
        noOfCondensingUnits: f.noOfCondensingUnits,
        mid_or_dew: f.mid_or_dew,
        standardsRating: f.standardsRating,
        compressorInletSuperheat: f.compressorInletSuperheat,
        liquidSubcooling: f.liquidSubcooling,
      };

      return {
        ...state,
        filter: action.filter,
        condensingUnitSearchRequest: cUnit,
      };
    }
    case types.SEARCH_SINGLE_CONDENSING_UNIT: {
      return {
        ...state,
        condensingUnitSearchFilter: action.filter,
      };
    }
    case types.SEARCH_EVAPORATORS: {
      let evaporatorSearchRequest: EvaporatorSearchRequest = {
        refrigerant: state.filter.refrigerant,
        td: state.filter.td,
        roomTemp: state.filter.roomTemp,
        ambientTemp: state.filter.ambient,
        noOfCondensingUnits: state.filter.noOfCondensingUnits,
        noOfEvaporatorUnits: state.filter.noOfEvaporatorUnits,
        standardsRating: state.filter.standardsRating,
        mid_or_dew: state.filter.mid_or_dew,
        compressorInletSuperheat: state.filter.compressorInletSuperheat,
        liquidSubcooling: state.filter.liquidSubcooling,
        desiredEvaporatorCapacity: action.selectedCondensingUnit.capacity,
        startingEvaporatorTemp:
          action.selectedCondensingUnit.evaporator_temperature,
        startingCondensingTemp:
          action.selectedCondensingUnit.condenser_temperature,
        condensingUnitCode: action.selectedCondensingUnit.product_code,
      };
      return {
        ...state,
        evaporatorSearchRequest: evaporatorSearchRequest,
      };
    }
    case types.SEARCH_SINGLE_EVAPORATOR: {
      let evaporatorSearchRequest: EvaporatorSearchRequest = {
        refrigerant: action.filter.refrigerant,
        td: action.filter.tempDifference,
        roomTemp: action.filter.roomTemp,
        mid_or_dew: action.filter.mid_or_dew,
        desiredEvaporatorCapacity: action.filter.capacity,
        noOfEvaporatorUnits: 1,
      };
      return {
        ...state,
        evaporatorSingleSearchRequest: evaporatorSearchRequest,
      };
    }
    case types.SELECT_EVAPORATOR: {
      return {
        ...state,
        selectedEvaporator: action.selectedEvaporator,
      };
    }
    case types.SELECT_CONDENSING_UNIT: {
      return {
        ...state,
        selectedCondensingUnit: action.selectedCondensingUnit,
      };
    }
    case types.SELECT_SINGLE_EVAPORATOR: {
      return {
        ...state,
        selectedSingleEvaporator: action.selectedEvaporator,
      };
    }
    case types.SELECT_SINGLE_CONDENSING_UNIT: {
      return {
        ...state,
        selectedSingleCondensingUnit: action.selectedCondensingUnit,
      };
    }
    case types.CLEAR_SELECTED_PRODUCTS: {
      return {
        ...state,
        selectedCondensingUnit: {},
        selectedEvaporator: {},
      };
    }
    case types.LOADING_BALANCE_PERFORMANCE: {
      return {
        ...state,
        loadingBalancePerformance: action.loadingBalancePerformance,
      };
    }
    case types.DISPLAY_CONDENSING_UNIT_FILTERS: {
      return {
        ...state,
        condensingUnitFilters: action.condensingUnitFilters,
      };
    }
    case types.SELECT_CONDENSING_UNIT_FILTER: {
      let condensingUnitFilterSelections = {
        ...state.condensingUnitFilterSelections,
        [action.filterType]: action.filterValue,
      };

      return {
        ...state,
        condensingUnitFilterSelections: condensingUnitFilterSelections,
      };
    }
    case types.DEFAULT_CONDENSING_UNIT_FILTERS_SELECTIONS: {
      return {
        ...state,
        condensingUnitFilterSelections: action.filterSelections,
      };
    }
    case types.DISPLAY_EVAPORATOR_FILTERS: {
      return {
        ...state,
        evaporatorFilters: action.evaporatorFilters,
      };
    }
    case types.SELECT_EVAPORATOR_FILTER: {
      let evaporatorFilterSelections = {
        ...state.evaporatorFilterSelections,
        [action.filterType]: action.filterValue,
      };

      return {
        ...state,
        evaporatorFilterSelections: evaporatorFilterSelections,
      };
    }
    case types.RESET_CONDENSING_UNIT_FILTERS: {
      let defaultFilterSelection = getInitialFilterStateFromProductFilters(
        state.condensingUnitFilters
      );
      return {
        ...state,
        condensingUnitFilterSelections: defaultFilterSelection,
      };
    }
    case types.RESET_EVAPORATOR_UNIT_FILTERS: {
      let defaultFilterSelection = getInitialFilterStateFromProductFilters(
        state.evaporatorFilters
      );
      return {
        ...state,
        evaporatorFilterSelections: defaultFilterSelection,
      };
    }
    case types.DEFAULT_EVAPORATOR_FILTERS_SELECTIONS: {
      return {
        ...state,
        evaporatorFilterSelections: action.filterSelections,
      };
    }
    case types.DISPLAY_PIPE_SIZE_REFRIGERANT_OPTIONS: {
      return {
        ...state,
        pipeSizeRefrigerantOptions: action.refrigerantOptions,
      };
    }
    case types.DISPLAY_EQUIPMENT_REFRIGERANT_OPTIONS: {
      return {
        ...state,
        equipmentRefrigerantOptions: action.refrigerantOptions,
      };
    }
    case types.SET_CONDENSING_UNIT_SEARCH_STATUS: {
      return {
        ...state,
        condensingUnitSearchStatus: action.searchStatus,
      };
    }
    case types.SET_EVAPORATOR_SEARCH_STATUS: {
      return {
        ...state,
        evaporatorSearchStatus: action.searchStatus,
      };
    }
    case types.SET_SINGLE_CONDENSING_UNIT_SEARCH_STATUS: {
      return {
        ...state,
        singleCondensingUnitSearchStatus: action.searchStatus,
      };
    }
    case types.SET_SINGLE_EVAPORATOR_SEARCH_STATUS: {
      return {
        ...state,
        singleEvaporatorSearchStatus: action.searchStatus,
      };
    }
    case types.CLEAR_CONDENSING_UNIT_SEARCH: {
      return {
        ...state,
        condensingUnitSearchRequest: {},
        selectedSingleCondensingUnit: {},
        condensingUnitSearchStatus: "NOT_FOUND",
        singleCondensingUnitSearchStatus: "NOT_FOUND",
      };
    }
    case types.CLEAR_EVAPORATOR_SEARCH: {
      return {
        ...state,
        evaporatorSearchRequest: { desiredEvaporatorCapacity: 0 },
        evaporatorSingleSearchRequest: {},
        selectedSingleEvaporator: {},
        evaporatorSearchStatus: "NOT_FOUND",
        singleEvaporatorSearchStatus: "NOT_FOUND",
      };
    }
    case types.ROOM_TEMP_BELOW_ZERO: {
      const { roomTempBelowZero } = action;
      return {
        ...state,
        roomTempBelowZero,
      };
    }

    default:
      return state;
  }
};

export default productReducer;
