import { FieldError, RegisterOptions } from "react-hook-form";

interface PeopleDetailsErrors {
  numberOfPeople?: FieldError | undefined;
  activity?: FieldError | undefined;
  numberOfHours?: FieldError | undefined;
  ventilation?: FieldError | undefined;
}

interface PeopleDetailsDirtyFields {
  numberOfPeople?: boolean | undefined;
  activity?: boolean | undefined;
  numberOfHours?: boolean | undefined;
  ventilation?: boolean | undefined;
}

const numberOfHoursRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Must be between 0 and 24",
  },
  max: {
    value: 24,
    message: "Must be between 0 and 24",
  },
  valueAsNumber: true,
};

const isErrorPeople = (errors: PeopleDetailsErrors): boolean => {
  return (
    errors.numberOfPeople !== undefined ||
    errors.activity !== undefined ||
    errors.numberOfHours !== undefined
  );
};

const allPeopleFieldsDirty = (
  dirtyFields: PeopleDetailsDirtyFields
): boolean => {
  return (
    dirtyFields.numberOfPeople !== undefined &&
    dirtyFields.activity !== undefined &&
    dirtyFields.numberOfHours !== undefined
  );
};

const validatePeopleDetails = (
  errors: PeopleDetailsErrors,
  dirtyFields: PeopleDetailsDirtyFields
): boolean => {
  const isValid = !isErrorPeople(errors);
  const allDirtyFields = allPeopleFieldsDirty(dirtyFields);

  return isValid && allDirtyFields;
};

const validateVentilationDetails = (
  errors: PeopleDetailsErrors,
  dirtyFields: PeopleDetailsDirtyFields
): boolean => {
  const isValid = errors.ventilation === undefined;
  const allDirtyFields = dirtyFields.ventilation !== undefined;

  return isValid && allDirtyFields;
};

export {
  numberOfHoursRules,
  validateVentilationDetails,
  validatePeopleDetails,
};
