import React, { FC } from "react";
import Input from "../../../components/Input/Input";
import { useFormContext } from "react-hook-form";
import {
  equipmentRuntimeRule,
  safetyFactorRule,
  otherLoadsRule,
} from "./SafetyAndRuntimeDetailsValidation";
import heatLoadStyles from "../../page/HeatLoad.module.css";

const SafetyAndRuntimeDetails: FC<{
  roomTemperature: number;
}> = ({ roomTemperature }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div data-testid="additional-details">
      <div className={heatLoadStyles.narrowFormContainer}>
        <div>
          <Input
            {...register("equipmentRuntime", equipmentRuntimeRule)}
            labelText="Equipment run time per day"
            placeholderText="0 to 24"
            innerLabel="hrs"
            hasErrors={errors.equipmentRuntime}
            errorMessage={errors.equipmentRuntime?.message}
            fieldSize="small"
          />
          <Input
            {...register("safetyFactor", safetyFactorRule)}
            labelText="Safety factor"
            placeholderText="0 to 20"
            innerLabel="%"
            hasErrors={errors.safetyFactor}
            errorMessage={errors.safetyFactor?.message}
            fieldSize="small"
          />
          <Input
            {...register("fanFactor", safetyFactorRule)}
            labelText={`Fan${roomTemperature <= 0 ? " and heater" : ""} load`}
            placeholderText="0 to 20"
            innerLabel="%"
            hasErrors={errors.fanFactor}
            errorMessage={errors.fanFactor?.message}
            fieldSize="small"
          />
          <Input
            {...register("otherLoads", otherLoadsRule)}
            labelText="Other misc. loads"
            placeholderText="0 to 100000"
            innerLabel="W"
            hasErrors={errors.otherLoads}
            errorMessage={errors.otherLoads?.message}
            fieldSize="small"
          />
        </div>
      </div>
    </div>
  );
};

export default SafetyAndRuntimeDetails;
