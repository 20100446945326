import React, { FC } from "react";
import { useSelector } from "react-redux";
import PrintTemplate from "../../../components/PrintTemplate/PrintTemplate";
import { CapitalEquipmentFilterModel } from "../../../types/CapitalEquipmentFilterModel";
import { CondensingUnitProductCapacity } from "../../../types/CondensingUnitProductCapacity";
import { EvaporatorProductCapacity } from "../../../types/EvaporatorProductCapacity";
import CombinedSearchBalancedPerformance from "./CombinedSearchBalancedPerformance";
import CombinedSearchInputs from "./CombinedSearchInputs";
import SelectedCondensingUnitPrint from "./SelectedCondensingUnitPrint";
import SelectedEvaporatorPrint from "./SelectedEvaporatorPrint";
import styles from "./SelectedEquipment.module.css";

const CombinedSearchPrint: FC = () => {
  const equipmentSearch: CapitalEquipmentFilterModel = useSelector(
    (state: any) => {
      return state.product.filter;
    }
  );

  const selectedCondensingUnit: CondensingUnitProductCapacity = useSelector(
    (state: any) => {
      return state.product.selectedCondensingUnit;
    }
  );

  const selectedEvaporator: EvaporatorProductCapacity = useSelector(
    (state: any) => {
      return state.product.selectedEvaporator;
    }
  );

  return (
    <PrintTemplate pageTitle="Equipment selection">
      <div className="page-break">
        <div className="page-header">
          Condensing unit &amp; evaporator combination inputs
        </div>

        <CombinedSearchInputs equipmentSearch={equipmentSearch} />

        <div className="page-header">
          Condensing unit &amp; evaporator combination selection
        </div>

        <CombinedSearchBalancedPerformance equipmentSearch={equipmentSearch} />

        <div className={styles.combinedContainer}>
          <SelectedCondensingUnitPrint
            selectedCondensingUnit={selectedCondensingUnit}
            numberOfCondensingUnits={equipmentSearch.noOfCondensingUnits}
            refrigerant={equipmentSearch.refrigerant}
          />

          <SelectedEvaporatorPrint
            selectedEvaporator={selectedEvaporator}
            selectedRefrigerant={equipmentSearch.refrigerant}
            numberOfEvaporators={
              equipmentSearch.noOfCondensingUnits *
              equipmentSearch.noOfEvaporatorUnits
            }
          />
        </div>
      </div>
    </PrintTemplate>
  );
};

export default CombinedSearchPrint;
