import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import AppRouter from "./AppRouter";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
//import swConfig from "./swConfig";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

declare const window: any;
const middleware = [applyMiddleware(thunk)];

if (window.__runtime_configuration.SENTRY_ENABLED) {
  Sentry.init({
    dsn: window.__runtime_configuration.SENTRY_ENDPOINT,
    integrations: [new Integrations.BrowserTracing()],
    environment: window.__runtime_configuration.ENVIRONMENT.toLowerCase(),

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

  middleware.push(Sentry.createReduxEnhancer());
}

const store = createStore(rootReducer, composeWithDevTools(...middleware));

ReactDOM.render(
  <Provider store={store}>
    <AppRouter />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register(swConfig);
serviceWorker.unregister();
