import React, { FC } from "react";
import { CondensingUnitProductCapacity } from "../../../types/CondensingUnitProductCapacity";
import ProductInfoPrint from "./ProductInfoPrint";
import styles from "./SelectedEquipment.module.css";

const SelectedCondensingUnitPrint: FC<{
  selectedCondensingUnit: CondensingUnitProductCapacity;
  refrigerant: string;
  numberOfCondensingUnits: number;
}> = ({ selectedCondensingUnit, refrigerant, numberOfCondensingUnits }) => {
  return (
    <div className={styles.equipmentContainer}>
      <div className={styles.title}>Condensing unit(s)</div>

      <ProductInfoPrint
        refrigerant={refrigerant}
        numberRequired={numberOfCondensingUnits}
        thumbnail={selectedCondensingUnit.thumbnail}
        model={selectedCondensingUnit.model}
        product_code={selectedCondensingUnit.product_code}
        series={selectedCondensingUnit.series}
      />
    </div>
  );
};

export default SelectedCondensingUnitPrint;
