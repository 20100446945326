import React from "react";
import { CondensingUnitProductCapacity } from "../../../types/CondensingUnitProductCapacity";
import styles from "../SelectedEquipment/SelectedEquipment.module.css";
import SelectedProductPlaceholder from "../../../components/SelectedProductPlaceholder/SelectedProductPlaceholder";
import CondensingUnitCard from "../CondensingUnitCard/CondensingUnitCard";

type Props = Ready | Loading;

interface Ready {
  condensingUnit: CondensingUnitProductCapacity;
  refrigerant: string;
  loading?: false;
}

interface Loading {
  condensingUnit?: CondensingUnitProductCapacity;
  refrigerant?: string;
  loading?: true;
}

const SelectedCondensingUnit: React.FC<Props> = ({
  condensingUnit,
  refrigerant,
  loading,
}) => {
  return (
    <section className={styles.container}>
      <div className="section-header">Selected condensing unit</div>
      {loading ? (
        <SelectedProductPlaceholder
          heading="No condensing unit selected"
          subheading="Add details in search to select condensing unit"
        />
      ) : (
        <CondensingUnitCard
          condensingUnit={condensingUnit!}
          refrigerant={refrigerant!}
        />
      )}
    </section>
  );
};

export default SelectedCondensingUnit;
