import types from "./types";

const INITIAL_STATE: any = {
  pipeSizeCalculationRequest: {
    refrigerant: "R404A",
    pipeLine: "liquid",
    mid_or_dew: "mid",
    operatingCapacity: 0,
    saturatedSuctionTemp: -4,
    saturatedCondensingTemp: 38,
    compressorInletTemp: 15,
    liquidSubcooling: 2,
    locationOfCondensingUnit: "same",
    levelOfDetail: "equivalent",
    horizontalLiquid: 0,
    verticalLiquid: 0,
    horizontalSuction: 0,
    verticalSuction: 0,
    horizontalDischarge: 0,
    verticalDischarge: 0,
  },
  selectedLiquidLinePipes: {
    horizontal: {},
    vertical: {},
  },
  selectedSuctionLinePipes: {
    horizontal: {},
    vertical: {},
  },
  selectedDischargeLinePipes: {
    horizontal: {},
    vertical: {},
  },
};

const pipeReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case types.PIPE_SIZE_CALCULATION: {
      return {
        ...state,
        pipeSizeCalculationRequest: action.pipeSizeCalculationRequest,
      };
    }
    case types.SELECT_LIQUID_LINE_PIPE_HORIZONTAL: {
      return {
        ...state,
        selectedLiquidLinePipes: {
          ...state.selectedLiquidLinePipes,
          horizontal: action.selectedLiquidLinePipe,
        },
      };
    }
    case types.SELECT_LIQUID_LINE_PIPE_VERTICAL: {
      return {
        ...state,
        selectedLiquidLinePipes: {
          ...state.selectedLiquidLinePipes,
          vertical: action.selectedLiquidLinePipe,
        },
      };
    }
    case types.SELECT_DISCHARGE_LINE_PIPE_HORIZONTAL: {
      return {
        ...state,
        selectedDischargeLinePipes: {
          ...state.selectedDischargeLinePipes,
          horizontal: action.selectedDischargeLinePipe,
        },
      };
    }
    case types.SELECT_DISCHARGE_LINE_PIPE_VERTICAL: {
      return {
        ...state,
        selectedDischargeLinePipes: {
          ...state.selectedDischargeLinePipes,
          vertical: action.selectedDischargeLinePipe,
        },
      };
    }
    case types.SELECT_SUCTION_LINE_PIPE_HORIZONTAL: {
      return {
        ...state,
        selectedSuctionLinePipes: {
          ...state.selectedSuctionLinePipes,
          horizontal: action.selectedSuctionLinePipe,
        },
      };
    }
    case types.SELECT_SUCTION_LINE_PIPE_VERTICAL: {
      return {
        ...state,
        selectedSuctionLinePipes: {
          ...state.selectedSuctionLinePipes,
          vertical: action.selectedSuctionLinePipe,
        },
      };
    }
    case types.CLEAR_ALL_PIPE_SELECTIONS: {
      return {
        ...state,
        selectedLiquidLinePipes: {
          horizontal: {},
          vertical: {},
        },
        selectedSuctionLinePipes: {
          horizontal: {},
          vertical: {},
        },
        selectedDischargeLinePipes: {
          horizontal: {},
          vertical: {},
        },
      };
    }
    default:
      return state;
  }
};

export default pipeReducer;
