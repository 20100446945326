import React, { FC, useEffect, useState } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import Input from "../../components/Input/Input";
import RadioGroup, { RadioValue } from "../../components/RadioGroup/RadioGroup";
import {
  horizontalLengthRules,
  verticalLengthRules,
} from "../page/PipeSizeCalculationValidation";
import styles from "./LineLengthDetails.module.css";

const LineLengthDetails: FC = () => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();
  const watchLocationOfCondensingUnit = watch("locationOfCondensingUnit");
  const watchLevelOfDetail = watch("levelOfDetail");

  const locationOfCondensingUnitRadioList: RadioValue[] = [
    { label: "Same level", value: "same" },
    { label: "Above", value: "above" },
    { label: "Below", value: "below" },
  ];

  const levelOfDetailRadioList: RadioValue[] = [
    { label: "Equivalent", value: "equivalent" },
    { label: "Separate", value: "separate", disabled: true },
  ];

  const [levelOfDetailRadio, setLevelOfDetailList] = useState<RadioValue[]>(
    levelOfDetailRadioList
  );

  useEffect(() => {
    setLevelOfDetailList([
      { label: "Equivalent", value: "equivalent" },
      {
        label: "Separate",
        value: "separate",
        disabled: watchLocationOfCondensingUnit === "same",
      },
    ]);

    if (watchLocationOfCondensingUnit === "same") {
      setValue("levelOfDetail", "equivalent");
    }
  }, [watchLocationOfCondensingUnit, setValue]);

  const horizontalLiquidLengthRules: RegisterOptions = {
    required: "Required",
    pattern: {
      value: /^\d*\.?\d*$/,
      message: "Must be a number",
    },
    min: {
      value: 0,
      message: "Must be between 0 and 150 metres",
    },
    max: {
      value: 150,
      message: "Must be between 0 and 150 metres",
    },
    validate: {
      atLeastOneLengthEntered: (value) => {
        const lengthValues = getValues();
        let horizontalLengthTotal =
          (value ? +value : 0) +
          (lengthValues?.horizontalDischarge
            ? +lengthValues?.horizontalDischarge
            : 0) +
          (lengthValues?.horizontalSuction
            ? +lengthValues?.horizontalSuction
            : 0);

        let verticalLengthTotal =
          (lengthValues?.verticalDischarge
            ? +lengthValues?.verticalDischarge
            : 0) +
          (lengthValues?.verticalSuction ? +lengthValues?.verticalSuction : 0) +
          (lengthValues?.verticalLiquid ? +lengthValues?.verticalLiquid : 0);

        let totalLength =
          watchLevelOfDetail === "equivalent"
            ? horizontalLengthTotal
            : horizontalLengthTotal + verticalLengthTotal;

        return (
          totalLength > 0 ||
          `Must have at least one length field greater than 0`
        );
      },
    },
  };

  let horizontalLiquidLabel =
    watchLevelOfDetail === "equivalent" ? "Liquid" : "Horizontal";
  let horizontalSuctionLabel =
    watchLevelOfDetail === "equivalent" ? "Suction" : "Horizontal";
  let horizontalDischargeLabel =
    watchLevelOfDetail === "equivalent" ? "Discharge" : "Horizontal";
  let verticalLiquidLabel = `Vertical ${
    watchLevelOfDetail === "separate" &&
    watchLocationOfCondensingUnit === "above"
      ? "drop"
      : "lift"
  }`;
  let verticalSuctionLabel = `Vertical ${
    watchLevelOfDetail === "separate" &&
    watchLocationOfCondensingUnit === "above"
      ? "lift"
      : "drop"
  }`;
  let verticalDischargeLabel = "Vertical lift";

  return (
    <>
      <RadioGroup
        {...register("locationOfCondensingUnit", { required: "Required" })}
        labelText="Location of condensing unit from evaporator"
        values={locationOfCondensingUnitRadioList}
        hasErrors={errors.locationOfCondensingUnit}
        errorMessage={errors.locationOfCondensingUnit?.message}
      />

      <RadioGroup
        {...register("levelOfDetail", { required: "Required" })}
        labelText="Level of detail"
        values={levelOfDetailRadio}
        hasErrors={errors.levelOfDetail}
        errorMessage={errors.levelOfDetail?.message}
      />

      {watchLevelOfDetail === "equivalent" &&
        (watchLocationOfCondensingUnit === "above" ||
          watchLocationOfCondensingUnit === "below") && (
          <div className={styles.alertMessageContainer}>
            <AlertMessage
              title="Pipe size accuracy will be impacted"
              description={`Make sure to provide separate horizontal and vertical line lengths if the unit is ${watchLocationOfCondensingUnit} the evaporator`}
              alertType="warning"
            />
          </div>
        )}

      {watchLevelOfDetail !== "equivalent" && (
        <div className={styles.subHeadingLineLengths}>Liquid line</div>
      )}

      <div
        className={
          watchLevelOfDetail !== "equivalent"
            ? styles.liquidFieldsContainer
            : ""
        }
      >
        <Input
          {...register("horizontalLiquid", horizontalLiquidLengthRules)}
          labelText={horizontalLiquidLabel}
          placeholderText="0 to 150"
          innerLabel="m"
          hasErrors={errors.horizontalLiquid}
          errorMessage={errors.horizontalLiquid?.message}
        />

        {watchLevelOfDetail !== "equivalent" && (
          <Input
            {...register("verticalLiquid", verticalLengthRules)}
            labelText={verticalLiquidLabel}
            placeholderText="0 to 20"
            innerLabel="m"
            hasErrors={errors.verticalLiquid}
            errorMessage={errors.verticalLiquid?.message}
          />
        )}
      </div>

      {watchLevelOfDetail !== "equivalent" && (
        <div className={styles.subHeadingLineLengths}>Suction line</div>
      )}

      <div
        className={
          watchLevelOfDetail !== "equivalent"
            ? styles.liquidFieldsContainer
            : ""
        }
      >
        <Input
          {...register("horizontalSuction", horizontalLengthRules)}
          labelText={horizontalSuctionLabel}
          placeholderText="0 to 150"
          innerLabel="m"
          hasErrors={errors.horizontalSuction}
          errorMessage={errors.horizontalSuction?.message}
        />

        {watchLevelOfDetail !== "equivalent" && (
          <Input
            {...register("verticalSuction", verticalLengthRules)}
            labelText={verticalSuctionLabel}
            placeholderText="0 to 20"
            innerLabel="m"
            hasErrors={errors.verticalSuction}
            errorMessage={errors.verticalSuction?.message}
          />
        )}
      </div>

      {watchLevelOfDetail !== "equivalent" && (
        <div className={styles.subHeadingLineLengths}>Discharge line</div>
      )}

      <div
        className={
          watchLevelOfDetail !== "equivalent"
            ? styles.liquidFieldsContainer
            : ""
        }
      >
        <Input
          {...register("horizontalDischarge", horizontalLengthRules)}
          labelText={horizontalDischargeLabel}
          placeholderText="0 to 150"
          innerLabel="m"
          hasErrors={errors.horizontalDischarge}
          errorMessage={errors.horizontalDischarge?.message}
        />

        {watchLevelOfDetail !== "equivalent" && (
          <Input
            {...register("verticalDischarge", verticalLengthRules)}
            labelText={verticalDischargeLabel}
            placeholderText="0 to 20"
            innerLabel="m"
            hasErrors={errors.verticalDischarge}
            errorMessage={errors.verticalDischarge?.message}
          />
        )}
      </div>
    </>
  );
};

export default LineLengthDetails;
