import { ProductCapacity } from "../../types/ProductCapacity";
import { ThumbnailsResponse } from "../../types/ThumbnailsResponse";
import getUrl from "../../helpers/api";
import { enrichProductsWithThumbnails } from "../../helpers/enrichProductsWithThumbnails";
import { enrichProductsWithThumbnailValue } from "../../helpers/enrichProductsWithThumbnailValue";
import { Service, ServiceLoaded } from "../../types/Service";
import { useEffect, useState } from "react";
import { FilterValues } from "../../types/FilterValues";
import { SearchStatus } from "../../types/SearchStatus";

declare const window: any;
const ENDPOINT_THUMBNAILS = `${window.__runtime_configuration.ENDPOINT_THUMBNAILS}/call/capital-equipment-details`;

const useProductWithThumbnails = <T extends ProductCapacity>(
  productService: Service<{
    results: T[];
    best_match: T;
    filterSelections: FilterValues;
    search_status: SearchStatus;
  }>
): {
  productsWithThumbnails: Service<{
    results: T[];
    best_match: T;
    filterSelections: FilterValues;
    search_status: SearchStatus;
  }>;
} => {
  const [result, setResult] = useState<
    Service<{
      results: T[];
      best_match: T;
      filterSelections: FilterValues;
      search_status: SearchStatus;
    }>
  >({
    status: "init",
  });

  useEffect(() => {
    if (productService.status === "loaded") {
      setResult({ status: "loaded", payload: productService.payload });
      if (productService.payload.results.length > 0)
        getThumbnails<T>(productService, setResult);
    } else if (productService.status === "error") {
      setResult({ status: "error", error: productService.error });
    } else {
      setResult({ status: productService.status });
    }
  }, [productService]);

  return { productsWithThumbnails: result };
};

function getThumbnails<T extends ProductCapacity>(
  productService: ServiceLoaded<{
    results: T[];
    best_match: T;
    filterSelections: FilterValues;
    search_status: SearchStatus;
  }>,
  setResult: any
) {
  let products = productService.payload.results;
  let url = createThumbnailUrlWithSearchParametersFromProducts<T>(products);
  let enrichedProducts: T[];

  getUrl<ThumbnailsResponse[]>(url.href)
    .then((response) => {
      enrichedProducts = enrichProductsWithThumbnails<T>(products, response);
      setResultWithProducts<T>(
        setResult,
        enrichedProducts,
        productService.payload.best_match,
        productService.payload.filterSelections,
        productService.payload.search_status
      );
    })
    .catch(() => {
      enrichedProducts = enrichProductsWithThumbnailValue<T>(products, null);
      setResultWithProducts<T>(
        setResult,
        enrichedProducts,
        productService.payload.best_match,
        productService.payload.filterSelections,
        productService.payload.search_status
      );
    });
}

function setResultWithProducts<T extends ProductCapacity>(
  setResult: any,
  results: T[],
  bestMatch: T,
  filterSelections: FilterValues,
  search_status: SearchStatus
) {
  setResult({
    status: "loaded",
    payload: {
      results: results,
      best_match: bestMatch,
      filterSelections: filterSelections,
      search_status: search_status,
    },
  });
}

function createThumbnailUrlWithSearchParametersFromProducts<
  T extends ProductCapacity
>(products: T[]) {
  let url = new URL(ENDPOINT_THUMBNAILS);
  products.forEach((product) => {
    if (product.product_code)
      url.searchParams.append("product_code", product.product_code.toString());
  });
  return url;
}

export default useProductWithThumbnails;
