import React, { FC, useEffect } from "react";
import CeilingDetails from "./CeilingDetails/CeilingDetails";
import FloorDetails from "./FloorDetails/FloorDetails";
import WallDetails from "./WallDetails/WallDetails";
import { RoomDimensions } from "../../types/Room";
import { useFormContext } from "react-hook-form";
import { ProductFilter } from "../../../types/ProductFilter";
import { StandardRoom } from "../../../types/HeatLoad";

const TransmissionDetails: FC<{
  roomDimensions: RoomDimensions;
  wallInsulationFilter: ProductFilter;
  ceilingInsulationFilter: ProductFilter;
  floorMaterialFilter: ProductFilter;
  floorInsulationFilter: ProductFilter;
  floorHeatingFilter: ProductFilter;
  formEnabled: boolean;
  roomDetails: StandardRoom;
  switchWallsViewHandler: (wallsAreSame: string) => void;
}> = ({
  roomDimensions,
  wallInsulationFilter,
  ceilingInsulationFilter,
  floorMaterialFilter,
  floorInsulationFilter,
  floorHeatingFilter,
  formEnabled,
  roomDetails,
  switchWallsViewHandler,
}) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (formEnabled) {
      setValue("walls[0].wallId", roomDimensions.walls[0].id);
    }
  }, [roomDimensions.walls, setValue, formEnabled]);

  return (
    <div data-testid="transmission-details">
      {formEnabled && (
        <>
          <WallDetails
            wallInsulationFilter={wallInsulationFilter}
            roomDimensions={roomDimensions}
            switchWallsViewHandler={switchWallsViewHandler}
          />

          <CeilingDetails ceilingInsulationFilter={ceilingInsulationFilter} />

          <FloorDetails
            floorMaterialFilter={floorMaterialFilter}
            floorInsulationFilter={floorInsulationFilter}
            floorHeatingFilter={floorHeatingFilter}
            roomDetails={roomDetails}
          />
        </>
      )}
    </div>
  );
};

export default TransmissionDetails;
