import React, { FC, Fragment } from "react";
import { HeatLoadCapacity } from "../../../calculations/heatload/load/TotalHeatLoad";
import InfoText from "../../../components/InfoText/InfoText";
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  Row,
  TableNoBorders,
} from "../../../components/Table/Table";
import { HeatLoadTotalCapacity } from "../../types/HeatLoadCapacity";
import styles from "./HeatLoadLineDetails.module.css";

const DisplayHeatLoadType: FC<{
  heatLoadValues: HeatLoadCapacity[];
  displayClassName: string;
  subtotalLoad: number;
}> = ({ heatLoadValues, displayClassName, subtotalLoad }) => {
  const canDisplaySection =
    heatLoadValues.length > 0 &&
    heatLoadValues.filter((hl) => hl.latent === 0 && hl.sensible === 0).length <
      heatLoadValues.length;

  return (
    <>
      {canDisplaySection && (
        <div className={styles.tableContainer}>
          <TableNoBorders additionalClass={displayClassName}>
            <Header>
              <Row>
                <HeaderCell width={20}>
                  {heatLoadValues[0].groupName}
                </HeaderCell>
                <HeaderCell width={20} textAlign="center">
                  Latent (W)
                </HeaderCell>
                <HeaderCell width={20} textAlign="center">
                  Sensible (W)
                </HeaderCell>
                <HeaderCell width={20} textAlign="center">
                  Total (W)
                </HeaderCell>
                <HeaderCell width={20} textAlign="center">
                  %
                </HeaderCell>
              </Row>
            </Header>
            <Body>
              {heatLoadValues.map((v) => {
                let lineTotal = v.latent + v.sensible;
                let percent = (lineTotal / subtotalLoad) * 100;

                return (
                  <Fragment key={v.heatLoadType + v.reference}>
                    {(v.latent !== 0 || v.sensible !== 0) && (
                      <>
                        <Row>
                          <Cell
                            dataTestId={
                              v.heatLoadType + "-" + v.reference + "-name"
                            }
                          >
                            {v.name}
                          </Cell>
                          <Cell
                            textAlign="center"
                            dataTestId={
                              v.heatLoadType + "-" + v.reference + "-latent"
                            }
                          >
                            {v.latent.toFixed(2)}
                          </Cell>
                          <Cell
                            textAlign="center"
                            dataTestId={
                              v.heatLoadType + "-" + v.reference + "-sensible"
                            }
                          >
                            {v.sensible.toFixed(2)}
                          </Cell>
                          <Cell
                            textAlign="center"
                            dataTestId={
                              v.heatLoadType + "-" + v.reference + "-total"
                            }
                          >
                            {lineTotal.toFixed(2)}
                          </Cell>
                          <Cell
                            textAlign="center"
                            dataTestId={
                              v.heatLoadType + "-" + v.reference + "-percent"
                            }
                          >
                            {percent.toFixed(2)}
                          </Cell>
                        </Row>
                      </>
                    )}
                  </Fragment>
                );
              })}
            </Body>
          </TableNoBorders>
        </div>
      )}
    </>
  );
};

const HeatLoadLineDetails: FC<{
  totalHeatLoad: HeatLoadCapacity[];
  heatLoadCapacity: HeatLoadTotalCapacity;
}> = ({ totalHeatLoad, heatLoadCapacity }) => {
  let totalLoad =
    heatLoadCapacity.totalLoad.sensible +
    heatLoadCapacity.totalLoad.latent +
    heatLoadCapacity.otherLoad.total;

  return (
    <>
      {totalLoad > 0 && totalHeatLoad.length > 0 && (
        <>
          <DisplayHeatLoadType
            heatLoadValues={totalHeatLoad.filter(
              (v) => v.groupName === "Transmission"
            )}
            displayClassName="heatLoadDetailsTransmissionBorder"
            subtotalLoad={totalLoad}
          />
          <DisplayHeatLoadType
            heatLoadValues={totalHeatLoad.filter(
              (v) => v.groupName === "Infiltration"
            )}
            displayClassName="heatLoadDetailsInfiltrationBorder"
            subtotalLoad={totalLoad}
          />
          <DisplayHeatLoadType
            heatLoadValues={totalHeatLoad.filter(
              (v) => v.groupName === "Product loads"
            )}
            displayClassName="heatLoadDetailsProductBorder"
            subtotalLoad={totalLoad}
          />
          <DisplayHeatLoadType
            heatLoadValues={totalHeatLoad.filter(
              (v) => v.groupName === "Additional loads"
            )}
            displayClassName="heatLoadDetailsAdditionalLoadsBorder"
            subtotalLoad={totalLoad}
          />
          {heatLoadCapacity.otherLoad.total > 0 && (
            <DisplayHeatLoadType
              heatLoadValues={[
                {
                  sensible: heatLoadCapacity.otherLoad.total,
                  latent: 0,
                  reference: "other",
                  heatLoadType: "Other",
                  groupName: "Other loads",
                  name: "Other misc. loads",
                },
              ]}
              displayClassName="heatLoadDetailsOtherMiscLoadsBorder"
              subtotalLoad={totalLoad}
            />
          )}
        </>
      )}
      {totalLoad === 0 && (
        <InfoText testid="no-heat-load-details">
          Your heat load details will appear here as you add room details.
        </InfoText>
      )}
    </>
  );
};

export default HeatLoadLineDetails;
