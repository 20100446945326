import {
  SEARCH_CONDENSING_UNITS,
  SEARCH_SINGLE_CONDENSING_UNIT,
  LOADING_BALANCE_PERFORMANCE,
  SELECT_EVAPORATOR,
  SELECT_CONDENSING_UNIT,
  SELECT_SINGLE_EVAPORATOR,
  SELECT_SINGLE_CONDENSING_UNIT,
  CLEAR_SELECTED_PRODUCTS,
  DISPLAY_CONDENSING_UNIT_FILTERS,
  DISPLAY_EVAPORATOR_FILTERS,
  DISPLAY_PIPE_SIZE_REFRIGERANT_OPTIONS,
  DISPLAY_EQUIPMENT_REFRIGERANT_OPTIONS,
  SELECT_CONDENSING_UNIT_FILTER,
  SELECT_EVAPORATOR_FILTER,
  SEARCH_EVAPORATORS,
  SEARCH_SINGLE_EVAPORATOR,
  DEFAULT_CONDENSING_UNIT_FILTERS_SELECTIONS,
  DEFAULT_EVAPORATOR_FILTERS_SELECTIONS,
  RESET_EVAPORATOR_UNIT_FILTERS,
  RESET_CONDENSING_UNIT_FILTERS,
  SET_CONDENSING_UNIT_SEARCH_STATUS,
  SET_EVAPORATOR_SEARCH_STATUS,
  SET_SINGLE_CONDENSING_UNIT_SEARCH_STATUS,
  SET_SINGLE_EVAPORATOR_SEARCH_STATUS,
  CLEAR_CONDENSING_UNIT_SEARCH,
  CLEAR_EVAPORATOR_SEARCH,
  ROOM_TEMP_BELOW_ZERO,
} from "./types";
import { CondensingUnitProductCapacity } from "../../types/CondensingUnitProductCapacity";
import { CapitalEquipmentFilterModel } from "../../types/CapitalEquipmentFilterModel";
import { EvaporatorProductCapacity } from "../../types/EvaporatorProductCapacity";
import { FilterOption, ProductFilter } from "../../types/ProductFilter";
import { FilterValues } from "../../types/FilterValues";
import { SearchStatus } from "../../types/SearchStatus";
import { CondensingUnitFilterModel } from "../../types/CondensingUnitFilterModel";
import { EvaporatorFilterModel } from "../../types/EvaporatorFilterModel";

const searchCondensingUnits = (filter: CapitalEquipmentFilterModel) => {
  return {
    type: SEARCH_CONDENSING_UNITS,
    filter,
  };
};

const searchSingleCondensingUnit = (filter: CondensingUnitFilterModel) => {
  return {
    type: SEARCH_SINGLE_CONDENSING_UNIT,
    filter,
  };
};

const searchEvaporators = (
  selectedCondensingUnit: CondensingUnitProductCapacity
) => {
  return {
    type: SEARCH_EVAPORATORS,
    selectedCondensingUnit,
  };
};

const searchSingleEvaporator = (filter: EvaporatorFilterModel) => {
  return {
    type: SEARCH_SINGLE_EVAPORATOR,
    filter,
  };
};

const loadingBalancePerformance = (loadingBalancePerformance: boolean) => {
  return {
    type: LOADING_BALANCE_PERFORMANCE,
    loadingBalancePerformance,
  };
};

const selectEvaporator = (selectedEvaporator: EvaporatorProductCapacity) => {
  return {
    type: SELECT_EVAPORATOR,
    selectedEvaporator,
  };
};

const selectCondensingUnit = (
  selectedCondensingUnit: CondensingUnitProductCapacity
) => {
  return {
    type: SELECT_CONDENSING_UNIT,
    selectedCondensingUnit,
  };
};

const selectSingleEvaporator = (
  selectedEvaporator: EvaporatorProductCapacity
) => {
  return {
    type: SELECT_SINGLE_EVAPORATOR,
    selectedEvaporator,
  };
};

const selectSingleCondensingUnit = (
  selectedCondensingUnit: CondensingUnitProductCapacity
) => {
  return {
    type: SELECT_SINGLE_CONDENSING_UNIT,
    selectedCondensingUnit,
  };
};

const clearSelectedProducts = () => {
  return {
    type: CLEAR_SELECTED_PRODUCTS,
  };
};

const displayCondensingUnitFilters = (
  condensingUnitFilters: ProductFilter[]
) => {
  return {
    type: DISPLAY_CONDENSING_UNIT_FILTERS,
    condensingUnitFilters,
  };
};

const selectCondensingUnitFilter = (
  filterType: string,
  filterValue: string
) => {
  return {
    type: SELECT_CONDENSING_UNIT_FILTER,
    filterType,
    filterValue,
  };
};

const defaultCondensingUnitFilterSelections = (
  filterSelections: FilterValues
) => {
  return {
    type: DEFAULT_CONDENSING_UNIT_FILTERS_SELECTIONS,
    filterSelections,
  };
};

const displayEvaporatorFilters = (evaporatorFilters: ProductFilter[]) => {
  return {
    type: DISPLAY_EVAPORATOR_FILTERS,
    evaporatorFilters,
  };
};

const selectEvaporatorFilter = (filterType: string, filterValue: string) => {
  return {
    type: SELECT_EVAPORATOR_FILTER,
    filterType,
    filterValue,
  };
};

const defaultEvaporatorFilterSelections = (filterSelections: FilterValues) => {
  return {
    type: DEFAULT_EVAPORATOR_FILTERS_SELECTIONS,
    filterSelections,
  };
};

const resetCondensingUnitFilters = () => {
  return {
    type: RESET_CONDENSING_UNIT_FILTERS,
  };
};

const resetEvaporatorFilters = () => {
  return {
    type: RESET_EVAPORATOR_UNIT_FILTERS,
  };
};

const displayPipeSizeRefrigerantOptions = (
  refrigerantOptions: FilterOption[]
) => {
  return {
    type: DISPLAY_PIPE_SIZE_REFRIGERANT_OPTIONS,
    refrigerantOptions,
  };
};

const displayEquipmentRefrigerantOptions = (
  refrigerantOptions: FilterOption[]
) => {
  return {
    type: DISPLAY_EQUIPMENT_REFRIGERANT_OPTIONS,
    refrigerantOptions,
  };
};

const setCondensingUnitSearchStatus = (searchStatus: SearchStatus) => {
  return {
    type: SET_CONDENSING_UNIT_SEARCH_STATUS,
    searchStatus,
  };
};

const setEvaporatorSearchStatus = (searchStatus: SearchStatus) => {
  return {
    type: SET_EVAPORATOR_SEARCH_STATUS,
    searchStatus,
  };
};

const setSingleCondensingUnitSearchStatus = (searchStatus: SearchStatus) => {
  return {
    type: SET_SINGLE_CONDENSING_UNIT_SEARCH_STATUS,
    searchStatus,
  };
};

const setSingleEvaporatorSearchStatus = (searchStatus: SearchStatus) => {
  return {
    type: SET_SINGLE_EVAPORATOR_SEARCH_STATUS,
    searchStatus,
  };
};

const clearCondensingUnitSearch = () => {
  return {
    type: CLEAR_CONDENSING_UNIT_SEARCH,
  };
};

const clearEvaporatorSearch = () => {
  return {
    type: CLEAR_EVAPORATOR_SEARCH,
  };
};

const roomTempBelowZero = (roomTempBelowZero: boolean) => {
  return {
    type: ROOM_TEMP_BELOW_ZERO,
    roomTempBelowZero,
  };
};

export default {
  searchCondensingUnits,
  searchSingleCondensingUnit,
  searchEvaporators,
  searchSingleEvaporator,
  loadingBalancePerformance,
  selectEvaporator,
  selectCondensingUnit,
  selectSingleEvaporator,
  selectSingleCondensingUnit,
  clearSelectedProducts,
  displayCondensingUnitFilters,
  selectCondensingUnitFilter,
  displayEvaporatorFilters,
  selectEvaporatorFilter,
  displayPipeSizeRefrigerantOptions,
  displayEquipmentRefrigerantOptions,
  defaultCondensingUnitFilterSelections,
  defaultEvaporatorFilterSelections,
  resetCondensingUnitFilters,
  resetEvaporatorFilters,
  setCondensingUnitSearchStatus,
  setEvaporatorSearchStatus,
  setSingleCondensingUnitSearchStatus,
  setSingleEvaporatorSearchStatus,
  clearCondensingUnitSearch,
  clearEvaporatorSearch,
  roomTempBelowZero,
};
