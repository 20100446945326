import React, { FC, useEffect } from "react";
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  Row,
  Table,
} from "../../components/Table/Table";
import {
  PipeSizeCalculationResponse,
  PipeSizeCalculations,
} from "../../types/PipeSizeCalculationResponse";
import TablePlaceholder from "../../components/TablePlaceholder/TablePlaceholder";
import ProductCard from "../../components/ProductCard/ProductCard";
import styles from "../../css/ResponsiveTable.module.css";
import MeetsCriteriaTooltip from "./MeetsCriteriaTooltip";
import Switch from "../../components/Switch/Switch";
import { useForm } from "react-hook-form";

interface PipeCalculationResultModel {
  pipeCalculationResults: PipeSizeCalculationResponse;
  lineType: string;
  handlePipeSelect: (
    selectedPipe: PipeSizeCalculations,
    position: string,
    lineType: string
  ) => void;
  handleUomSelected: (uom: string) => void;
  selectedLiquidLineHorizontalPipe: PipeSizeCalculations;
  selectedLiquidLineVerticalPipe: PipeSizeCalculations;
  selectedSuctionLineHorizontalPipe: PipeSizeCalculations;
  selectedSuctionLineVerticalPipe: PipeSizeCalculations;
  selectedDischargeLineHorizontalPipe: PipeSizeCalculations;
  selectedDischargeLineVerticalPipe: PipeSizeCalculations;
}

const buildPipeDescriptions = (
  pipeSizeCalcs: PipeSizeCalculations,
  uom: UnitTypes,
  section: SectionType,
  pressureUomSwitch: string
) => {
  let meetsCriteria = (
    <MeetsCriteriaTooltip pipe_gauge={pipeSizeCalcs} section={section} />
  );

  let deltaChar = "\u0394";
  let pressureDrop =
    pressureUomSwitch === "psi"
      ? `${pipeSizeCalcs[section].total_pressure_drop_psi} ${uom["total_pressure_drop_psi"]}`
      : `${pipeSizeCalcs[section].total_pressure_drop} ${uom["total_pressure_drop"]}`;

  let pipeDescriptionValues = [
    {
      label: "Outer Diameter",
      value: `${pipeSizeCalcs.od_mm} ${uom["od_mm"]}`,
    },
    {
      label: "Thickness",
      value: `${pipeSizeCalcs.wall_thickness} ${uom["wall_thickness"]}`,
    },
    {
      label: "Velocity",
      value: `${pipeSizeCalcs[section].velocity} ${uom["velocity"]}`,
    },
    {
      label: `${deltaChar} Pressure`,
      value: pressureDrop,
    },
    {
      label: `${deltaChar} Saturation Temperature`,
      value: `${pipeSizeCalcs[section].temperature_difference} ${uom["temperature_difference"]}`,
    },
    {
      label: `Section`,
      value: `${section.charAt(0).toUpperCase() + section.slice(1)}`,
    },
    {
      label: `Meets Criteria`,
      value: meetsCriteria,
    },
  ];

  return pipeDescriptionValues;
};

export type SectionType = "horizontal" | "vertical";

export interface UnitTypes {
  [field_name: string]: string;
}

interface ResultRowModel {
  pipe_gauge: PipeSizeCalculations;
  section: SectionType;
  uom: UnitTypes;
  pressureUomSwitch: string;
}

const ResultRow: FC<ResultRowModel> = ({
  pipe_gauge,
  section,
  uom,
  pressureUomSwitch,
}) => {
  return (
    <>
      <Cell textAlign="center">
        {pipe_gauge.od_in}
        {uom["od_in"]}
      </Cell>
      <Cell textAlign="center">
        {pipe_gauge.od_mm} {uom["od_mm"]}
      </Cell>
      <Cell textAlign="center">
        {pipe_gauge.wall_thickness} {uom["wall_thickness"]}
      </Cell>
      <Cell textAlign="center">
        {pipe_gauge[section].velocity} {uom["velocity"]}
      </Cell>
      <Cell textAlign="center">
        {pressureUomSwitch === "kPa" &&
          `${pipe_gauge[section].total_pressure_drop} ${uom["total_pressure_drop"]}`}
        {pressureUomSwitch === "psi" &&
          `${pipe_gauge[section].total_pressure_drop_psi} ${uom["total_pressure_drop_psi"]}`}
      </Cell>
      <Cell textAlign="center">
        {pipe_gauge[section].temperature_difference}
        {uom["temperature_difference"]}
      </Cell>
      <Cell textAlign="center">
        {section.charAt(0).toUpperCase() + section.slice(1)}
      </Cell>
      <Cell textAlign="center">
        <MeetsCriteriaTooltip pipe_gauge={pipe_gauge} section={section} />
      </Cell>
    </>
  );
};

interface SwitchPressureUomForm {
  pressureUom: string;
}

const defaultValues: SwitchPressureUomForm = {
  pressureUom: "kPa",
};

interface SwitchPressureUomMobileForm {
  pressureUomMobile: string;
}

const defaultMobileValues: SwitchPressureUomMobileForm = {
  pressureUomMobile: "kPa",
};

const PipeCalculationResults: FC<PipeCalculationResultModel> = ({
  pipeCalculationResults,
  lineType,
  handlePipeSelect,
  handleUomSelected,
  selectedLiquidLineHorizontalPipe,
  selectedLiquidLineVerticalPipe,
  selectedSuctionLineHorizontalPipe,
  selectedSuctionLineVerticalPipe,
  selectedDischargeLineHorizontalPipe,
  selectedDischargeLineVerticalPipe,
}) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useForm<SwitchPressureUomForm>({
    defaultValues,
  });
  const watchUomSwitch = watch("pressureUom");
  const {
    register: registerMobileView,
    formState: { errors: errorsMobileView },
    watch: watchMobileView,
  } = useForm<SwitchPressureUomMobileForm>({
    defaultValues: defaultMobileValues,
  });
  const watchUomSwitchMobile = watchMobileView("pressureUomMobile");

  let selectedHorizontalPipe =
    lineType === "Liquid"
      ? selectedLiquidLineHorizontalPipe
      : lineType === "Suction"
      ? selectedSuctionLineHorizontalPipe
      : selectedDischargeLineHorizontalPipe;
  let selectedVerticalPipe =
    lineType === "Liquid"
      ? selectedLiquidLineVerticalPipe
      : lineType === "Suction"
      ? selectedSuctionLineVerticalPipe
      : selectedDischargeLineVerticalPipe;

  useEffect(() => {
    handleUomSelected(watchUomSwitch);
  }, [watchUomSwitch, handleUomSelected]);

  return (
    <>
      {!pipeCalculationResults.pipe_gauge && (
        <TablePlaceholder
          heading="No results"
          subheading={`Enter operating details, temperature and ${lineType} line length section to see results`}
        />
      )}
      {pipeCalculationResults.pipe_gauge &&
        pipeCalculationResults.pipe_gauge.length > 0 && (
          <>
            <div className={styles.tableContainer}>
              <Table>
                <Header>
                  <Row>
                    <HeaderCell textAlign="center" width={10}>
                      Size
                    </HeaderCell>
                    <HeaderCell textAlign="center" width={10}>
                      Outer diameter
                    </HeaderCell>
                    <HeaderCell textAlign="center" width={10}>
                      Thickness
                    </HeaderCell>
                    <HeaderCell textAlign="center" width={15}>
                      Velocity
                    </HeaderCell>
                    <HeaderCell textAlign="center" width={15}>
                      &#916; Pressure
                      <br />
                      <form key={1}>
                        <Switch
                          {...register("pressureUom")}
                          values={[
                            { label: "kPa", value: "kPa" },
                            { label: "psi", value: "psi" },
                          ]}
                          hasErrors={errors.pressureUom !== undefined}
                          errorMessage={errors.pressureUom?.message}
                        />
                      </form>
                    </HeaderCell>
                    <HeaderCell textAlign="center" width={15}>
                      &#916; Saturation temp.
                    </HeaderCell>
                    <HeaderCell textAlign="center" width={10}>
                      Section
                    </HeaderCell>
                    <HeaderCell textAlign="center" width={10}>
                      Meets criteria
                    </HeaderCell>
                  </Row>
                </Header>
                <Body>
                  {pipeCalculationResults.pipe_gauge.map((pg, i) => {
                    let isSelectedHorizontalPipe =
                      Object.keys(selectedHorizontalPipe).length > 0
                        ? pg.horizontal && pg.id === selectedHorizontalPipe.id
                        : pipeCalculationResults.best_match.horizontal &&
                          pipeCalculationResults.best_match.horizontal.id ===
                            pg.id;
                    let isSelectedVerticalPipe =
                      Object.keys(selectedVerticalPipe).length > 0
                        ? pg.vertical && pg.id === selectedVerticalPipe.id
                        : pipeCalculationResults.best_match.vertical &&
                          pipeCalculationResults.best_match.vertical.id ===
                            pg.id;

                    return (
                      <React.Fragment key={i}>
                        {pg.horizontal && (
                          <Row
                            selected={isSelectedHorizontalPipe}
                            onClick={(e) =>
                              handlePipeSelect(pg, "horizontal", lineType)
                            }
                          >
                            <ResultRow
                              pipe_gauge={pg}
                              uom={pipeCalculationResults.uom}
                              section="horizontal"
                              pressureUomSwitch={watchUomSwitch}
                            />
                          </Row>
                        )}
                        {pg.vertical && (
                          <Row
                            selected={isSelectedVerticalPipe}
                            onClick={(e) =>
                              handlePipeSelect(pg, "vertical", lineType)
                            }
                          >
                            <ResultRow
                              pipe_gauge={pg}
                              uom={pipeCalculationResults.uom}
                              section="vertical"
                              pressureUomSwitch={watchUomSwitch}
                            />
                          </Row>
                        )}
                      </React.Fragment>
                    );
                  })}
                </Body>
              </Table>
            </div>
            <div className={styles.gridContainer}>
              <form key={2}>
                <Switch
                  {...registerMobileView("pressureUomMobile")}
                  values={[
                    { label: "kPa", value: "kPa" },
                    { label: "psi", value: "psi" },
                  ]}
                  labelText={`${"\u0394"} Pressure`}
                  hasErrors={errorsMobileView.pressureUomMobile !== undefined}
                  errorMessage={errorsMobileView.pressureUomMobile?.message}
                />
              </form>
              <br />
              {pipeCalculationResults.pipe_gauge.map((pg, i) => (
                <React.Fragment key={i}>
                  {pg.horizontal && (
                    <ProductCard
                      title={`${pg.od_in}${pipeCalculationResults.uom["od_in"]}`}
                      productDescriptionValues={buildPipeDescriptions(
                        pg,
                        pipeCalculationResults.uom,
                        "horizontal",
                        watchUomSwitchMobile
                      )}
                      linkId={pg.id}
                    />
                  )}
                  {pg.vertical && (
                    <ProductCard
                      title={`${pg.od_in}${pipeCalculationResults.uom["od_in"]}`}
                      productDescriptionValues={buildPipeDescriptions(
                        pg,
                        pipeCalculationResults.uom,
                        "vertical",
                        watchUomSwitchMobile
                      )}
                      linkId={pg.id}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </>
        )}
    </>
  );
};

export default PipeCalculationResults;
