import React, { FC } from "react";
import { useSelector } from "react-redux";
import { LightingDetailsForm } from "./LightingDetailsContainer";
import LightingDetailsContainer from "./LightingDetailsContainer";

const LightingDetailsParent: FC<{}> = () => {
  const additionalLoadsEnabled: boolean = useSelector((state: any) => {
    return state.heatLoad.formSections.additionalLoadsEnabled;
  });

  let defaultValues: LightingDetailsForm | undefined;

  if (additionalLoadsEnabled) {
    defaultValues = {
      lightingType: "led",
      heatGenerated: 2,
      lightUsage: 8,
    };
  }

  return additionalLoadsEnabled && defaultValues !== undefined ? (
    <LightingDetailsContainer preloadedValues={defaultValues} />
  ) : (
    <></>
  );
};

export default LightingDetailsParent;
