import React, { FC } from "react";
import PrintSectionHeader from "../../../components/PrintTemplate/PrintSectionHeader";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";
import { CondensingUnitFilterModel } from "../../../types/CondensingUnitFilterModel";

const CondensingUnitInputs: FC<{
  condensingUnitSearch: CondensingUnitFilterModel;
}> = ({ condensingUnitSearch }) => {
  return (
    <>
      <PrintSectionHeader>Heat load and refrigerant</PrintSectionHeader>

      <table className={styles.tableHalfWidth}>
        <tbody>
          <tr>
            <td width="60%">Standards rating</td>
            <td width="40%">{condensingUnitSearch.standardsRating}</td>
          </tr>
          <tr>
            <td width="60%">Required capacity</td>
            <td width="40%">{condensingUnitSearch.capacity} W</td>
          </tr>
          <tr>
            <td width="60%">Refrigerant</td>
            <td width="40%">{condensingUnitSearch.refrigerant}</td>
          </tr>
        </tbody>
      </table>

      <PrintSectionHeader>
        Temperatures &amp; ambient conditions
      </PrintSectionHeader>

      <table className={styles.tablePrint}>
        <tbody>
          <tr>
            <td width="30%">Reference temp. method</td>
            <td width="20%">
              {condensingUnitSearch.mid_or_dew === "mid"
                ? "Mid point"
                : "Dew point"}
            </td>
            <td width="30%">Liquid subcooling</td>
            <td width="20%">{condensingUnitSearch.liquidSubcooling} K</td>
          </tr>
          <tr>
            <td width="30%">Saturated suction temp.</td>
            <td width="20%">{condensingUnitSearch.sst}°C</td>
            <td width="30%">Ambient temp. on condensing unit</td>
            <td width="20%">{condensingUnitSearch.ambient}°C</td>
          </tr>
          <tr>
            <td width="30%">Compressor inlet superheat</td>
            <td width="20%">
              {condensingUnitSearch.compressorInletSuperheat} K
            </td>
            <td width="30%">&nbsp;</td>
            <td width="20%">&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default CondensingUnitInputs;
