import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Input from "../../../../components/Input/Input";
import { ambientRules } from "../CapitalEquipmentValidation";

const ClimateDetailsSection: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Input
        {...register("ambient", ambientRules)}
        labelText="Ambient temp. at condensing unit"
        innerLabel="°C"
        placeholderText="20° to 43°"
        hasErrors={errors.ambient}
        errorMessage={errors.ambient?.message}
      />
    </>
  );
};

export default ClimateDetailsSection;
