import React from "react";
import styles from "./Placeholder.module.css";
import Heading from "../Headings/Headings";

interface Props {
  heading: string;
  subheading: string;
  width?: string;
  height?: string;
}

const Placeholder: React.FC<Props> = ({
  heading,
  subheading,
  width,
  height,
  children,
}) => {
  let style = {
    ...(width && { width: width }),
    ...(height && { height: height }),
  };

  return (
    <div
      data-testid={heading.toLocaleLowerCase().replace(/ /g, "-")}
      style={style}
      className={styles.container}
    >
      <div>
        <Heading>{heading}</Heading>
        <p>{subheading}</p>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Placeholder;
