import React from "react";
import styles from "./BalancedPerformance.module.css";
import { BalancePerformanceModel } from "../../../types/BalancePerformanceModel";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder/LoadingPlaceholder";

const BalancedPerformance: React.FC<{
  balance: BalancePerformanceModel;
  isLoading: boolean;
}> = ({ balance, isLoading = false }) => {
  return (
    <section className={styles.container}>
      <div>
        <div className="section-header">Balanced performance</div>
        <div className={styles.performanceValues}>
          <div className={styles.performanceAttributeGrid}>
            <p>Temp. difference (TD)</p>
            {isLoading && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!isLoading && (
              <p>
                <b>{balance.ktd.toFixed(2)}</b> K
              </p>
            )}
          </div>
          <div className={styles.performanceAttributeGrid}>
            <p>Saturated suction temp. (SST)</p>
            {isLoading && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!isLoading && (
              <p>
                <b>{balance.sst.toFixed(2)}</b> °C
              </p>
            )}
          </div>
          <div className={styles.performanceAttributeGrid}>
            <p>Saturated condensing temp. (SCT)</p>
            {isLoading && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!isLoading && (
              <p>
                <b>{balance.sct.toFixed(2)}</b> °C
              </p>
            )}
          </div>
          <div className={styles.performanceAttributeGrid}>
            <p>Capacity</p>
            {isLoading && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!isLoading && (
              <p>
                <b>{balance.capacity.toFixed(0)}</b> W
              </p>
            )}
          </div>
          <div className={styles.performanceAttributeGrid}>
            <p>Power</p>
            {isLoading && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!isLoading && (
              <p>
                <b>{balance.power.toFixed(0)}</b> W
              </p>
            )}
          </div>
          <div className={styles.performanceAttributeGrid}>
            <p>Coefficient of performance (COP)</p>
            {isLoading && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!isLoading && (
              <p>
                <b>{balance.cop.toFixed(2)}</b>
              </p>
            )}
          </div>
          <div className={styles.performanceAttributeGrid}>
            <p>Runtime</p>
            {isLoading && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!isLoading && (
              <p>
                <b>{balance.runtime.toFixed(1)}</b> hrs/day
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BalancedPerformance;
