import { default as pipeActions } from "./actions";

const pipeSizeCalculation = pipeActions.pipeSizeCalculation;
const selectLiquidLinePipeHorizontal =
  pipeActions.selectLiquidLinePipeHorizontal;
const selectLiquidLinePipeVertical = pipeActions.selectLiquidLinePipeVertical;
const selectDischargeLinePipeHorizontal =
  pipeActions.selectDischargeLinePipeHorizontal;
const selectDischargeLinePipeVertical =
  pipeActions.selectDischargeLinePipeVertical;
const selectSuctionLinePipeHorizontal =
  pipeActions.selectSuctionLinePipeHorizontal;
const selectSuctionLinePipeVertical = pipeActions.selectSuctionLinePipeVertical;
const clearAllPipeSelections = pipeActions.clearAllPipeSelections;

export default {
  pipeSizeCalculation,
  selectLiquidLinePipeHorizontal,
  selectLiquidLinePipeVertical,
  selectDischargeLinePipeHorizontal,
  selectDischargeLinePipeVertical,
  selectSuctionLinePipeHorizontal,
  selectSuctionLinePipeVertical,
  clearAllPipeSelections,
};
