import React from "react";
import styles from "./Thumbnail.module.css";
import ThumbnailLoadingPlaceholder from "./ThumbnailLoadingPlaceholder";

const UNAVAILABLE_IMAGE_URL =
  "https://www.actrol.com.au/search/static/images/image-unavailable.jpg";

const Thumbnail: React.FC<{ thumbnailUrl: string | undefined | null }> = ({
  thumbnailUrl,
}) => {
  if (thumbnailUrl === undefined) {
    return <ThumbnailLoadingPlaceholder />;
  } else if (thumbnailUrl === null) {
    return (
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={UNAVAILABLE_IMAGE_URL}
          alt="Product item thumbnail"
          data-testid="product-item-thumbnail"
        />
      </div>
    );
  } else {
    return (
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={thumbnailUrl}
          alt="Product item thumbnail"
          data-testid="product-item-thumbnail"
        />
      </div>
    );
  }
};

export default Thumbnail;
