import React, { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ProductFilter } from "../../../../types/ProductFilter";
import { LightingDetails as LightingDetailsModel } from "../../../types/Room";
import LightingDetails from "./LightingDetails";
import actionSelector from "../../../duck/actions";
import { validateLightingDetails } from "./LightingDetailsValidation";
import { EMPTY_FILTER } from "../../../hooks/ExtractHeatloadFilters";

export interface LightingDetailsForm {
  lightingType: string;
  heatGenerated?: number;
  lightUsage?: number;
}

const LightingDetailsContainer: FC<{
  preloadedValues: LightingDetailsForm;
}> = ({ preloadedValues }) => {
  let methods = useForm<LightingDetailsForm>({
    defaultValues: preloadedValues,
    mode: "onBlur",
  });
  let [defaultFormReady, setDefaultFormReady] = useState(true);

  const dispatch = useDispatch();
  const { watch, formState } = methods;
  const { errors, dirtyFields, isValidating } = formState;

  const lightingOptions: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.miscellaneous
      ? state.heatLoad.formHeatLoadFilters.miscellaneous["lighting_type"]
      : EMPTY_FILTER
  );

  useEffect(() => {
    const lightingDetails = watch();

    if (
      (isValidating || defaultFormReady) &&
      lightingDetails.lightUsage !== undefined &&
      lightingDetails.heatGenerated !== undefined &&
      validateLightingDetails(errors)
    ) {
      let lightingType = lightingOptions.options.find(
        (light) => light.key === lightingDetails.lightingType
      );

      const lighting: LightingDetailsModel = {
        lightingType: lightingDetails.lightingType,
        lightingTypeDisplay: lightingType?.value ?? "No lighting",
        hours: lightingDetails.lightUsage,
        heatGenerated: lightingDetails.heatGenerated,
      };

      dispatch(actionSelector.saveLightingDetails(lighting));
      setDefaultFormReady(false);
    }
  }, [
    errors,
    isValidating,
    dirtyFields,
    dispatch,
    watch,
    defaultFormReady,
    lightingOptions.options,
  ]);

  return (
    <FormProvider {...methods}>
      <form>
        <LightingDetails lightingOptions={lightingOptions} />
      </form>
    </FormProvider>
  );
};

export default LightingDetailsContainer;
