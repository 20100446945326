import Edge from "./Edge";
import AirCondition from "./AirCondition";
import Vertex from "./Vertex";
import { LoadCapacity } from "./load/TotalHeatLoad";

export const defaultSurfaceEdgers = (height: number, width: number) => {
  return [
    new Edge(new Vertex(0, 0), new Vertex(height, 0), height),
    new Edge(new Vertex(height, 0), new Vertex(height, width), width),
    new Edge(new Vertex(height, width), new Vertex(0, width), height),
    new Edge(new Vertex(0, width), new Vertex(0, 0), width),
  ];
};

class Face {
  private readonly _id: string;
  private readonly _edgers: Edge[];
  private _thickness: number;
  private _conductivity: number;
  private _outsideAirCondition: AirCondition;
  private _facingSun: boolean;
  private _uValue: number;

  constructor(faceId: string, edgers: Edge[]) {
    this._id = faceId;
    this._edgers = edgers;
  }

  get edgers(): Edge[] {
    return this._edgers;
  }

  get id(): string {
    return this._id;
  }

  get thickness(): number {
    return this._thickness;
  }

  set thickness(thickness: number) {
    this._thickness = thickness;
  }

  get conductivity(): number {
    return this._conductivity;
  }

  set conductivity(conductivity: number) {
    this._conductivity = conductivity;
  }

  get outsideAirCondition(): AirCondition {
    return this._outsideAirCondition;
  }

  set outsideAirCondition(outsideAirCondition: AirCondition) {
    this._outsideAirCondition = outsideAirCondition;
  }

  get facingSun(): boolean {
    return this._facingSun;
  }

  set facingSun(sunlight: boolean) {
    this._facingSun = sunlight;
  }

  get uValue() {
    return this._uValue;
  }

  set uValue(uValue: number) {
    this._uValue = uValue;
  }

  surfaceArea() {
    if (this._edgers.length === 4) {
      // TODO: required validation to make sure it is a rectangular
      return this._edgers[0].edgeLength * this._edgers[1].edgeLength;
    }
    throw new Error(
      "Invalid number of edgers to calculate area, should contain 4 but only have " +
        this._edgers.length
    );
  }

  transmittableSurfaceArea() {
    return this.surfaceArea();
  }

  transmissionLoad(room_temperature: number): LoadCapacity {
    if (!this.outsideAirCondition)
      throw new Error("Missing outside room temperature");

    let sensibleHeat = 0;
    let outsideTemperature = this.outsideAirCondition.roomTemperature;
    let surfaceAreaInSQM = this.transmittableSurfaceArea() / (1000 * 1000);

    outsideTemperature += this.facingSun ? 5 : 0;
    let temperatureDifference = outsideTemperature - room_temperature;

    let uValue;

    if (this.uValue && !isNaN(this.uValue)) {
      uValue = this.uValue;
    }

    if (
      sensibleHeat === 0 &&
      this.conductivity &&
      !isNaN(this.conductivity) &&
      this.thickness
    ) {
      uValue = this.conductivity / (this.thickness / 1000);
    }

    if (uValue) {
      sensibleHeat = this.surfaceTransmissionLoad(
        surfaceAreaInSQM,
        uValue,
        temperatureDifference
      );
    } else {
      // TODO: These should be refactored, not clear the intention
      if (!this.conductivity && !this.uValue)
        throw new Error(
          "Missing conductivity and uValue, require either one of them"
        );
      if (this.conductivity && !isNaN(this.conductivity) && !this.thickness)
        throw new Error(
          "Missing thickness. Provide uValue or thickness with conductivity"
        );
    }

    return { sensible: Math.round(sensibleHeat * 1000) / 1000, latent: 0 };
  }

  protected surfaceTransmissionLoad(
    surfaceAreaInSQM: number,
    uValue: number,
    temperatureDifference: number
  ) {
    return surfaceAreaInSQM * uValue * temperatureDifference;
  }
}

export default Face;
