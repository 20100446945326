import { navigate, setQueryParams } from "hookrouter";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import RadioGroup, {
  RadioValue,
} from "../../../components/RadioGroup/RadioGroup";
import Section from "../../../components/Section/Section";
import styles from "./SearchTypeSelection.module.css";
import layoutStyles from "../../../components/Layout/Layout.module.css";
import Button from "../../../components/Button/Button";
import {
  BalancedPerformance,
  EvaporatorProductCapacity,
} from "../../../types/EvaporatorProductCapacity";
import { useSelector } from "react-redux";
import { CondensingUnitProductCapacity } from "../../../types/CondensingUnitProductCapacity";
import { logPageEvent } from "../../../helpers/amplitude";
import Image from "../../../components/Image/Image";
import PageTitleSection from "../../../components/PageTitleSection/PageTitleSection";

export const SearchTypeSelection: FC<{ defaultValue: string }> = ({
  defaultValue,
}) => {
  const [enablePrintButton, setEnablePrintButton] = useState(false);
  const [enableNavigateToPSButton, setEnabledNavigateToPSButton] =
    useState(false);
  const confirmationText =
    "OK to Continue?\n\nThe data you've customised on this page will be lost if you continue to the next page.";

  const [showNextButton, setShowNextButton] = useState(true);
  let analyticsDescription =
    defaultValue === "/equipment-selection"
      ? "Equipment selection - Print"
      : defaultValue === "/condensing-unit-search"
      ? "Condensing unit search - Print"
      : "Evaporator search - Print";

  useEffect(() => {
    if (window.location.pathname === "/evaporator-search") {
      setShowNextButton(false);
    }
  }, []);

  const {
    register,
    formState: { errors },
    watch,
  } = useForm<any>({
    defaultValues: { searchTypeSelection: defaultValue },
    mode: "onBlur",
  });
  const watchSearchType = watch("searchTypeSelection");

  const radioValues: RadioValue[] = [
    {
      label: "Condensing unit & evaporator combination",
      value: "/equipment-selection",
    },
    {
      label: "Condensing unit only",
      value: "/condensing-unit-search",
    },
    {
      label: "Evaporator only",
      value: "/evaporator-search",
    },
  ];

  useEffect(() => {
    navigate(watchSearchType);
  }, [watchSearchType]);

  const balancePerformanceDetails: BalancedPerformance = useSelector(
    (state: any) => {
      return state.product.selectedEvaporator.balance_performance;
    }
  );

  const condensingUnitProductCapacity: CondensingUnitProductCapacity =
    useSelector((state: any) => {
      return state.product.selectedSingleCondensingUnit;
    });

  const selectedEvaporator: EvaporatorProductCapacity = useSelector(
    (state: any) => state.product.selectedSingleEvaporator
  );

  useEffect(() => {
    const isEquipmentSelectPerformed =
      window.location.pathname === "/equipment-selection" &&
      balancePerformanceDetails !== undefined;
    const isSingleCondensingUnitSearchPerformed =
      window.location.pathname === "/condensing-unit-search" &&
      condensingUnitProductCapacity.capacity > 0;

    if (isEquipmentSelectPerformed || isSingleCondensingUnitSearchPerformed) {
      setEnablePrintButton(true);
      setEnabledNavigateToPSButton(true);
    }
  }, [balancePerformanceDetails, condensingUnitProductCapacity.capacity]);

  useEffect(() => {
    if (
      window.location.pathname === "/evaporator-search" &&
      selectedEvaporator.operating_capacity &&
      selectedEvaporator.operating_capacity.capacity > 0
    ) {
      setEnablePrintButton(true);
    }
  }, [balancePerformanceDetails, selectedEvaporator.operating_capacity]);

  return (
    <form className={styles.formContainer}>
      <PageTitleSection title="Equipment selection">
        <Button
          isDisabled={!enablePrintButton}
          buttonStyle="secondary"
          onClickHandler={() => {
            logPageEvent(analyticsDescription);
            window.print();
          }}
        >
          Print
        </Button>

        {showNextButton && (
          <Button
            isDisabled={!enableNavigateToPSButton}
            onClickHandler={() => {
              if (window.confirm(confirmationText)) {
                logPageEvent(
                  "Next button clicked from Equipment selection to Pipe size calculation"
                );
                setQueryParams({ originPage: window.location.pathname });
                navigate("/pipe-size-calculation");
              }
            }}
          >
            Next: Pipe selection
            <Image filename="navigate_next_white" altText="" />
          </Button>
        )}
      </PageTitleSection>

      <div className={layoutStyles.contentContainer}>
        <Section>
          <RadioGroup
            {...register("searchTypeSelection")}
            labelText=""
            values={radioValues}
            hasErrors={errors.searchTypeSelection}
            hasBorder={true}
          />
        </Section>
      </div>
    </form>
  );
};

export default SearchTypeSelection;
