import React, { FC } from "react";
import { useSelector } from "react-redux";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";
import {
  ForkliftDetails,
  LightingDetails,
  PeopleDetails,
  VentilationDetails,
} from "../../types/Room";

const AdditionalLoadsOutput: FC = () => {
  const peopleDetails: PeopleDetails = useSelector((state: any) => {
    return state.heatLoad.people;
  });

  const ventilationDetails: VentilationDetails = useSelector((state: any) => {
    return state.heatLoad.ventilation;
  });

  const lightingDetails: LightingDetails = useSelector((state: any) => {
    return state.heatLoad.lighting;
  });

  const forkliftDetails: ForkliftDetails = useSelector((state: any) => {
    return state.heatLoad.forklift;
  });

  return (
    <table className={styles.tableHalfWidth}>
      <tbody>
        {peopleDetails && peopleDetails.peopleCount !== undefined && (
          <>
            <tr>
              <td width="40%">
                <strong>People</strong>
              </td>
              <td width="60%">{peopleDetails.peopleCount}</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Level of activity
              </td>
              <td width="60%">{peopleDetails.activityLevelDisplay}</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Activity per day
              </td>
              <td width="60%">{peopleDetails.hours} hrs</td>
            </tr>
          </>
        )}
        {ventilationDetails &&
          ventilationDetails.ventilationType !== undefined && (
            <tr>
              <td width="40%">
                <strong>Ventilation</strong>
              </td>
              <td width="60%">
                {ventilationDetails.ventilationType !== "none"
                  ? `${ventilationDetails.airLitresPerPerson} L/person`
                  : "No ventilation"}
              </td>
            </tr>
          )}
        {lightingDetails && lightingDetails.lightingType !== undefined && (
          <>
            <tr>
              <td width="40%">
                <strong>Light options</strong>
              </td>
              <td width="60%">{lightingDetails.lightingTypeDisplay}</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Heat gain
              </td>
              <td width="60%">{lightingDetails.heatGenerated} W/m²</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Usage per day
              </td>
              <td width="60%">{lightingDetails.hours} hrs</td>
            </tr>
          </>
        )}
        {forkliftDetails && (
          <>
            <tr>
              <td width="40%">
                <strong>Forklifts</strong>
              </td>
              <td width="60%">{forkliftDetails.forkliftType}</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Heat gain
              </td>
              <td width="60%">{forkliftDetails.heatGain} W</td>
            </tr>
            <tr>
              <td width="40%" className={styles.indentTableCell}>
                Usage per day
              </td>
              <td width="60%">{forkliftDetails.hours} hrs</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default AdditionalLoadsOutput;
