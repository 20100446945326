import React, { FC } from "react";
import { EvaporatorProductCapacity } from "../../../types/EvaporatorProductCapacity";
import ProductInfoPrint from "./ProductInfoPrint";
import styles from "./SelectedEquipment.module.css";

const SelectedEvaporatorPrint: FC<{
  selectedEvaporator: EvaporatorProductCapacity;
  selectedRefrigerant: string;
  numberOfEvaporators: number;
}> = ({ selectedEvaporator, selectedRefrigerant, numberOfEvaporators }) => {
  return (
    <div className={styles.equipmentContainer}>
      <div className={styles.title}>Evaporator(s)</div>

      <ProductInfoPrint
        series={selectedEvaporator.series}
        refrigerant={selectedRefrigerant}
        numberRequired={numberOfEvaporators}
        model={selectedEvaporator.model}
        thumbnail={selectedEvaporator.thumbnail}
        product_code={selectedEvaporator.product_code}
      />
    </div>
  );
};

export default SelectedEvaporatorPrint;
