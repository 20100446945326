import React, { FC } from "react";
import styles from "../Layout/Layout.module.css";

const PageTitleSection: FC<{ title: string }> = ({ title, children }) => {
  return (
    <>
      <div className={`page-header-container ${styles.pageTitleContainer}`}>
        <div className="section-header">{title}</div>
        <div className="printButtonContainer">
          <div className="buttonContents">{children}</div>
        </div>
      </div>
      <div className="shadow"></div>
      <div className="cover"></div>
    </>
  );
};

export default PageTitleSection;
