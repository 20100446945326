import Vertex from "./Vertex";

class Edge {
  private readonly _vertexStart: Vertex;
  private readonly _vertexEnd: Vertex;
  private readonly _edgeLength: number;

  constructor(vertexStart: Vertex, vertexEnd: Vertex, edgeLength: number) {
    this._vertexStart = vertexStart;
    this._vertexEnd = vertexEnd;
    this._edgeLength = edgeLength;
  }

  get vertexStart() {
    return this._vertexStart;
  }

  get vertexEnd() {
    return this._vertexEnd;
  }

  get edgeLength() {
    return this._edgeLength;
  }
}

export default Edge;
