import HeatCapacity from "./HeatCapacity";
import { InfiltrationLoadRequest } from "./InfiltrationLoadRequest";
import ServiceEndpoints from "./ServiceEndpoints";
import getUrl from "../../../helpers/api";

const calculateInfiltrationLoad = (
  request: InfiltrationLoadRequest
): Promise<HeatCapacity> => {
  let url = new ServiceEndpoints().getInfiltrationLoad(request);

  return getUrl<HeatCapacity>(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      // TODO: Just a reminder use this for cleanups
    });
};

export { calculateInfiltrationLoad };
