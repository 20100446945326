import React, { FC } from "react";
import Image from "../Image/Image";
import styles from "./Pill.module.css";

type BulletAlertLevel = "WARNING" | "ERROR";

interface BulletProps {
  text: string;
  alertLevel: BulletAlertLevel;
  imageFilename: string;
}

const Pill: FC<BulletProps> = ({ text, alertLevel, imageFilename }) => {
  let alertLevelClass =
    alertLevel === "WARNING" ? styles.warning : styles.error;

  return (
    <div className={`${styles.bulletContainer} ${alertLevelClass}`}>
      <Image filename={imageFilename} altText={`${alertLevel}: ${text}`} />
      <span>{text}</span>
    </div>
  );
};

export default Pill;
