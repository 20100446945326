import React, { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import RoomDetails from "./RoomDetails";
import { useDispatch, useSelector } from "react-redux";
import actionSelector from "../../duck/selectors";
import { StandardRoom } from "../../../types/HeatLoad";
import isNaNReturnDefault from "../../../helpers/isNaNReturnNumber";
import {
  validateRoomDetails,
  allRoomDetailsFieldsDirty,
} from "./RoomDetailsValidation";
import { ExtendedValues, ProductFilter } from "../../../types/ProductFilter";
import { EMPTY_FILTER } from "../../hooks/ExtractHeatloadFilters";
import { GroupedOption } from "../../../types/SearchSelect";
import { buildProductGroupOptionsWithGroupAppended } from "../ProductDetails/ProductDetailsHelper";
import { logPageEvent, logPageEventWithData } from "../../../helpers/amplitude";
import { useQueryParams } from "hookrouter";

export interface RoomDetailsForm {
  locationSelected: { label: string; value: string };
  temperature?: number;
  humidity?: number;
  length?: number;
  width?: number;
  height?: number;
  outsideTemperature?: number;
  outsideHumidity?: number;
  groundTemperature?: number;
  wetBulbTemperature?: number;
  roomUsage?: string;
}

const LOW_TEMP_RUNTIME = 18;
const MID_TEMP_RUNTIME = 16;
const LOW_TEMP_FAN_LOAD_RATIO = 14;
const MID_TEMP_FAN_LOAD_RATIO = 6;
const LOW_TEMP_HEATED_FASCIA = 16;
const MID_TEMP_HEATED_FASCIA = 9;
const LOW_TEMP_GLASS_FASCIA = 86;
const MID_TEMP_GLASS_FASCIA = 43;

const RoomDetailsContainer: FC<{ showHeatLoadSummary: () => void }> = ({
  showHeatLoadSummary,
}) => {
  const [enableInitialHeatLoadButton, setEnabledInitialHeatLoadButton] =
    useState(false);
  const [initialHeatLoadButtonClicked, setInitialHeatLoadButtonClicked] =
    useState(false);
  const [queryParams] = useQueryParams();

  const advancedCalcState = useSelector((state: any) => {
    return state.heatLoad.advancedCalculation;
  });

  const {
    // Use object destructuring and a default value
    // if the param is not yet present in the URL.
    type = "",
  } = queryParams;

  const defaultValues: RoomDetailsForm = {
    locationSelected: { label: "Default location", value: "default location" },
    ...(type !== "" && type === "cool" && { temperature: 2, humidity: 80 }),
    ...(type !== "" && type === "freezer" && { temperature: -18, humidity: 0 }),
  };

  const methods = useForm<RoomDetailsForm>({
    defaultValues,
    mode: "onBlur",
  });

  const dispatch = useDispatch();
  const { watch } = methods;
  const room = watch();
  const { isValidating, errors, dirtyFields } = methods.formState;

  const roomUsageFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.doors
      ? state.heatLoad.formHeatLoadFilters.doors["room_usage"]
      : EMPTY_FILTER
  );

  let roomUsageFactor: ExtendedValues | undefined;
  let roomUsageName: string = "";

  if (room.roomUsage && roomUsageFilter.options) {
    let option = roomUsageFilter.options.find((o) => o.key === room.roomUsage);
    roomUsageName = option?.value ?? "";

    if (option && "extended_values" in option) {
      roomUsageFactor = option["extended_values"];
    }
  }

  const locationFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.locations
      ? state.heatLoad.formHeatLoadFilters.locations.location
      : EMPTY_FILTER
  );

  const locationCategoryFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.locations
      ? state.heatLoad.formHeatLoadFilters.locations.location_state
      : EMPTY_FILTER
  );

  const [groupedOptions, setGroupOption] = useState<GroupedOption[]>([]);

  useEffect(() => {
    setGroupOption(
      buildProductGroupOptionsWithGroupAppended(
        locationFilter,
        locationCategoryFilter
      )
    );
  }, [locationFilter, locationCategoryFilter, setGroupOption]);

  const initialHeatLoadHandler = () => {
    setInitialHeatLoadButtonClicked(true);
    setEnabledInitialHeatLoadButton(false);
    showHeatLoadSummary();
    logPageEvent("Heat Load - Get initial heat load clicked");
  };

  useEffect(() => {
    // If there are no errors in the room object, dispatch to redux..
    if (
      isValidating &&
      validateRoomDetails(errors, dirtyFields, type) &&
      room.temperature !== undefined &&
      room.humidity !== undefined &&
      room.length !== undefined &&
      room.width !== undefined &&
      room.height !== undefined &&
      room.roomUsage !== undefined &&
      roomUsageFactor &&
      roomUsageName
    ) {
      let roomTemperature = isNaNReturnDefault(room.temperature);
      let runtime = roomTemperature > 0 ? MID_TEMP_RUNTIME : LOW_TEMP_RUNTIME;
      let fanFactor =
        roomTemperature > 0 ? MID_TEMP_FAN_LOAD_RATIO : LOW_TEMP_FAN_LOAD_RATIO;
      let heatedFasciaValue =
        roomTemperature > 0 ? MID_TEMP_HEATED_FASCIA : LOW_TEMP_HEATED_FASCIA;
      let glassFasciaValue =
        roomTemperature > 0 ? MID_TEMP_GLASS_FASCIA : LOW_TEMP_GLASS_FASCIA;
      let outsideTemperature = room.outsideTemperature
        ? room.outsideTemperature
        : roomTemperature;
      let outsideHumidity = room.outsideHumidity
        ? room.outsideHumidity
        : room.humidity;

      const roomDetails: StandardRoom = {
        temperature: roomTemperature,
        humidity: isNaNReturnDefault(room.humidity),
        length: isNaNReturnDefault(room.length),
        width: isNaNReturnDefault(room.width),
        height: isNaNReturnDefault(room.height),
        outsideTemperature: isNaNReturnDefault(outsideTemperature),
        outsideHumidity: isNaNReturnDefault(outsideHumidity),
        groundTemperature: isNaNReturnDefault(room.groundTemperature ?? NaN),
        roomUsage: room.roomUsage,
        roomUsageDisplay: roomUsageName,
        defaultRuntime: runtime,
        defaultFanFactor: fanFactor,
        defaultHeatedFasciaValue: heatedFasciaValue,
        defaultGlassFasciaValue: glassFasciaValue,
        location: room.locationSelected.label,
        wetBulbTemperature: room.wetBulbTemperature ?? 0,
        extendedValues: {
          roomUsageFactor: parseFloat(roomUsageFactor["usage_factor"]),
        },
      };

      dispatch(actionSelector.saveStandardRoomDetails(roomDetails));
      dispatch(
        actionSelector.saveFormSectionState({
          transmissionDetailsEnabled: true,
        })
      );

      logPageEventWithData("Heat Load - Location Details Saved", {
        location: room.locationSelected.value,
        outsideTemperature: isNaNReturnDefault(outsideTemperature),
        outsideHumidity: isNaNReturnDefault(outsideHumidity),
        groundTemperature: room.groundTemperature,
      });

      logPageEventWithData("Heat Load - Room Details Saved", {
        temperature: roomTemperature,
        humidity: isNaNReturnDefault(room.humidity),
        length: isNaNReturnDefault(room.length),
        width: isNaNReturnDefault(room.width),
        height: isNaNReturnDefault(room.height),
        roomUsage: room.roomUsage,
      });
    }
  }, [
    dirtyFields,
    errors,
    isValidating,
    dispatch,
    room,
    initialHeatLoadButtonClicked,
    type,
    roomUsageFactor,
    roomUsageName,
  ]);

  useEffect(() => {
    if (
      allRoomDetailsFieldsDirty(dirtyFields, type) &&
      room.temperature !== undefined &&
      !isNaN(room.temperature) &&
      room.humidity !== undefined &&
      !isNaN(room.humidity) &&
      room.length !== undefined &&
      !isNaN(room.length) &&
      room.width !== undefined &&
      !isNaN(room.width) &&
      room.height !== undefined &&
      !isNaN(room.height) &&
      ((advancedCalcState === false &&
        room.roomUsage !== undefined &&
        room.roomUsage !== "") ||
        advancedCalcState === true)
    ) {
      if (!initialHeatLoadButtonClicked) setEnabledInitialHeatLoadButton(true);
    } else {
      if (!initialHeatLoadButtonClicked) setEnabledInitialHeatLoadButton(false);
    }
  }, [
    advancedCalcState,
    room,
    dirtyFields,
    errors,
    initialHeatLoadButtonClicked,
    type,
  ]);

  return (
    <FormProvider {...methods}>
      <div>
        <form>
          <RoomDetails
            locationGroupedOptions={groupedOptions}
            locationFilter={locationFilter}
            roomType={type}
            roomUsage={roomUsageFilter}
            initialHeatLoadButtonEnabled={enableInitialHeatLoadButton}
            initialHeatLoadHandler={initialHeatLoadHandler}
          />
        </form>
      </div>
    </FormProvider>
  );
};

export default RoomDetailsContainer;
