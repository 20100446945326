import { RegisterOptions } from "react-hook-form";

const capacityRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Must be between 0 and 999000 Watts",
  },
  max: {
    value: 999000,
    message: "Must be between 0 and 999000 Watts",
  },
  valueAsNumber: true,
};

const horizontalLengthRules: RegisterOptions = {
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Must be between 0 and 150 metres",
  },
  max: {
    value: 150,
    message: "Must be between 0 and 150 metres",
  },
};

const verticalLengthRules: RegisterOptions = {
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Must be between 0 and 20 metres",
  },
  max: {
    value: 20,
    message: "Must be between 0 and 20 metres",
  },
  valueAsNumber: true,
};

const sstRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^-?\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: -50,
    message: "Must be between -50\u00b0 and 20\u00b0 Celsius",
  },
  max: {
    value: 20,
    message: "Must be between -50\u00b0 and 20\u00b0 Celsius",
  },
  valueAsNumber: true,
};

const compressorInletSuperheatRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Must be between 0 and +80 Kelvin",
  },
  max: {
    value: 80,
    message: "Must be between 0 and +80 Kelvin",
  },
  valueAsNumber: true,
};

const liquidSubcoolingRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Must be between 0 and +15 Kelvin",
  },
  max: {
    value: 15,
    message: "Must be between 0 and +15 Kelvin",
  },
  valueAsNumber: true,
};

export {
  capacityRules,
  horizontalLengthRules,
  verticalLengthRules,
  sstRules,
  compressorInletSuperheatRules,
  liquidSubcoolingRules,
};
