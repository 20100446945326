import React, { FC, Fragment, useCallback } from "react";
import { CondensingUnitProductCapacity } from "../../../../types/CondensingUnitProductCapacity";
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  Row,
  Table,
  TableNoBorders,
} from "../../../../components/Table/Table";
import { ProductFilter } from "../../../../types/ProductFilter";
import Filters from "../../../../components/Filters/Filters";
import Thumbnail from "../../../../components/Thumbnail/Thumbnail";
import { FilterValues } from "../../../../types/FilterValues";
import TablePlaceholder from "../../../../components/TablePlaceholder/TablePlaceholder";
import tableStyles from "../../../../css/ResponsiveTable.module.css";
import styles from "./CondensingUnitProductItem.module.css";
import ProductCard from "../../../../components/ProductCard/ProductCard";
import Pill from "../../../../components/Pill/Pill";

interface DisplayExceptionsProps {
  additional_cooling: boolean;
  on_edge: boolean;
}

const DisplayExceptions: FC<DisplayExceptionsProps> = ({
  additional_cooling,
  on_edge,
}) => {
  return (
    <div className={styles.exceptionContainer}>
      {additional_cooling && (
        <Pill
          text="Require external cooling"
          alertLevel="ERROR"
          imageFilename="thermostat_error"
        />
      )}
      {on_edge && (
        <Pill
          text="Near edge of operating limit"
          alertLevel="WARNING"
          imageFilename="error_outline"
        />
      )}
    </div>
  );
};

const CondensingUnitProductItem: FC<{
  products: CondensingUnitProductCapacity[];
  selectedCondenserUnit: CondensingUnitProductCapacity | undefined;
  handleCondenserUnitSelect: Function;
  condensingUnitFilters: ProductFilter[];
  filterSelections: FilterValues;
  setFilterValues: (filterType: string, value: string) => void;
  resetCondensingUnitFilters: () => void;
}> = ({
  products,
  handleCondenserUnitSelect,
  selectedCondenserUnit,
  condensingUnitFilters,
  filterSelections,
  setFilterValues,
  resetCondensingUnitFilters,
}) => {
  const handleSelect = useCallback(
    (product: CondensingUnitProductCapacity, event) => {
      event.preventDefault();
      handleCondenserUnitSelect(product);
    },
    [handleCondenserUnitSelect]
  );

  products.sort((a, b) => {
    return a.capacity - b.capacity;
  });

  return (
    <div>
      <Filters
        filters={condensingUnitFilters}
        filterValues={filterSelections}
        setFilterValues={setFilterValues}
        handleReset={resetCondensingUnitFilters}
      />
      {products.length === 0 && (
        <TablePlaceholder
          heading="No results"
          subheading="Update details to see matching products"
        />
      )}
      {products.length > 0 && (
        <>
          <div className={tableStyles.tableContainer}>
            <Table>
              <Header>
                <Row>
                  <HeaderCell textAlign="center" width={15}>
                    Image
                  </HeaderCell>
                  <HeaderCell width={15}>Model</HeaderCell>
                  <HeaderCell width={15}>Product code</HeaderCell>
                  <HeaderCell textAlign="center" width={15}>
                    Product range
                  </HeaderCell>
                  <HeaderCell textAlign="center" width={15}>
                    Operating capacity (W)
                  </HeaderCell>
                  <HeaderCell textAlign="center" width={10}>
                    Fan phase
                  </HeaderCell>
                  <HeaderCell textAlign="center" width={15}>
                    Electrical phase
                  </HeaderCell>
                </Row>
              </Header>
              <Body>
                {products.map((item) => {
                  let hasException = item.additional_cooling || item.on_edge;

                  return (
                    <Fragment key={item.model}>
                      <Row
                        selected={
                          selectedCondenserUnit !== undefined &&
                          selectedCondenserUnit.model === item.model
                        }
                        onClick={(e) => handleSelect(item, e)}
                      >
                        {!hasException && (
                          <>
                            <Cell width={15} textAlign="center">
                              <Thumbnail thumbnailUrl={item.thumbnail} />
                            </Cell>
                            <Cell width={15}>{item.model}</Cell>
                            <Cell width={15}>{item.product_code}</Cell>
                            <Cell
                              width={15}
                              dataTestId="product_range"
                              textAlign="center"
                            >
                              {item.series}
                            </Cell>
                            <Cell
                              width={15}
                              dataTestId="operating_capacity"
                              textAlign="center"
                            >
                              {Math.round(item.capacity)}
                            </Cell>
                            <Cell
                              width={10}
                              dataTestId="fan_phase"
                              textAlign="center"
                            >
                              {item.fan_phase}
                            </Cell>
                            <Cell
                              width={15}
                              dataTestId="electrical_phase"
                              textAlign="center"
                            >
                              {item.electrical_phase}
                            </Cell>
                          </>
                        )}
                        {hasException && (
                          <Cell columnSpan={7} cellStyle="nopadding">
                            <TableNoBorders>
                              <Body>
                                <Row>
                                  <Cell
                                    rowSpan={2}
                                    cellStyle="noborder"
                                    width={15}
                                    textAlign="center"
                                  >
                                    <Thumbnail thumbnailUrl={item.thumbnail} />
                                  </Cell>
                                  <Cell cellStyle="noborder" width={15}>
                                    {item.model}
                                  </Cell>
                                  <Cell cellStyle="noborder" width={15}>
                                    {item.product_code}
                                  </Cell>
                                  <Cell
                                    cellStyle="noborder"
                                    width={15}
                                    dataTestId="product_range"
                                    textAlign="center"
                                  >
                                    {item.series}
                                  </Cell>
                                  <Cell
                                    cellStyle="noborder"
                                    width={15}
                                    dataTestId="operating_capacity"
                                    textAlign="center"
                                  >
                                    {Math.round(item.capacity)}
                                  </Cell>
                                  <Cell
                                    cellStyle="noborder"
                                    width={10}
                                    dataTestId="fan_phase"
                                    textAlign="center"
                                  >
                                    {item.fan_phase}
                                  </Cell>
                                  <Cell
                                    cellStyle="noborder"
                                    width={15}
                                    dataTestId="electrical_phase"
                                    textAlign="center"
                                  >
                                    {item.electrical_phase}
                                  </Cell>
                                </Row>
                                <Row>
                                  <Cell columnSpan={6} cellStyle="noborder">
                                    <DisplayExceptions
                                      additional_cooling={
                                        item.additional_cooling
                                      }
                                      on_edge={item.on_edge}
                                    />
                                  </Cell>
                                </Row>
                              </Body>
                            </TableNoBorders>
                          </Cell>
                        )}
                      </Row>
                    </Fragment>
                  );
                })}
              </Body>
            </Table>
          </div>
          <div className={tableStyles.gridContainer}>
            {products.map((item) => {
              let hasException = item.additional_cooling || item.on_edge;
              let exceptions = hasException ? (
                <>
                  <DisplayExceptions
                    additional_cooling={item.additional_cooling}
                    on_edge={item.on_edge}
                  />
                </>
              ) : (
                <></>
              );
              let productDescriptionValues = [
                {
                  label: "Product code",
                  value: item.product_code,
                },
                { label: "Product range", value: item.series },
                {
                  label: "Operating capacity",
                  value: Math.round(item.capacity).toString(),
                },
                { label: "Fan phase", value: item.fan_phase },
                {
                  label: "Electrical phase",
                  value: item.electrical_phase,
                },
              ];

              return (
                <ProductCard
                  title={item.model}
                  thumbnail={item.thumbnail}
                  productDescriptionValues={productDescriptionValues}
                  key={item.model}
                  additionalInfo={exceptions}
                  linkId={item.product_code !== null ? item.product_code : ""}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default CondensingUnitProductItem;
