import { ProductCapacity } from "../types/ProductCapacity";
import { ThumbnailValue } from "../types/ThumbnailValue";

export const enrichProductsWithThumbnailValue = <T extends ProductCapacity>(
  products: T[],
  thumbnailValue: ThumbnailValue
): T[] => {
  let productsWithoutThumbnails: T[] = products.map((products) => ({
    ...products,
    thumbnail: thumbnailValue,
  }));
  return productsWithoutThumbnails;
};
