import React, { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout/Layout";
import Section from "../../components/Section/Section";
import { SearchStatus } from "../../types/SearchStatus";
import useFilters from "../hooks/useFilters";
import { CondensingUnitPerformance } from "../sections/CondensingUnitPerformance/CondensingUnitPerformance";
import CondensingUnitSearchForm from "../sections/CondensingUnitSearchForm/CondensingUnitSearchForm";
import CondensingUnitSelectionContainer from "../sections/CondensingUnitSelectionContainer/CondensingUnitSelectionContainter";
import ResultInformation from "../sections/ResultInformation/ResultInformation";
import SearchTypeSelection from "../sections/SearchTypeSelection/SearchTypeSelection";
import styles from "./ProductSelection.module.css";
import layoutStyles from "../../components/Layout/Layout.module.css";
import { default as actionSelector } from "../duck/selectors";
import SelectedCondensingUnitContainer from "../sections/SelectedCondensingUnit/SelectedCondensingUnitContainer";
import CondensingUnitPrint from "../sections/EquipmentSelectionPrint/CondensingUnitPrint";

const CondensingUnitSearch: FC<{}> = () => {
  const dispatch = useDispatch();
  const refEquipmentSelection = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    return () => {
      dispatch(actionSelector.clearCondensingUnitSearch());
    };
  }, [dispatch]);

  const scrollToEquipmentSelection = () => {
    if (null !== refEquipmentSelection.current) {
      window.scrollTo({
        top: refEquipmentSelection.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useFilters();

  const condenserUnitSearchStatus: SearchStatus = useSelector(
    (state: any) => state.product.singleCondensingUnitSearchStatus
  );

  return (
    <Layout>
      <SearchTypeSelection defaultValue="/condensing-unit-search" />

      <div className={`hideSectionForPrint ${layoutStyles.contentContainer}`}>
        <Section>
          <CondensingUnitSearchForm
            scrollToEquipmentSelection={scrollToEquipmentSelection}
          />
          <div className={styles.equipmentSelectionGrid}>
            <CondensingUnitSelectionContainer ref={refEquipmentSelection} />
            <aside className={styles.equipmentSelectionAside}>
              <CondensingUnitPerformance />
              <ResultInformation
                condensingUnitSearchStatus={condenserUnitSearchStatus}
              />
              <SelectedCondensingUnitContainer />
            </aside>
          </div>
        </Section>
      </div>

      <CondensingUnitPrint />
    </Layout>
  );
};

export default CondensingUnitSearch;
