import React, { FC } from "react";
import { HeatLoadTotalCapacity } from "../../types/HeatLoadCapacity";
import styles from "./CalculationSummary.module.css";

const CalculationSummaryTable: FC<{
  heatLoadSummary: HeatLoadTotalCapacity;
  roomTemperature: number;
}> = ({ heatLoadSummary, roomTemperature }) => {
  let totalHeatLoad =
    heatLoadSummary.totalLoad.sensible +
    heatLoadSummary.totalLoad.latent +
    heatLoadSummary.otherLoad.total;

  return (
    <>
      {totalHeatLoad > 0 && (
        <>
          <div
            className={`${styles.loadTypeContainer} ${styles.loadTypeHeading}`}
          >
            <div className={styles.alignLeft}>Load type</div>
            <div>Sensible</div>
            <div>Latent</div>
          </div>
          <div className={styles.loadTypeContainer}>
            <div className={styles.alignLeft}>
              <span className={`${styles.circle} ${styles.orangeCircle}`}>
                &nbsp;
              </span>
              Transmission
            </div>
            <div>{heatLoadSummary.transmissionLoad.sensible.toFixed(0)} W</div>
            <div>{heatLoadSummary.transmissionLoad.latent.toFixed(0)} W</div>
            <div className={styles.alignLeft}>
              <span className={`${styles.circle} ${styles.greenCircle}`}>
                &nbsp;
              </span>
              Infiltration
            </div>
            <div>{heatLoadSummary.infiltrationLoad.sensible.toFixed(0)} W</div>
            <div>{heatLoadSummary.infiltrationLoad.latent.toFixed(0)} W</div>
            <div className={styles.alignLeft}>
              <span className={`${styles.circle} ${styles.purpleCircle}`}>
                &nbsp;
              </span>
              Product
            </div>
            <div>{heatLoadSummary.productLoad.sensible.toFixed(0)} W</div>
            <div>{heatLoadSummary.productLoad.latent.toFixed(0)} W</div>
            <div className={styles.alignLeft}>
              <span className={`${styles.circle} ${styles.blueCircle}`}>
                &nbsp;
              </span>
              Additional loads
            </div>
            <div>{heatLoadSummary.internalLoad.sensible.toFixed(0)} W</div>
            <div>{heatLoadSummary.internalLoad.latent.toFixed(0)} W</div>
            <div className={styles.alignLeft}>
              <span className={`${styles.circle} ${styles.pinkCircle}`}>
                &nbsp;
              </span>
              Other misc. loads
            </div>
            <div data-testid="otherHeatLoad">
              {heatLoadSummary.otherLoad.total.toFixed(0)} W
            </div>
            <div>0 W</div>
          </div>
          <div
            className={`${styles.loadTypeContainer} ${styles.loadTypeTotals}`}
          >
            <div className={styles.spanTwoColumns}>Subtotal loads</div>
            <div data-testid="totalHeatLoad">{totalHeatLoad.toFixed(0)} W</div>
          </div>
          <div className={styles.safetyContainer}>
            <div className={styles.totalsLabel}>
              <span className={`${styles.circle} ${styles.yellowCircle}`}>
                &nbsp;
              </span>
              Safety ({heatLoadSummary.safetyLoad.safetyFactor}%)
            </div>
            <div data-testid="safetyHeatLoad" className={styles.totalsValue}>
              {heatLoadSummary.safetyLoad.total.toFixed(0)} W
            </div>
          </div>
          <div
            className={`${styles.loadTypeContainer} ${styles.loadTypeTotals}`}
          >
            <div className={styles.spanTwoColumns}>Total</div>
            <div data-testid="total">
              {(heatLoadSummary.safetyLoad.total + totalHeatLoad).toFixed(0)} W
            </div>
            <div className={styles.spanTwoColumns}>Total - time adjusted</div>
            <div data-testid="runtimeTotal">
              {heatLoadSummary.runTimeTotal.total.toFixed(0)} W
            </div>
          </div>
          <div className={styles.safetyContainer}>
            <div className={styles.totalsLabel}>
              <span className={`${styles.circle} ${styles.redCircle}`}>
                &nbsp;
              </span>
              Fan{roomTemperature <= 0 ? " and heater" : ""} load (
              {heatLoadSummary.fanLoad.fanFactor}%)
            </div>
            <div data-testid="fanLoad" className={styles.totalsValue}>
              {heatLoadSummary.fanLoad.fanLoad.toFixed(0)} W
            </div>
          </div>
          <div className={styles.finalTotalContainer}>
            <div className={`${styles.totalsLabel} ${styles.finalTotals}`}>
              Required capacity{" "}
              <span className={styles.runtimePrintDisplay}>
                @ {heatLoadSummary.runTimeTotal.runtime} hr runtime
              </span>
            </div>
            <div
              data-testid="totalWithFanLoad"
              className={`${styles.totalsValue} ${styles.finalTotalValue}`}
            >
              {heatLoadSummary.fanLoad.total.toFixed(0)} W
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CalculationSummaryTable;
