import React, { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout/Layout";
import Section from "../../components/Section/Section";
import { SearchStatus } from "../../types/SearchStatus";
import useFilters from "../hooks/useFilters";
import { EvaporatorPerformance } from "../sections/EvaporatorPerformance/EvaporatorPerformance";
import EvaporatorSearchForm from "../sections/EvaporatorSearchForm/EvaporatorSearchForm";
import EvaporatorSelectionContainer from "../sections/EvaporatorSelectionContainer/EvaporatorSelectionContainer";
import ResultInformation from "../sections/ResultInformation/ResultInformation";
import SearchTypeSelection from "../sections/SearchTypeSelection/SearchTypeSelection";
import { default as actionSelector } from "../duck/selectors";
import styles from "./ProductSelection.module.css";
import layoutStyles from "../../components/Layout/Layout.module.css";
import SelectedEvaporatorContainer from "../sections/SelectedEvaporator/SelectedEvaporatorContainer";
import EvaporatorPrint from "../sections/EquipmentSelectionPrint/EvaporatorPrint";

const EvaporatorSearch: FC<{}> = () => {
  const dispatch = useDispatch();
  const refEquipmentSelection = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    return () => {
      dispatch(actionSelector.clearEvaporatorSearch());
    };
  }, [dispatch]);

  const scrollToEquipmentSelection = () => {
    if (null !== refEquipmentSelection.current) {
      window.scrollTo({
        top: refEquipmentSelection.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useFilters();

  const evaporatorSearchStatus: SearchStatus = useSelector(
    (state: any) => state.product.singleEvaporatorSearchStatus
  );

  return (
    <Layout>
      <SearchTypeSelection defaultValue="/evaporator-search" />

      <div className={`hideSectionForPrint ${layoutStyles.contentContainer}`}>
        <Section>
          <EvaporatorSearchForm
            scrollToEquipmentSelection={scrollToEquipmentSelection}
          />
          <div className={styles.equipmentSelectionGrid}>
            <EvaporatorSelectionContainer ref={refEquipmentSelection} />

            <aside className={styles.equipmentSelectionAside}>
              <EvaporatorPerformance />
              <ResultInformation
                evaporatorSearchStatus={evaporatorSearchStatus}
              />
              <SelectedEvaporatorContainer />
            </aside>
          </div>
        </Section>
      </div>

      <EvaporatorPrint />
    </Layout>
  );
};

export default EvaporatorSearch;
