interface StandardsRating {
  liquidSubcooling: number;
  compressorInletSuperheat: number;
}
interface StandardsRatingMap {
  [key: string]: StandardsRating;
}

const calculateSuperheatAndLiquidSubcooling = (
  standardsRating: string,
  evaporatorTemperature: number
): { liquidSubcooling: number; compressorInletSuperheat: number } => {
  let standards: StandardsRatingMap = {
    DA12: { liquidSubcooling: 0, compressorInletSuperheat: 15 },
    EN12900: {
      liquidSubcooling: 0,
      compressorInletSuperheat: 20 - evaporatorTemperature,
    },
    EN13215: {
      liquidSubcooling: 3,
      compressorInletSuperheat: 20 - evaporatorTemperature,
    },
    DEFAULT: { liquidSubcooling: 2, compressorInletSuperheat: 15 },
  };

  if (standardsRating in standards) {
    return standards[standardsRating];
  }

  return standards["DEFAULT"];
};

export default calculateSuperheatAndLiquidSubcooling;
