import React from "react";
import CommonInfoSection from "../../components/CommonInfoSection/CommonInfoSection";
import {
  DesignConditions,
  PipeSizeCalculations,
  UnitTypes,
} from "../../types/PipeSizeCalculationResponse";
import SelectedPipes from "./SelectedPipes";

interface AsideSectionProps {
  designConditions: DesignConditions;
  unitTypes: UnitTypes;
  pipeGaugeLength: number;
  selectedLiquidLineHorizontalPipe: PipeSizeCalculations;
  selectedSuctionLineHorizontalPipe: PipeSizeCalculations;
  selectedDischargeLineHorizontalPipe: PipeSizeCalculations;
  selectedLiquidLineVerticalPipe: PipeSizeCalculations;
  selectedSuctionLineVerticalPipe: PipeSizeCalculations;
  selectedDischargeLineVerticalPipe: PipeSizeCalculations;
  selectedPressureDropUom: string;
  isLoading: boolean;
}

const AsideSection: React.FC<AsideSectionProps> = ({
  designConditions,
  unitTypes,
  pipeGaugeLength,
  selectedLiquidLineHorizontalPipe,
  selectedSuctionLineHorizontalPipe,
  selectedDischargeLineHorizontalPipe,
  selectedLiquidLineVerticalPipe,
  selectedSuctionLineVerticalPipe,
  selectedDischargeLineVerticalPipe,
  selectedPressureDropUom,
  isLoading,
}) => {
  const commonInfo = [
    {
      labelText: "Density",
      value: pipeGaugeLength !== 0 ? designConditions.density : 0,
      uom: unitTypes["density"],
    },
    {
      labelText: "Flow rate",
      value: pipeGaugeLength !== 0 ? designConditions.flow_rate : 0,
      uom: unitTypes["flow_rate"],
    },
    {
      labelText: "Absolute viscosity",
      value: pipeGaugeLength !== 0 ? designConditions.viscosity : 0,
      uom: unitTypes["viscosity"],
    },
    {
      labelText: "Safe working pressure",
      value: pipeGaugeLength !== 0 ? designConditions.swp : 0,
      uom: unitTypes["swp"],
    },
  ];

  // valid only for liquid line
  if (designConditions.max_height_before_flash) {
    commonInfo.push({
      labelText: "Flashing height",
      value:
        pipeGaugeLength !== 0 ? designConditions.max_height_before_flash : 0,
      uom: unitTypes["max_height_before_flash"],
    });
  }

  return (
    <>
      <CommonInfoSection
        header="Refrigerant properties"
        commonInfo={commonInfo}
      />
      <SelectedPipes
        selectedLiquidLineHorizontalPipe={selectedLiquidLineHorizontalPipe}
        selectedSuctionLineHorizontalPipe={selectedSuctionLineHorizontalPipe}
        selectedDischargeLineHorizontalPipe={
          selectedDischargeLineHorizontalPipe
        }
        selectedLiquidLineVerticalPipe={selectedLiquidLineVerticalPipe}
        selectedSuctionLineVerticalPipe={selectedSuctionLineVerticalPipe}
        selectedDischargeLineVerticalPipe={selectedDischargeLineVerticalPipe}
        selectedPressureDropUom={selectedPressureDropUom}
        isLoading={isLoading}
        unitTypes={unitTypes}
      />
    </>
  );
};

export default AsideSection;
