import React, { forwardRef, useCallback, useEffect } from "react";
import CondensingUnitProductItemContainer from "../EquipmentSelection/CondensingUnitProductItem/CondensingUnitProductItemContainer";
import useSingleCondensingUnitService from "../../hooks/useSingleCondensingUnitService";
import { CondensingUnitProductCapacity } from "../../../types/CondensingUnitProductCapacity";
import { default as actionSelector } from "../../duck/selectors";
import { useDispatch, useSelector } from "react-redux";
import styles from "../EquipmentSelection/EquipmentSelectionContainer.module.css";
import { findBestMatchWithImage } from "../../../helpers/findBestMatchWithImage";

const CondensingUnitSelectionContainer = forwardRef<HTMLHeadingElement, {}>(
  (_, ref) => {
    const dispatch = useDispatch();
    const { condenserUnitService } = useSingleCondensingUnitService();

    const selectCondensingUnit = useCallback(
      (selectedCondensingUnit: CondensingUnitProductCapacity) => {
        dispatch(actionSelector.loadingBalancePerformance(true));
        dispatch(
          actionSelector.selectSingleCondensingUnit(selectedCondensingUnit)
        );

        setTimeout(() => {
          dispatch(actionSelector.loadingBalancePerformance(false));
        }, 200);
      },
      [dispatch]
    );

    useEffect(() => {
      if (
        condenserUnitService.status === "loaded" &&
        condenserUnitService.payload.results.length
      ) {
        let bestMatch = findBestMatchWithImage(
          condenserUnitService.payload.results,
          condenserUnitService.payload.best_match
        );
        if (bestMatch !== undefined) {
          selectCondensingUnit(bestMatch as CondensingUnitProductCapacity);
        }
      }

      if (condenserUnitService.status === "loaded") {
        dispatch(
          actionSelector.setSingleCondensingUnitSearchStatus(
            condenserUnitService.payload.search_status
          )
        );
      } else {
        dispatch(
          actionSelector.setSingleCondensingUnitSearchStatus("NOT_FOUND")
        );
      }
    }, [condenserUnitService, selectCondensingUnit, dispatch]);

    const selectedCondensingUnit: CondensingUnitProductCapacity = useSelector(
      (state: any) => state.product.selectedSingleCondensingUnit
    );

    return (
      <section>
        <div ref={ref} className={styles.resultsContainer}>
          <CondensingUnitProductItemContainer
            condenserUnitService={condenserUnitService}
            handleCondenserUnitSelect={selectCondensingUnit}
            selectedCondensingUnit={selectedCondensingUnit}
          />
        </div>
      </section>
    );
  }
);

export default CondensingUnitSelectionContainer;
