import { FieldError, RegisterOptions } from "react-hook-form";

interface TransmissionDetailsDirtyFields {
  wallsAreTheSame?: boolean | undefined;
  walls?:
    | {
        wallId?: boolean | undefined;
        material?: boolean | undefined;
        thickness?: boolean | undefined;
        ambientTemp?: boolean | undefined;
      }[]
    | undefined;
  ceiling?:
    | {
        material?: boolean | undefined;
        thickness?: boolean | undefined;
        ambientTemp?: boolean | undefined;
      }
    | undefined;
  floor?:
    | {
        material?: boolean | undefined;
        thickness?: boolean | undefined;
        groundTemp?: boolean | undefined;
        hasInsulation?: boolean | undefined;
        insulationMaterial?: boolean | undefined;
        insulationThickness?: boolean | undefined;
        heating?: boolean | undefined;
        customWattage?: boolean | undefined;
      }
    | undefined;
}

interface TransmissionDetailsErrors {
  wallsAreTheSame?: FieldError | undefined;
  walls?:
    | {
        wallId?: FieldError | undefined;
        material?: FieldError | undefined;
        thickness?: FieldError | undefined;
        ambientTemp?: FieldError | undefined;
      }[]
    | undefined;
  ceiling?:
    | {
        material?: FieldError | undefined;
        thickness?: FieldError | undefined;
        ambientTemp?: FieldError | undefined;
      }
    | undefined;
  floor?:
    | {
        material?: FieldError | undefined;
        thickness?: FieldError | undefined;
        groundTemp?: FieldError | undefined;
        hasInsulation?: FieldError | undefined;
        insulationMaterial?: FieldError | undefined;
        insulationThickness?: FieldError | undefined;
        heating?: FieldError | undefined;
        customWattage?: FieldError | undefined;
      }
    | undefined;
}

const wallThicknessRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 30,
    message: "Must be between 30mm and 250mm",
  },
  max: {
    value: 250,
    message: "Must be between 30mm and 250mm",
  },
  valueAsNumber: true,
};

const floorHeatingRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 1,
    message: "Must be between 1W/m² and 100W/m²",
  },
  max: {
    value: 100,
    message: "Must be between 1W/m² and 100W/m²",
  },
  valueAsNumber: true,
};

const surfaceTempRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^-?\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: -40,
    message: "Must be between -40\u00b0 and +60\u00b0 Celsius",
  },
  max: {
    value: 60,
    message: "Must be between -40\u00b0 and +60\u00b0 Celsius",
  },
  valueAsNumber: true,
};

interface WallDetails {
  wallId?: string | undefined;
  material?: string | undefined;
  thickness?: number | undefined;
  ambientTemp?: number | undefined;
}

const wallErrors = (
  errors: TransmissionDetailsErrors,
  index: number
): boolean => {
  let wallsError = errors.walls;
  return wallsError !== undefined && wallsError[index] !== undefined;
};

const validateWalls = (
  errors: TransmissionDetailsErrors,
  i: number
): boolean => {
  return !wallErrors(errors, i);
};

const validateAllWallsEntered = (
  walls: WallDetails[],
  errors: TransmissionDetailsErrors
): boolean => {
  let allWallsValid = walls.every((wall, index) =>
    validateWalls(errors, index)
  );

  return allWallsValid;
};

function ceilingErrors(errors: TransmissionDetailsErrors) {
  let ceiling = errors.ceiling;
  return (
    !ceiling ||
    (!ceiling.material && !ceiling.thickness && !ceiling.ambientTemp)
  );
}

const validateCeiling = (errors: TransmissionDetailsErrors) => {
  return ceilingErrors(errors);
};

function floorErrors(errors: TransmissionDetailsErrors) {
  let floorErrors = errors.floor;
  return (
    !floorErrors ||
    (!floorErrors.material &&
      !floorErrors.thickness &&
      !floorErrors.groundTemp &&
      !floorErrors.insulationMaterial &&
      !floorErrors.insulationThickness)
  );
}

const validateFloor = (errors: TransmissionDetailsErrors) => {
  return floorErrors(errors);
};

export {
  wallThicknessRules,
  floorHeatingRules,
  surfaceTempRules,
  validateFloor,
  validateCeiling,
  validateWalls,
  validateAllWallsEntered,
  wallErrors,
};
