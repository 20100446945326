import React, { FC } from "react";
import PrintSectionHeader from "../../../components/PrintTemplate/PrintSectionHeader";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";
import isAnEmptyObject from "../../../helpers/emptyObject";
import { EvaporatorProductCapacity } from "../../../types/EvaporatorProductCapacity";
import { EvaporatorSearchRequest } from "../../../types/EvaporatorSearchRequest";

const EvaporatorPerformanceData: FC<{
  selectedEvaporator: EvaporatorProductCapacity;
  evaporatorSearchRequest: EvaporatorSearchRequest;
}> = ({ selectedEvaporator, evaporatorSearchRequest }) => {
  let capacity: number = !isAnEmptyObject(selectedEvaporator)
    ? selectedEvaporator.operating_capacity.capacity
    : 0;
  let tdAtCapacity =
    (evaporatorSearchRequest.desiredEvaporatorCapacity *
      evaporatorSearchRequest.td) /
    capacity;
  let requestedSst =
    evaporatorSearchRequest.roomTemp - evaporatorSearchRequest.td;

  return (
    <>
      <PrintSectionHeader>Performance data</PrintSectionHeader>

      <table className={styles.tablePrint}>
        <tbody>
          <tr>
            <td width="50%">
              Temperature difference (TD) at required capacity
            </td>
            <td width="50%">{tdAtCapacity.toFixed(2)} K</td>
          </tr>
          <tr>
            <td width="50%">Requested saturated suction temp. (SST)</td>
            <td width="50%">{requestedSst}°C</td>
          </tr>
          <tr>
            <td width="50%">Capacity</td>
            <td width="50%">{capacity} W</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default EvaporatorPerformanceData;
