import React, { FC } from "react";
import PrintTemplate from "../../../components/PrintTemplate/PrintTemplate";
import PipeSizeInputs from "./PipeSizeInputs";
import SelectedPipesOutput from "./SelectedPipesOutput";

const PipeSizePrint: FC<{ uomPressure: string }> = ({ uomPressure }) => {
  return (
    <PrintTemplate pageTitle="Pipe selection">
      <div className="page-break">
        <div className="page-header">Pipe size calculation</div>

        <PipeSizeInputs />

        <div className="page-header">Pipe size calculation</div>

        <SelectedPipesOutput uomPressure={uomPressure} />
      </div>
    </PrintTemplate>
  );
};

export default PipeSizePrint;
