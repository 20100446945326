import React from "react";

export interface ImageProps {
  filename: string;
  altText: string;
  className?: string;
}

const Image: React.FC<ImageProps> = ({ filename, altText, className }) => {
  const imageFile = require(`../../assets/${filename}.svg`);

  return <img src={imageFile} alt={altText} className={className} />;
};

export default Image;
