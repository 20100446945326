import React, { FC } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { HeatLoadTotalCapacity } from "../../types/HeatLoadCapacity";
import styles from "./CircleChart.module.css";

const COLORS = [
  "var(--trs-purple-30)",
  "var(--trs-orange-60)",
  "var(--trs-green-30)",
  "var(--trs-blue-40)",
  "var(--trs-yellow-60)",
  "#ba312b",
  "var(--info-soft-pink)",
];

const CircleChart: FC<{
  totalLoadCapacity: HeatLoadTotalCapacity;
}> = ({ totalLoadCapacity, children }) => {
  const data = [
    {
      name: "Product",
      value:
        0.01 +
        totalLoadCapacity.productLoad.latent +
        totalLoadCapacity.productLoad.sensible,
    },
    {
      name: "Transmission",
      value:
        0.01 +
        totalLoadCapacity.transmissionLoad.latent +
        totalLoadCapacity.transmissionLoad.sensible,
    },
    {
      name: "Infiltration",
      value:
        0.01 +
        totalLoadCapacity.infiltrationLoad.latent +
        totalLoadCapacity.infiltrationLoad.sensible,
    },
    {
      name: "Additional loads",
      value:
        0.01 +
        totalLoadCapacity.internalLoad.latent +
        totalLoadCapacity.internalLoad.sensible,
    },
    {
      name: `Safety ${totalLoadCapacity.safetyLoad.safetyFactor}`,
      value: 0.01 + totalLoadCapacity.safetyLoad.total,
    },
    {
      name: `Fan load ${totalLoadCapacity.fanLoad.fanFactor}`,
      value: 0.01 + totalLoadCapacity.fanLoad.fanLoad,
    },
    {
      name: "Other misc. loads",
      value: 0.01 + totalLoadCapacity.otherLoad.total,
    },
  ];
  return (
    <div className={styles.circleContainer}>
      <PieChart width={380} height={380}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={115}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      {children}
    </div>
  );
};

export default CircleChart;
