import React, { FC } from "react";
import styles from "./PrintHeader.module.css";
import logo from "../../assets/actrol.svg";
import veLogo from "../../assets/AVElogo.svg";

const PrintHeader: FC<{ pageTitle: string }> = ({ pageTitle }) => {
  let givenName = sessionStorage.getItem("ve_user_given_name");
  let email = sessionStorage.getItem("ve_user_email");
  let today = new Date().toLocaleDateString();
  const lastNameFromEmail = email?.slice(
    email.indexOf(".") + 1,
    email.indexOf("@")
  );
  const convertedLastName =
    lastNameFromEmail &&
    lastNameFromEmail.charAt(0).toUpperCase() +
      lastNameFromEmail.slice(1).toLowerCase();

  return (
    <div className={styles.printHeaderContainer}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Actrol logo" />
          <br />
          <img src={veLogo} alt="Actrol Virtual Engineer logo" />
        </div>
        <div className={styles.printTitle}>
          <div className={styles.titleHeaderTextContainer}>
            <div className={styles.titleHeader}>{pageTitle}</div>
            <div className={styles.titleInfoText}>
              Prepared by: {givenName} {convertedLastName}
              <br />
              Date: {today}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintHeader;
