import React, { FC } from "react";
import { ProductLine } from "../../types/products";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";

const ProductsOutput: FC<{ productLines: ProductLine[] }> = ({
  productLines,
}) => {
  return (
    <>
      {productLines &&
        productLines.length > 0 &&
        productLines.map((p, i) => {
          return (
            <table className={styles.tableHalfWidth} key={i}>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <strong>{p.product.name}</strong>
                  </td>
                </tr>
                <tr>
                  <td width="40%" className={styles.indentTableCell}>
                    Amount
                  </td>
                  <td width="60%">{p.massFlow} kg</td>
                </tr>
                <tr>
                  <td width="40%" className={styles.indentTableCell}>
                    Entering temp.
                  </td>
                  <td width="60%">{p.temperatureIn}°C</td>
                </tr>
                <tr>
                  <td width="40%" className={styles.indentTableCell}>
                    Storage temp.
                  </td>
                  <td width="60%">{p.temperatureOut}°C</td>
                </tr>
                <tr>
                  <td width="40%" className={styles.indentTableCell}>
                    Pulldown time
                  </td>
                  <td width="60%">{p.pullDownTime} hrs</td>
                </tr>
              </tbody>
            </table>
          );
        })}
    </>
  );
};

export default ProductsOutput;
