import React from "react";
import styles from "./Error.module.css";
import Heading from "../Headings/Headings";
import Image from "../Image/Image";

const Error: React.FC = () => (
  <div className={styles.error}>
    <div>
      <Image filename="system_error" altText="An error has occurred" />
      <Heading>Something's leaked</Heading>
      <p>
        We're not sure what happened. <br />
        Please go back and try again.
      </p>
    </div>
  </div>
);

export default Error;
