import { RegisterOptions } from "react-hook-form";

const loadCapacityRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Must be greater than or equal to 0",
  },
  max: {
    value: 999000,
    message: "Must be less than or equal to 999000",
  },
  valueAsNumber: true,
};

const ambientRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 20,
    message: "Must be greater than or equal to 20",
  },
  max: {
    value: 43,
    message: "Must be less than or equal to 43",
  },
  valueAsNumber: true,
};

const tempDiffRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 3,
    message: "Must be greater than or equal to 3",
  },
  max: {
    value: 12,
    message: "Must be less than or equal to 12",
  },
  valueAsNumber: true,
};

const humidityRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 50,
    message: "Must be greater than or equal to 50",
  },
  max: {
    value: 99,
    message: "Must be less than or equal to 99",
  },
  valueAsNumber: true,
};

const roomTempRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^-?\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: -40,
    message: "Must be greater than or equal to -40",
  },
  max: {
    value: 20,
    message: "Must be less than or equal to 20",
  },
  valueAsNumber: true,
};

const runtimeRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Must be greater than or equal to 0",
  },
  max: {
    value: 24,
    message: "Must be less than or equal to 24",
  },
  valueAsNumber: true,
};

const noOfCondensingUnitsRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 1,
    message: "Must be greater than or equal to 1",
  },
  max: {
    value: 20,
    message: "Must be less than or equal to 20",
  },
  valueAsNumber: true,
};

const noOfEvaporatorUnitsRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 1,
    message: "Must be greater than or equal to 1",
  },
  max: {
    value: 20,
    message: "Must be less than or equal to 20",
  },
  valueAsNumber: true,
};

const liquidSubcoolingRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Must be between 0 and +15 Kelvin",
  },
  max: {
    value: 15,
    message: "Must be between 0 and +15 Kelvin",
  },
  valueAsNumber: true,
};

export {
  loadCapacityRules,
  ambientRules,
  tempDiffRules,
  humidityRules,
  roomTempRules,
  runtimeRules,
  noOfCondensingUnitsRules,
  noOfEvaporatorUnitsRules,
  liquidSubcoolingRules,
};
