import { useState } from "react";
import {
  FullPipeSizeCalculationResponse,
  PipeSizeCalculationResponse,
} from "../../types/PipeSizeCalculationResponse";
import { Service } from "../../types/Service";
import getUrl from "../../helpers/api";
import { PipeSizeCalculationRequest } from "../../types/PipeSizeCalculationRequest";

declare const window: any;

const usePipeCalculationResults = (): {
  pipeSizeCalculationService: Service<FullPipeSizeCalculationResponse>;
  calculatePipeSizeResults: (
    pipeCalcRequest: PipeSizeCalculationRequest
  ) => void;
} => {
  const [pipeSizeCalculationService, setPipeSizeCalculationService] = useState<
    Service<FullPipeSizeCalculationResponse>
  >({
    status: "init",
  });

  const ENDPOINT_PIPE_SIZE_CALCULATION = `${window.__runtime_configuration.ENDPOINT_PIPE_SIZE_CALCULATION}/call/pipe-flow`;

  const calculatePipeSizeResults = (
    pipeCalcRequest: PipeSizeCalculationRequest
  ) => {
    setPipeSizeCalculationService({ status: "loading" });
    let apiCalls: Promise<PipeSizeCalculationResponse>[] = [];

    if (
      pipeCalcRequest.horizontalLiquid > 0 ||
      pipeCalcRequest.verticalLiquid > 0
    ) {
      pipeCalcRequest.pipeLine = "liquid";
      apiCalls.push(calculateIndividualPipeSizeResults(pipeCalcRequest));
    }

    if (
      pipeCalcRequest.horizontalSuction > 0 ||
      pipeCalcRequest.verticalSuction > 0
    ) {
      pipeCalcRequest.pipeLine = "suction";
      apiCalls.push(calculateIndividualPipeSizeResults(pipeCalcRequest));
    }

    if (
      pipeCalcRequest.horizontalDischarge > 0 ||
      pipeCalcRequest.verticalDischarge > 0
    ) {
      pipeCalcRequest.pipeLine = "discharge";
      apiCalls.push(calculateIndividualPipeSizeResults(pipeCalcRequest));
    }

    Promise.all(apiCalls)
      .then((responses) => {
        setPipeSizeCalculationService({
          status: "loaded",
          payload: {
            liquid: { ...responses[0] },
            suction: { ...responses[1] },
            discharge: { ...responses[2] },
          },
        });
      })
      .catch((error) => {
        setPipeSizeCalculationService({ status: "error", error });
      });
  };

  const calculateIndividualPipeSizeResults = (
    pipeCalcRequest: PipeSizeCalculationRequest
  ) => {
    setPipeSizeCalculationService({ status: "loading" });

    let url = new URL(ENDPOINT_PIPE_SIZE_CALCULATION);

    let pipe_length =
      pipeCalcRequest.pipeLine === "liquid"
        ? pipeCalcRequest.horizontalLiquid
        : pipeCalcRequest.pipeLine === "suction"
        ? pipeCalcRequest.horizontalSuction
        : pipeCalcRequest.horizontalDischarge;
    let pipe_height =
      pipeCalcRequest.pipeLine === "liquid"
        ? pipeCalcRequest.verticalLiquid
        : pipeCalcRequest.pipeLine === "suction"
        ? pipeCalcRequest.verticalSuction
        : pipeCalcRequest.verticalDischarge;

    let params: { [index: string]: string | number } = {
      refrigerant: pipeCalcRequest.refrigerant,
      pipe_line: pipeCalcRequest.pipeLine,
      capacity: pipeCalcRequest.operatingCapacity,
      mid_or_dew: pipeCalcRequest.mid_or_dew,
      evaporator_temperature: pipeCalcRequest.saturatedSuctionTemp,
      condenser_temperature: pipeCalcRequest.saturatedCondensingTemp,
      suction_super_heat: pipeCalcRequest.compressorInletTemp,
      liquid_sub_cooling: pipeCalcRequest.liquidSubcooling,
      pipe_length: pipe_length,
      pipe_height: pipe_height,
    };

    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key].toString())
    );

    return getUrl<PipeSizeCalculationResponse>(url.href);
  };

  return {
    pipeSizeCalculationService,
    calculatePipeSizeResults,
  };
};

export default usePipeCalculationResults;
