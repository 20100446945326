import React from "react";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";
import styles from "./Filters.module.css";

const FiltersLoadingPlaceholder = () => (
  <div className={styles.secondaryFilterContainer}>
    <div className={styles.form}>
      <div className={styles.selectFieldContainer}>
        <div className={styles.loadingFilter}>
          <LoadingPlaceholder />
        </div>
      </div>
      <div className={styles.selectFieldContainer}>
        <div className={styles.loadingFilter}>
          <LoadingPlaceholder />
        </div>
      </div>
      <div className={styles.selectFieldContainer}>
        <div className={styles.loadingFilter}>
          <LoadingPlaceholder />
        </div>
      </div>
    </div>
  </div>
);

export default FiltersLoadingPlaceholder;
