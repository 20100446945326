import React, { FC, ReactElement, useEffect, useState } from "react";
import AccordionSection from "../AccordionSection/AccordionSection";

export interface OpenSectionsState {
  [key: string]: boolean;
}

export interface AccordionProps {
  defaultOpenSections?: OpenSectionsState;
  multiOpen: boolean;
  resetOpenSections?: OpenSectionsState;
  children: ReactElement<HTMLDivElement>[];
}

const Accordion: FC<AccordionProps> = ({
  defaultOpenSections,
  multiOpen,
  resetOpenSections,
  children,
}) => {
  const [openSections, setOpenSections] = useState<OpenSectionsState>(
    defaultOpenSections ?? {}
  );

  const onClickHandler = (id: string) => {
    const isOpen = !!openSections[id];

    setOpenSections(
      multiOpen ? { ...openSections, [id]: !isOpen } : { [id]: !isOpen }
    );
  };

  useEffect(() => {
    // If user wants to force an accordion(s) open, they can update the
    // resetOpenSections variable and trigger this reset
    if (resetOpenSections !== undefined) setOpenSections(resetOpenSections);
  }, [resetOpenSections]);

  return (
    <div>
      {children &&
        children.map((child) => (
          <AccordionSection
            isOpen={!!openSections[child.props.id]}
            id={child.props.id}
            title={child.props.title}
            onClickHandler={onClickHandler}
            key={child.props.id}
          >
            {child.props.children}
          </AccordionSection>
        ))}
    </div>
  );
};

export default Accordion;
