import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { AuthToken, readAuthToken } from "../helpers/applicationSession";

const isTokenValid = (authToken: AuthToken) => {
  return (
    authToken !== null &&
    authToken.idToke !== null &&
    authToken.accessToke !== null &&
    authToken.refreshToken !== null
  );
};

const getCognitoUserSession = (): CognitoUserSession | undefined => {
  let authToken = readAuthToken();

  if (isTokenValid(authToken)) {
    return new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: authToken.idToke }),
      AccessToken: new CognitoAccessToken({
        AccessToken: authToken.accessToke,
      }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: authToken.refreshToken,
      }),
    });
  }
};
// TODO: require test cases
const isValidCognitoUser = (): boolean => {
  let cognitoUserSession = getCognitoUserSession();

  if (cognitoUserSession) return cognitoUserSession.isValid();

  return false;
};

export { isValidCognitoUser };
