import { combineReducers } from "redux";
import productReducer from "./productSelection/duck/reducers";
import pipeReducer from "./pipeSizeCalculation/duck/reducers";
import heatLoadReducer from "./heatLoad/duck/reducers";

const rootReducer = combineReducers({
  product: productReducer,
  pipes: pipeReducer,
  heatLoad: heatLoadReducer,
});

export default rootReducer;
