import React, { FC, useEffect } from "react";
import Section from "../../components/Section/Section";
import styles from "./Login.module.css";
import logo from "../../assets/actrol.svg";
import Button from "../../components/Button/Button";
import cx from "classnames";
import { loginRedirect } from "../CognitoTokenService";
import { isValidCognitoUser } from "../CognitoUserPoolHelper";

declare const window: any;
const ENVIRONMENT = `${window.__runtime_configuration.ENVIRONMENT}`;

const goToSSO = () => {
  // redirect to SSO
  let ssoUrl = loginRedirect();
  window.location.assign(ssoUrl);
};

const goToDashboardIfLoggedIn = () => {
  window.location.assign("/dashboard");
};

const Login: FC = () => {
  useEffect(() => {
    if (isValidCognitoUser()) {
      goToDashboardIfLoggedIn();
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Section noPadding={true}>
          <div
            className={cx(
              styles.header,
              ENVIRONMENT === "TEST" && styles.testHeader,
              ENVIRONMENT === "STAGING" && styles.stagingHeader
            )}
          >
            <img src={logo} alt="Actrol logo" />
            <div className={styles.headerText}>Virtual Engineer</div>
            <p>Australia</p>
          </div>
          <div className={styles.contentBody}>
            <h5>Sign in</h5>

            <Button dataTestId="loginButton" onClickHandler={() => goToSSO()}>
              Sign in using SSO
            </Button>
          </div>
        </Section>
      </div>
    </div>
  );
};

export default Login;
