import Face, { defaultSurfaceEdgers } from "./Face";
import { LoadCapacity } from "./load/TotalHeatLoad";
export interface DoorAttributes {
  doorwayUseFactor: number;
  openingTime: number;
  doorwayOpeningFactor: number;
  effectiveness: number;
  uValue: number | undefined;
  conductivity: number | undefined;
  heatedFascia: number;
  heatedGlass: number;
}
export interface WallDoor {
  wallId: string;
  doorId: string;
}

export interface DoorModel {
  wallId: string;
  doorId: string | undefined;
  height: number;
  width: number;
  numberOfDoors: number;
  doorAttributes: DoorAttributes;
}

class Door extends Face {
  private readonly _height: number;
  private readonly _width: number;
  private _doorAttributes: DoorAttributes;
  private _airflow: number;

  constructor(id: string, height: number, width: number) {
    super(id, defaultSurfaceEdgers(height, width));
    this._height = height;
    this._width = width;
  }

  get height() {
    return this._height;
  }

  get width() {
    return this._width;
  }

  get perimeter() {
    return 2 * (this.width + this.height);
  }

  get area() {
    return this.width * this.height;
  }

  set doorAttributes(a: DoorAttributes) {
    this._doorAttributes = a;
  }

  get doorAttributes() {
    return this._doorAttributes;
  }

  set airflow(af: number) {
    this._airflow = af;
  }

  get airflow() {
    return this._airflow;
  }
}

class Opening {
  private _noOfDoors: number;
  private _door: Door;

  constructor(door: Door, noOfDoors: number) {
    this._door = door;
    this._noOfDoors = noOfDoors;
  }
  set noOfDoors(af: number) {
    this._noOfDoors = af;
  }

  get noOfDoors() {
    return this._noOfDoors;
  }

  set door(d: Door) {
    this._door = d;
  }

  get door() {
    return this._door;
  }

  transmissionLoad(room_temperature: number): LoadCapacity {
    let loadCapacity: LoadCapacity =
      this._door.transmissionLoad(room_temperature);
    return {
      sensible: loadCapacity.sensible * this.noOfDoors,
      latent: loadCapacity.latent * this.noOfDoors,
    };
  }
}

export { Door, Opening };
