import React, { FC } from "react";
import { ThumbnailValue } from "../../../types/ThumbnailValue";
import styles from "./SelectedEquipment.module.css";

const UNAVAILABLE_IMAGE_URL =
  "https://www.actrol.com.au/search/static/images/image-unavailable.jpg";

const ProductInfoPrint: FC<{
  thumbnail: ThumbnailValue | undefined;
  model: string;
  product_code: string | null;
  refrigerant: string;
  series: string;
  numberRequired: number;
}> = ({
  thumbnail,
  model,
  product_code,
  refrigerant,
  series,
  numberRequired,
}) => {
  return (
    <>
      <div className={styles.imageContainer}>
        <img
          src={thumbnail !== null ? thumbnail : UNAVAILABLE_IMAGE_URL}
          className={styles.image}
          alt="Product item thumbnail"
        />
      </div>
      <div className={styles.productTitle}>{model}</div>

      <table>
        <tbody>
          <tr>
            <td>Product code</td>
            <td>{product_code}</td>
          </tr>
          <tr>
            <td>Refrigerant</td>
            <td>{refrigerant}</td>
          </tr>
          <tr>
            <td>Series</td>
            <td>{series}</td>
          </tr>
          <tr>
            <td>No. required</td>
            <td>{numberRequired}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <a
                href={`https://actrol.com.au/product/-${product_code}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                More technical info
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ProductInfoPrint;
