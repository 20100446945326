import React, { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ProductFilter } from "../../../../types/ProductFilter";
import PeopleDetails from "./PeopleDetails";
import {
  validatePeopleDetails,
  validateVentilationDetails,
} from "./PeopleDetailsValidation";
import {
  PeopleDetails as PeopleDetailsModel,
  VentilationDetails,
} from "../../../types/Room";
import isNaNReturnDefault from "../../../../helpers/isNaNReturnNumber";
import actionSelector from "../../../duck/actions";
import { useDispatch, useSelector } from "react-redux";
import { EMPTY_FILTER } from "../../../hooks/ExtractHeatloadFilters";

export interface PeopleDetailsForm {
  numberOfPeople?: number;
  activity: string;
  numberOfHours?: number;
  ventilation: string;
}

const defaultValues: PeopleDetailsForm = {
  numberOfPeople: undefined,
  activity: "",
  ventilation: "none",
};

const PeopleDetailsContainer: FC = () => {
  const methods = useForm<PeopleDetailsForm>({
    defaultValues,
    mode: "onBlur",
  });
  const dispatch = useDispatch();
  const { watch, formState } = methods;
  const { errors, dirtyFields, isValidating } = formState;

  const peopleActivityFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.miscellaneous
      ? state.heatLoad.formHeatLoadFilters.miscellaneous["people_activity"]
      : EMPTY_FILTER
  );
  const ventilationFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.miscellaneous
      ? state.heatLoad.formHeatLoadFilters.miscellaneous["ventilation"]
      : EMPTY_FILTER
  );

  useEffect(() => {
    const peopleDetails = watch();

    if (
      isValidating &&
      peopleDetails &&
      peopleActivityFilter.options &&
      validatePeopleDetails(errors, dirtyFields)
    ) {
      let extendedValues;
      let option = peopleActivityFilter.options.find(
        (o) => o.key === peopleDetails.activity
      );
      if (option && "extended_values" in option) {
        extendedValues = option["extended_values"];
      }

      if (
        extendedValues !== undefined &&
        peopleDetails.numberOfPeople !== undefined &&
        peopleDetails.numberOfPeople >= 0 &&
        peopleDetails.numberOfHours !== undefined &&
        peopleDetails.numberOfHours >= 0
      ) {
        const people: PeopleDetailsModel = {
          peopleCount: isNaNReturnDefault(peopleDetails.numberOfPeople),
          activityLevel: peopleDetails.activity,
          activityLevelDisplay: option?.value ?? "",
          activityFactor: parseFloat(extendedValues["activity_factor"]),
          hours: isNaNReturnDefault(peopleDetails.numberOfHours),
        };

        dispatch(actionSelector.savePeopleDetails(people));
      }
    }

    if (
      isValidating &&
      peopleDetails &&
      peopleDetails.ventilation &&
      ventilationFilter.options &&
      (peopleDetails.ventilation === "none" ||
        validateVentilationDetails(errors, dirtyFields))
    ) {
      let ventilationExtendedValues;
      let option = ventilationFilter.options.find(
        (o) => o.key === peopleDetails.ventilation
      );
      if (option && "extended_values" in option) {
        ventilationExtendedValues = option["extended_values"];
      }

      if (ventilationExtendedValues) {
        let airFlowPerPerson =
          "airFlowPerPerson" in ventilationExtendedValues &&
          ventilationExtendedValues["airFlowPerPerson"]
            ? parseFloat(ventilationExtendedValues["airFlowPerPerson"])
            : 0;
        const ventilation: VentilationDetails = {
          ventilationType: peopleDetails.ventilation,
          airLitresPerPerson: airFlowPerPerson,
        };
        dispatch(actionSelector.saveVentilationDetails(ventilation));
      }
    }
  }, [
    errors,
    isValidating,
    dirtyFields,
    dispatch,
    watch,
    peopleActivityFilter,
    ventilationFilter,
  ]);

  return (
    <FormProvider {...methods}>
      <form>
        <PeopleDetails
          peopleActivityFilter={peopleActivityFilter}
          ventilationFilter={ventilationFilter}
        />
      </form>
    </FormProvider>
  );
};

export default PeopleDetailsContainer;
