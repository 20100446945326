import React, { FC } from "react";
import styles from "./ErrorMessage.module.css";

interface ErrorMessageProps {
  errorText: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ errorText }) => {
  return (
    <div role="alert" className={styles.errorMessage}>
      {errorText}
    </div>
  );
};

export default ErrorMessage;
