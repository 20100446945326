import React, { FC, MouseEvent, useEffect, useState } from "react";
import styles from "./Menu.module.css";
import Image from "../Image/Image";
import { logoutRedirect } from "../../authentication/CognitoTokenService";

const goToLogout = (e: MouseEvent<HTMLElement>) => {
  e.preventDefault();
  // redirect to logout
  let logoutUrl = logoutRedirect();
  window.location.assign(logoutUrl);
};

const Menu: FC = () => {
  const [isOpenMenu, setOpen] = useState<boolean>(false);
  let givenName = sessionStorage.getItem("ve_user_given_name");

  const onClickMenu = () => {
    setOpen(!isOpenMenu);
  };

  useEffect(() => {
    const handleWindowClick = () => setOpen(false);

    if (isOpenMenu) {
      window.addEventListener("click", handleWindowClick);
    } else {
      window.removeEventListener("click", handleWindowClick);
    }
    return () => window.removeEventListener("click", handleWindowClick);
  }, [isOpenMenu, setOpen]);

  return (
    <>
      {givenName !== null && (
        <div className={styles.dropdown}>
          <button
            onClick={onClickMenu}
            className={styles.dropbtn}
            data-testid="user-menu"
          >
            <div className={styles.menuProfile}>
              <Image filename="account_circle_black" altText="Account menu" />
            </div>
            <div className={styles.userName}>{givenName}</div>

            <div className={styles.menuArrows}>
              <Image
                filename={
                  isOpenMenu ? "keyboard_arrow_up" : "keyboard_arrow_down"
                }
                altText={isOpenMenu ? `Close the menu` : `Open the menu`}
              />
            </div>
          </button>
          {isOpenMenu && (
            <div id="myDropdown" className={styles.dropdownContent}>
              <a href="." onClick={(e) => goToLogout(e)}>
                Sign out
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Menu;
