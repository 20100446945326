export const PIPE_SIZE_CALCULATION = "PIPE_SIZE_CALCULATION";
export const SELECT_LIQUID_LINE_PIPE_HORIZONTAL =
  "SELECT_LIQUID_LINE_PIPE_HORIZONTAL";
export const SELECT_LIQUID_LINE_PIPE_VERTICAL =
  "SELECT_LIQUID_LINE_PIPE_VERTICAL";
export const SELECT_DISCHARGE_LINE_PIPE_HORIZONTAL =
  "SELECT_DISCHARGE_LINE_PIPE_HORIZONTAL";
export const SELECT_DISCHARGE_LINE_PIPE_VERTICAL =
  "SELECT_DISCHARGE_LINE_PIPE_VERTICAL";
export const SELECT_SUCTION_LINE_PIPE_HORIZONTAL =
  "SELECT_SUCTION_LINE_PIPE_HORIZONTAL";
export const SELECT_SUCTION_LINE_PIPE_VERTICAL =
  "SELECT_SUCTION_LINE_PIPE_VERTICAL";
export const CLEAR_ALL_PIPE_SELECTIONS = "CLEAR_ALL_PIPE_SELECTIONS";

export default {
  PIPE_SIZE_CALCULATION,
  SELECT_LIQUID_LINE_PIPE_HORIZONTAL,
  SELECT_LIQUID_LINE_PIPE_VERTICAL,
  SELECT_SUCTION_LINE_PIPE_HORIZONTAL,
  SELECT_SUCTION_LINE_PIPE_VERTICAL,
  SELECT_DISCHARGE_LINE_PIPE_HORIZONTAL,
  SELECT_DISCHARGE_LINE_PIPE_VERTICAL,
  CLEAR_ALL_PIPE_SELECTIONS,
};
