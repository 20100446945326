import React, { FC } from "react";
import PrintFooter from "./PrintFooter";
import PrintHeader from "./PrintHeader";
import styles from "./PrintTemplate.module.css";

const PrintTemplate: FC<{ pageTitle: string; showPrintTemplate?: boolean }> = ({
  pageTitle,
  showPrintTemplate = false,
  children,
}) => {
  return (
    <div
      className={`${styles.printLayoutContainer} ${
        showPrintTemplate ? styles.overridePrintLayoutContainer : ``
      }`}
    >
      <PrintHeader pageTitle={pageTitle} />

      <table className={styles.paging}>
        <thead>
          <tr>
            <td>
              <div className={styles.pageHeaderSpace}></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{children}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className={styles.pageFooterSpace}></div>
            </td>
          </tr>
        </tfoot>
      </table>

      <PrintFooter />
    </div>
  );
};

export default PrintTemplate;
