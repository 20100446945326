import React, { FC } from "react";
import { StandardRoom } from "../../../types/HeatLoad";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";

const LocationAndRoomOutput: FC<{
  roomDetails: StandardRoom;
  advancedCalc: boolean;
}> = ({ roomDetails, advancedCalc }) => {
  return (
    <table className={styles.tablePrint}>
      <tbody>
        <tr>
          <td width="20%">Room location</td>
          <td width="30%">{roomDetails.location}</td>
          <td width="20%">Design room temp.</td>
          <td width="30%">{roomDetails.temperature}°C</td>
        </tr>
        <tr>
          <td>Dry bulb temp.</td>
          <td>{roomDetails.outsideTemperature}°C</td>
          <td>Design room humidity</td>
          <td>{roomDetails.humidity}%</td>
        </tr>
        <tr>
          <td>Wet bulb temp.</td>
          <td>{roomDetails.wetBulbTemperature}°C</td>
          <td>Length, width, height</td>
          <td>{`${roomDetails.length} mm x ${roomDetails.width} mm x ${roomDetails.height} mm`}</td>
        </tr>
        {!advancedCalc && (
          <tr>
            <td></td>
            <td></td>
            <td>Room Usage</td>
            <td>{`${roomDetails.roomUsageDisplay}`}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default LocationAndRoomOutput;
