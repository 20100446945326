import React, { FC, useState } from "react";
import Image from "../Image/Image";
import cx from "classnames";
import styles from "./AlertMessage.module.css";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";

type AlertType = "warning" | "error" | "success" | "info";

interface AlertMessageProps {
  title: string;
  description: string;
  alertType?: AlertType;
  showCloseIcon?: boolean;
}

const AlertMessage: FC<AlertMessageProps> = ({
  title,
  description,
  alertType = "warning",
  showCloseIcon = false,
}) => {
  const [isHidden, setHideAlert] = useState(false);
  const imageFile =
    alertType === "warning"
      ? "warning-alert"
      : alertType === "info"
      ? "info_filled_blue"
      : alertType === "success"
      ? "success_filled_green"
      : "error_filled_red";

  const handleHideAlert = () => {
    setHideAlert(true);
  };

  return (
    <div
      className={cx(
        styles.alertContainer,
        alertType === "warning" && styles.warning,
        alertType === "info" && styles.info,
        alertType === "error" && styles.error,
        alertType === "success" && styles.success,
        isHidden && styles.hideAlert
      )}
      role="alert"
    >
      <div
        className={cx(
          styles.alertSideBar,
          alertType === "warning" && styles.warningSideBar,
          alertType === "info" && styles.infoSideBar,
          alertType === "error" && styles.errorSideBar,
          alertType === "success" && styles.successSideBar
        )}
      >
        &nbsp;
      </div>
      <div className={styles.alertIconContainer}>
        <Image filename={imageFile} altText={`${alertType} - ${title}`} />
      </div>
      <div className={styles.alertText}>
        <div
          className={cx(
            styles.alertTitle,
            alertType === "info" && styles.alertInfoTitle,
            alertType === "error" && styles.alertErrorTitle,
            alertType === "success" && styles.alertSuccessTitle
          )}
        >
          {title}
        </div>
        <div className={styles.alertDescription}>{description}</div>
      </div>
      {showCloseIcon && (
        <div className={styles.closeAlertContainer}>
          <button onClick={handleHideAlert}>
            <CloseIcon data-testid="closeAlert" />
          </button>
        </div>
      )}
    </div>
  );
};

export default AlertMessage;
