import { InfiltrationLoadRequest } from "./InfiltrationLoadRequest";
import DoorInfiltrationRequest from "./DoorInfiltrationRequest";

declare const window: any;

export default class ServiceEndpoints {
  getInfiltrationLoad(request: InfiltrationLoadRequest): string {
    const endpoint = `${window.__runtime_configuration.AIR_INFILTRATION_LOAD}/call/air-infiltration-load`;
    let params: { [index: string]: string | number } = {
      air_flow: request.air_flow,
      room_temperature: request.room_temperature,
      room_humidity: request.room_humidity,
      outside_temperature: request.outside_temperature,
      outside_humidity: request.outside_humidity,
    };
    let url = this.appendQueryParameters(endpoint, params);
    return url.href;
  }

  getDoorInfiltrationLoad(request: DoorInfiltrationRequest): string {
    const endpoint = `${window.__runtime_configuration.AIR_INFILTRATION_LOAD}/call/door-infiltration-load`;
    let params: { [index: string]: string | number } = {
      door_height: request.doorHeight,
      door_area: request.doorArea,
      doorway_use_factor: request.doorwayUseFactor,
      doorway_opening_factor: request.doorwayOpeningFactor,
      opening_time: request.openingTime,
      effectiveness: request.effectiveness,
      room_temperature: request.roomTemperature,
      room_humidity: request.roomHumidity,
      outside_temperature: request.outsideTemperature,
      outside_humidity: request.outsideHumidity,
    };
    let url = this.appendQueryParameters(endpoint, params);
    return url.href;
  }

  private appendQueryParameters(
    endpoint: string,
    params: { [p: string]: string | number }
  ) {
    let url = new URL(endpoint);

    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key].toString())
    );
    return url;
  }
}
