import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Section from "../../components/Section/Section";
import Error from "../../components/Error/Error";
import { SelectOption } from "../../types/SelectOption";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import actionSelector from "../duck/selectors";
import Tabs from "../../components/Tabs/Tabs";
import useFilters from "../../productSelection/hooks/useFilters";
import PipeCalculationResults, {
  UnitTypes,
} from "../sections/PipeCalculationResults";
import { PipeSizeCalculationRequest } from "../../types/PipeSizeCalculationRequest";
import usePipeCalculationResults from "../hooks/usePipeCalculationResults";
import TableLoadingPlaceholder from "../../components/Table/TableLoadingPlaceholder";
import ResultGrid from "../../components/ResultGrid/ResultGrid";
import {
  DesignConditions,
  PipeSizeCalculations,
} from "../../types/PipeSizeCalculationResponse";
import TablePlaceholder from "../../components/TablePlaceholder/TablePlaceholder";
import PipeSizeCalculationForm from "./PipeSizeCalculationForm";
import AsideSection from "../sections/AsideSection";
import { logPageEvent } from "../../helpers/amplitude";
import PipeSizePrint from "../sections/PipeSizePrint/PipeSizePrint";
import Button from "../../components/Button/Button";
import { CapitalEquipmentFilterModel } from "../../types/CapitalEquipmentFilterModel";
import { BalancedPerformance } from "../../types/EvaporatorProductCapacity";
import { CondensingUnitProductCapacity } from "../../types/CondensingUnitProductCapacity";
import isAnEmptyObject from "../../helpers/emptyObject";
import PageTitleSection from "../../components/PageTitleSection/PageTitleSection";
import styles from "../../components/Layout/Layout.module.css";
import { CondensingUnitFilterModel } from "../../types/CondensingUnitFilterModel";
import { getQueryParams } from "hookrouter";
interface PipeCalculationForm {
  refrigerant: string;
  operatingCapacity: string;
  pipeLine: string;
  referenceTemperatureMethod: string;
  saturatedSuctionTemp: string;
  saturatedCondensingTemp: string;
  compressorInletTemp: string;
  liquidSubcooling: string;
  locationOfCondensingUnit: string;
  levelOfDetail: string;
  horizontalLiquid: string;
  verticalLiquid: string;
  horizontalSuction: string;
  verticalSuction: string;
  horizontalDischarge: string;
  verticalDischarge: string;
}

const createPipeSizeCalculationRequest = (inputs: PipeCalculationForm) => {
  let verticalLiquid = 0;
  let verticalSuction = 0;
  let verticalDischarge = 0;

  if (inputs.levelOfDetail === "separate") {
    if (inputs.verticalLiquid) {
      verticalLiquid =
        inputs.locationOfCondensingUnit === "above"
          ? -Math.abs(+inputs.verticalLiquid)
          : +inputs.verticalLiquid;
    }

    if (inputs.verticalSuction) {
      verticalSuction =
        inputs.locationOfCondensingUnit === "below"
          ? -Math.abs(+inputs.verticalSuction)
          : +inputs.verticalSuction;
    }

    if (inputs.verticalDischarge) verticalDischarge = +inputs.verticalDischarge;
  }

  let pipeCalculationParams: PipeSizeCalculationRequest = {
    refrigerant: inputs.refrigerant,
    pipeLine: inputs.pipeLine,
    operatingCapacity: +inputs.operatingCapacity,
    mid_or_dew: inputs.referenceTemperatureMethod,
    compressorInletTemp: +inputs.compressorInletTemp,
    liquidSubcooling: +inputs.liquidSubcooling,
    saturatedCondensingTemp: +inputs.saturatedCondensingTemp,
    saturatedSuctionTemp: +inputs.saturatedSuctionTemp,
    horizontalDischarge: inputs.horizontalDischarge
      ? +inputs.horizontalDischarge
      : 0,
    horizontalLiquid: inputs.horizontalLiquid ? +inputs.horizontalLiquid : 0,
    horizontalSuction: inputs.horizontalSuction ? +inputs.horizontalSuction : 0,
    verticalLiquid: verticalLiquid,
    verticalSuction: verticalSuction,
    verticalDischarge: verticalDischarge,
    locationOfCondensingUnit: inputs.locationOfCondensingUnit,
  };

  return pipeCalculationParams;
};

const PipeSizeCalculation: React.FC = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("Liquid");
  const { pipeSizeCalculationService, calculatePipeSizeResults } =
    usePipeCalculationResults();
  const [enablePrint, setEnablePrint] = useState(false);
  const [uomPressure, setUomPressure] = useState("kPa");

  const filterDetails: CapitalEquipmentFilterModel = useSelector(
    (state: any) => {
      return state.product.filter;
    }
  );

  const condensingUnitFilterDetails: CondensingUnitFilterModel = useSelector(
    (state: any) => {
      return state.product.condensingUnitSearchFilter;
    }
  );

  const balancePerformanceDetails: BalancedPerformance = useSelector(
    (state: any) => {
      return state.product.selectedEvaporator.balance_performance;
    }
  );

  const condensingUnitProductCapacity: CondensingUnitProductCapacity =
    useSelector((state: any) => {
      return state.product.selectedSingleCondensingUnit;
    });

  let queryParams = getQueryParams();
  let refrigerant = filterDetails.refrigerant;
  let compressorInletTemp = filterDetails.compressorInletSuperheat;

  if (queryParams && queryParams.originPage === "/condensing-unit-search") {
    refrigerant = condensingUnitFilterDetails.refrigerant;
    compressorInletTemp = condensingUnitFilterDetails.compressorInletSuperheat;
  }

  let operatingCapacity = 0;
  let saturatedSuctionTemp = 0;
  let saturatedCondensingTemp = 0;

  if (balancePerformanceDetails !== undefined) {
    operatingCapacity = Math.round(balancePerformanceDetails.balance_capacity);
    saturatedSuctionTemp =
      Math.round(balancePerformanceDetails.evaporator_temperature * 10) / 10;
    saturatedCondensingTemp =
      Math.round(balancePerformanceDetails.condenser_temperature * 10) / 10;
  }

  if (!isAnEmptyObject(condensingUnitProductCapacity)) {
    operatingCapacity = Math.round(condensingUnitProductCapacity.capacity);
    saturatedSuctionTemp =
      Math.round(condensingUnitProductCapacity.evaporator_temperature * 10) /
      10;
    saturatedCondensingTemp =
      Math.round(condensingUnitProductCapacity.condenser_temperature * 10) / 10;
  }

  const defaultValues: PipeCalculationForm = {
    refrigerant: refrigerant,
    operatingCapacity:
      operatingCapacity > 0 ? String(operatingCapacity) : "3000",
    pipeLine: "liquid",
    referenceTemperatureMethod: "mid",
    saturatedSuctionTemp:
      operatingCapacity > 0 ? String(saturatedSuctionTemp) : "-4",
    saturatedCondensingTemp:
      operatingCapacity > 0 ? String(saturatedCondensingTemp) : "38",
    compressorInletTemp:
      compressorInletTemp > 80
        ? "80"
        : compressorInletTemp > 0
        ? String(compressorInletTemp)
        : "15",
    liquidSubcooling: "2",
    locationOfCondensingUnit: "same",
    levelOfDetail: "equivalent",
    horizontalLiquid: "0",
    verticalLiquid: "0",
    horizontalSuction: "0",
    verticalSuction: "0",
    horizontalDischarge: "0",
    verticalDischarge: "0",
  };

  const methods = useForm<PipeCalculationForm>({
    defaultValues,
    mode: "onBlur",
  });

  const { setValue, handleSubmit } = methods;

  const submit = useCallback(
    (inputs: PipeCalculationForm) => {
      inputs.pipeLine = selectedTab.toLowerCase();
      let pipeCalculationParams = createPipeSizeCalculationRequest(inputs);

      // Save the form inputs to redux store
      dispatch(actionSelector.pipeSizeCalculation(pipeCalculationParams));

      // clear selected pipes
      dispatch(actionSelector.clearAllPipeSelections());

      // Populate pipe size calculation results
      calculatePipeSizeResults(pipeCalculationParams);

      setEnablePrint(true);
    },
    [calculatePipeSizeResults, dispatch, selectedTab]
  );

  // Download list of available pipe sizes
  useFilters();

  const refrigerantOptions: SelectOption[] = useSelector(
    (state: any) => state.product.pipeSizeRefrigerantOptions
  );

  const selectedLiquidLineHorizontalPipe: PipeSizeCalculations = useSelector(
    (state: any) => state.pipes.selectedLiquidLinePipes.horizontal
  );

  const selectedLiquidLineVerticalPipe: PipeSizeCalculations = useSelector(
    (state: any) => state.pipes.selectedLiquidLinePipes.vertical
  );

  const selectedSuctionLineHorizontalPipe: PipeSizeCalculations = useSelector(
    (state: any) => state.pipes.selectedSuctionLinePipes.horizontal
  );

  const selectedSuctionLineVerticalPipe: PipeSizeCalculations = useSelector(
    (state: any) => state.pipes.selectedSuctionLinePipes.vertical
  );

  const selectedDischargeLineHorizontalPipe: PipeSizeCalculations = useSelector(
    (state: any) => state.pipes.selectedDischargeLinePipes.horizontal
  );

  const selectedDischargeLineVerticalPipe: PipeSizeCalculations = useSelector(
    (state: any) => state.pipes.selectedDischargeLinePipes.vertical
  );

  useEffect(() => {
    setValue("refrigerant", refrigerant);
  }, [refrigerantOptions, setValue, refrigerant]);

  const selectPipe = useCallback(
    (
      selectedPipe: PipeSizeCalculations,
      position: string,
      lineType: string
    ) => {
      if (position === "horizontal") {
        if (lineType === "Liquid")
          dispatch(actionSelector.selectLiquidLinePipeHorizontal(selectedPipe));

        if (lineType === "Suction")
          dispatch(
            actionSelector.selectSuctionLinePipeHorizontal(selectedPipe)
          );

        if (lineType === "Discharge")
          dispatch(
            actionSelector.selectDischargeLinePipeHorizontal(selectedPipe)
          );
      }

      if (position === "vertical") {
        if (lineType === "Liquid")
          dispatch(actionSelector.selectLiquidLinePipeVertical(selectedPipe));

        if (lineType === "Suction")
          dispatch(actionSelector.selectSuctionLinePipeVertical(selectedPipe));

        if (lineType === "Discharge")
          dispatch(
            actionSelector.selectDischargeLinePipeVertical(selectedPipe)
          );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (pipeSizeCalculationService.status === "loaded") {
      let liquidHorizontalBestMatch =
        pipeSizeCalculationService.payload.liquid.pipe_gauge?.find(
          (p) =>
            p.id ===
            pipeSizeCalculationService.payload.liquid.best_match.horizontal?.id
        );
      let liquidVerticalBestMatch =
        pipeSizeCalculationService.payload.liquid.pipe_gauge?.find(
          (p) =>
            p.id ===
            pipeSizeCalculationService.payload.liquid.best_match.vertical?.id
        );

      let suctionHorizontalBestMatch =
        pipeSizeCalculationService.payload.suction.pipe_gauge?.find(
          (p) =>
            p.id ===
            pipeSizeCalculationService.payload.suction.best_match.horizontal?.id
        );
      let suctionVerticalBestMatch =
        pipeSizeCalculationService.payload.suction.pipe_gauge?.find(
          (p) =>
            p.id ===
            pipeSizeCalculationService.payload.suction.best_match.vertical?.id
        );

      let dischargeHorizontalBestMatch =
        pipeSizeCalculationService.payload.discharge.pipe_gauge?.find(
          (p) =>
            p.id ===
            pipeSizeCalculationService.payload.discharge.best_match.horizontal
              ?.id
        );
      let dischargeVerticalBestMatch =
        pipeSizeCalculationService.payload.discharge.pipe_gauge?.find(
          (p) =>
            p.id ===
            pipeSizeCalculationService.payload.discharge.best_match.vertical?.id
        );

      if (liquidHorizontalBestMatch)
        selectPipe(liquidHorizontalBestMatch, "horizontal", "Liquid");

      if (liquidVerticalBestMatch)
        selectPipe(liquidVerticalBestMatch, "vertical", "Liquid");

      if (suctionHorizontalBestMatch)
        selectPipe(suctionHorizontalBestMatch, "horizontal", "Suction");

      if (suctionVerticalBestMatch)
        selectPipe(suctionVerticalBestMatch, "vertical", "Suction");

      if (dischargeHorizontalBestMatch)
        selectPipe(dischargeHorizontalBestMatch, "horizontal", "Discharge");

      if (dischargeVerticalBestMatch)
        selectPipe(dischargeVerticalBestMatch, "vertical", "Discharge");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeSizeCalculationService.status, selectPipe]);

  const switchTabs = async (selectedTabName: string) => {
    setSelectedTab(selectedTabName);
  };

  const emptyDesignConditions: DesignConditions = {
    flow_rate: 0,
    temperature_difference: 0,
    density: 0,
    viscosity: 0,
    swp: 0,
    max_height_before_flash: 0,
  };

  const initialUnitTypes: UnitTypes = {
    density: "kg/m³",
    flow_rate: "kg/s",
    viscosity: "µPa.s",
    swp: "kPa",
    od_mm: "mm",
    wall_thickness: "mm",
    od_in: '"',
  };

  const handleUomSelected = (uom: string) => {
    setUomPressure(uom);
  };

  const tableResults =
    pipeSizeCalculationService.status === "init" ? (
      <TablePlaceholder
        heading="No results"
        subheading={`Enter operating details, temperature and ${selectedTab.toLowerCase()} line length section to see results`}
      />
    ) : pipeSizeCalculationService.status === "loaded" ? (
      <PipeCalculationResults
        pipeCalculationResults={
          pipeSizeCalculationService.payload[selectedTab.toLowerCase()]
        }
        lineType={selectedTab}
        handlePipeSelect={selectPipe}
        handleUomSelected={handleUomSelected}
        selectedLiquidLineHorizontalPipe={selectedLiquidLineHorizontalPipe}
        selectedLiquidLineVerticalPipe={selectedLiquidLineVerticalPipe}
        selectedSuctionLineHorizontalPipe={selectedSuctionLineHorizontalPipe}
        selectedSuctionLineVerticalPipe={selectedSuctionLineVerticalPipe}
        selectedDischargeLineHorizontalPipe={
          selectedDischargeLineHorizontalPipe
        }
        selectedDischargeLineVerticalPipe={selectedDischargeLineVerticalPipe}
      />
    ) : (
      <></>
    );

  const asideSection = (
    <AsideSection
      designConditions={
        pipeSizeCalculationService.status === "init"
          ? emptyDesignConditions
          : pipeSizeCalculationService.status === "loaded" &&
            pipeSizeCalculationService.payload[selectedTab.toLowerCase()]
              .design_conditions
          ? pipeSizeCalculationService.payload[selectedTab.toLowerCase()]
              .design_conditions
          : ({} as DesignConditions)
      }
      unitTypes={
        pipeSizeCalculationService.status === "init"
          ? initialUnitTypes
          : pipeSizeCalculationService.status === "loaded" &&
            pipeSizeCalculationService.payload[selectedTab.toLowerCase()].uom
          ? pipeSizeCalculationService.payload[selectedTab.toLowerCase()].uom
          : initialUnitTypes
      }
      pipeGaugeLength={
        pipeSizeCalculationService.status === "init"
          ? 0
          : pipeSizeCalculationService.status === "loaded" &&
            pipeSizeCalculationService.payload[selectedTab.toLowerCase()]
              .pipe_gauge
          ? pipeSizeCalculationService.payload[selectedTab.toLowerCase()]
              .pipe_gauge.length
          : 0
      }
      selectedLiquidLineHorizontalPipe={selectedLiquidLineHorizontalPipe}
      selectedSuctionLineHorizontalPipe={selectedSuctionLineHorizontalPipe}
      selectedDischargeLineHorizontalPipe={selectedDischargeLineHorizontalPipe}
      selectedLiquidLineVerticalPipe={selectedLiquidLineVerticalPipe}
      selectedSuctionLineVerticalPipe={selectedSuctionLineVerticalPipe}
      selectedDischargeLineVerticalPipe={selectedDischargeLineVerticalPipe}
      selectedPressureDropUom={uomPressure}
      isLoading={pipeSizeCalculationService.status !== "loaded"}
    />
  );

  return (
    <Layout>
      <PageTitleSection title="Pipe size calculator">
        <Button
          isDisabled={!enablePrint}
          buttonStyle="secondary"
          onClickHandler={() => {
            logPageEvent("Pipe Size Calculation - Print");
            window.print();
          }}
        >
          Print
        </Button>
      </PageTitleSection>

      <div className={`hideSectionForPrint ${styles.contentContainer}`}>
        <Section>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submit)}>
              <PipeSizeCalculationForm
                refrigerantOptions={refrigerantOptions}
              />
            </form>
          </FormProvider>

          {(pipeSizeCalculationService.status === "init" ||
            pipeSizeCalculationService.status === "loaded") && (
            <>
              <ResultGrid
                tableComponent={
                  <section>
                    <Tabs
                      tabs={["Liquid", "Suction", "Discharge"]}
                      selectedTab={selectedTab}
                      onSelect={(e) => {
                        switchTabs(e.selectedTabName);
                        logPageEvent(`${e.selectedTabName} results tab`);
                      }}
                      testId="pipe-size-selection-tab-field"
                    />
                    {tableResults}
                  </section>
                }
                asideComponent={asideSection}
              />
            </>
          )}

          {pipeSizeCalculationService.status === "loading" && (
            <>
              <TableLoadingPlaceholder />
            </>
          )}

          {pipeSizeCalculationService.status === "error" && <Error />}
        </Section>
      </div>

      <PipeSizePrint uomPressure={uomPressure} />
    </Layout>
  );
};

export default PipeSizeCalculation;
