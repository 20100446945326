import { FieldError, RegisterOptions } from "react-hook-form";

interface ForkliftDetailsErrors {
  forkliftType?: FieldError | undefined;
  heatGain?: FieldError | undefined;
  forkliftUsage?: FieldError | undefined;
}

interface ForkliftDetailsDirtyFields {
  forkliftType?: boolean | undefined;
  heatGain?: boolean | undefined;
  forkliftUsage?: boolean | undefined;
}

const heatGainRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Must be between 0 and 100,000",
  },
  max: {
    value: 100000,
    message: "Must be between 0 and 100,000",
  },
  valueAsNumber: true,
};

const usageRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Must be between 0 and 24",
  },
  max: {
    value: 24,
    message: "Must be between 0 and 24",
  },
  valueAsNumber: true,
};

const isErrorForklifts = (errors: ForkliftDetailsErrors): boolean => {
  return errors.heatGain !== undefined || errors.forkliftUsage !== undefined;
};

const allForkliftFieldsDirty = (
  dirtyFields: ForkliftDetailsDirtyFields
): boolean => {
  let forkliftDirtyFlag =
    dirtyFields.heatGain !== undefined &&
    dirtyFields.forkliftUsage !== undefined;
  return forkliftDirtyFlag ?? false;
};

const validateForkliftDetails = (
  errors: ForkliftDetailsErrors,
  dirtyFields: ForkliftDetailsDirtyFields
): boolean => {
  const isValid = !isErrorForklifts(errors);
  const allDirtyFields = allForkliftFieldsDirty(dirtyFields);

  return isValid && allDirtyFields;
};

export { usageRules, heatGainRules, validateForkliftDetails };
