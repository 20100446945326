import React, { FC } from "react";
import Image from "../Image/Image";
import styles from "./InfoText.module.css";

const InfoText: FC<{ testid?: string }> = ({ testid, children }) => {
  return (
    <div
      role="contentinfo"
      className={styles.infoMessageContainer}
      data-testid={testid}
    >
      <div>
        <Image filename="info_black" altText="Information message" />
      </div>
      <div className={styles.infoMessageText}>{children}</div>
    </div>
  );
};

export default InfoText;
