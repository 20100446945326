import { useEffect, useState } from "react";
import { Service } from "../../types/Service";
import { CondensingUnitProductCapacities } from "../../types/CondensingUnitProductCapacity";
import { useDispatch, useSelector } from "react-redux";
import { default as actionSelector } from "../duck/selectors";
import getUrl from "../../helpers/api";
import CondensingUnitResponse from "../../types/CondensingUnitResponse";
import { FilterValues } from "../../types/FilterValues";
import { CondensingUnitFilterModel } from "../../types/CondensingUnitFilterModel";

declare const window: any;
const ENDPOINT_CONDENSING_UNITS = `${window.__runtime_configuration.ENDPOINT_CONDENSING_UNITS}/call/condensing-unit`;

const useSingleCondensingUnits = (): {
  condenserUnitService: Service<CondensingUnitProductCapacities>;
} => {
  const [result, setResult] = useState<
    Service<CondensingUnitProductCapacities>
  >({ status: "init" });

  const dispatch = useDispatch();

  const filterSelections: FilterValues = useSelector(
    (state: any) => state.product.condensingUnitFilterSelections
  );

  const filter: CondensingUnitFilterModel = useSelector((state: any) => {
    return state.product.condensingUnitSearchFilter;
  });

  useEffect(() => {
    if (filter.capacity !== undefined && filter.capacity !== 0) {
      setResult({ status: "loading" });
      let u = new URL(ENDPOINT_CONDENSING_UNITS);
      let params: { [index: string]: string | number } = {
        refrigerant: filter.refrigerant,
        capacity: filter.capacity,
        evaporator_temperature: filter.sst,
        ambient_temperature: filter.ambient,
        mid_or_dew: filter.mid_or_dew,
        condition: filter.standardsRating,
        super_heat: filter.compressorInletSuperheat,
        sub_cooling: filter.liquidSubcooling,
      };
      // @ts-ignore
      Object.keys(params).forEach((key) =>
        u.searchParams.append(key, params[key].toString())
      );

      if (typeof filterSelections !== undefined) {
        Object.keys(filterSelections).forEach(function (key) {
          let value = filterSelections[key];
          if (value !== "Any") {
            u.searchParams.append(key, value.toString());
          }
        });
      }

      getUrl<CondensingUnitResponse>(u.href)
        .then((response) => {
          setResult({
            status: "loaded",
            payload: { ...response, filterSelections },
          });
        })
        .catch((error) => {
          setResult({ status: "error", error });
        })
        .finally(() => {
          dispatch(actionSelector.loadingBalancePerformance(false));
        });
    }
  }, [filter, filterSelections, dispatch]);

  return {
    condenserUnitService: result,
  };
};

export default useSingleCondensingUnits;
