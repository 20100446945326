export const SEARCH_CONDENSING_UNITS = "SEARCH_CONDENSING_UNITS";
export const SEARCH_SINGLE_CONDENSING_UNIT = "SEARCH_SINGLE_CONDENSING_UNIT";
export const SEARCH_EVAPORATORS = "SEARCH_EVAPORATORS";
export const SEARCH_SINGLE_EVAPORATOR = "SEARCH_SINGLE_EVAPORATOR";
export const LOADING_BALANCE_PERFORMANCE = "LOADING_BALANCE_PERFORMANCE";
export const SHOW_BALANCE_PERFORMANCE = "SHOW_BALANCE_PERFORMANCE";
export const SELECT_EVAPORATOR = "SELECT_EVAPORATOR";
export const SELECT_CONDENSING_UNIT = "SELECT_CONDENSING_UNIT";
export const SELECT_SINGLE_EVAPORATOR = "SELECT_SINGLE_EVAPORATOR";
export const SELECT_SINGLE_CONDENSING_UNIT = "SELECT_SINGLE_CONDENSING_UNIT";
export const CLEAR_SELECTED_PRODUCTS = "CLEAR_SELECTED_PRODUCTS";
export const DISPLAY_CONDENSING_UNIT_FILTERS =
  "DISPLAY_CONDENSING_UNIT_FILTERS";
export const DISPLAY_EVAPORATOR_FILTERS = "DISPLAY_EVAPORATOR_FILTERS";
export const DISPLAY_PIPE_SIZE_REFRIGERANT_OPTIONS =
  "DISPLAY_PIPE_SIZE_REFRIGERANT_OPTIONS";
export const DISPLAY_EQUIPMENT_REFRIGERANT_OPTIONS =
  "DISPLAY_EQUIPMENT_REFRIGERANT_OPTIONS";
export const SELECT_CONDENSING_UNIT_FILTER = "DISPLAY_CONDENSING_UNIT_FILTER";
export const SELECT_EVAPORATOR_FILTER = "DISPLAY_EVAPORATOR_FILTER";
export const DEFAULT_CONDENSING_UNIT_FILTERS_SELECTIONS =
  "DEFAULT_CONDENSING_UNIT_FILTERS_SELECTIONS";
export const RESET_EVAPORATOR_UNIT_FILTERS = "RESET_EVAPORATOR_UNIT_FILTERS";
export const RESET_CONDENSING_UNIT_FILTERS = "RESET_CONDENSING_UNIT_FILTERS";
export const DEFAULT_EVAPORATOR_FILTERS_SELECTIONS =
  "DEFAULT_EVAPORATOR_FILTERS_SELECTIONS";
export const SET_CONDENSING_UNIT_SEARCH_STATUS =
  "SET_CONDENSING_UNIT_SEARCH_STATUS";
export const SET_EVAPORATOR_SEARCH_STATUS = "SET_EVAPORATOR_SEARCH_STATUS";
export const SET_SINGLE_CONDENSING_UNIT_SEARCH_STATUS =
  "SET_SINGLE_CONDENSING_UNIT_SEARCH_STATUS";
export const SET_SINGLE_EVAPORATOR_SEARCH_STATUS =
  "SET_SINGLE_EVAPORATOR_SEARCH_STATUS";
export const CLEAR_CONDENSING_UNIT_SEARCH = "CLEAR_CONDENSING_UNIT_SEARCH";
export const CLEAR_EVAPORATOR_SEARCH = "CLEAR_EVAPORATOR_SEARCH";
export const ROOM_TEMP_BELOW_ZERO = "ROOM_TEMP_BELOW_ZERO";

export default {
  SEARCH_CONDENSING_UNITS,
  SEARCH_SINGLE_CONDENSING_UNIT,
  SEARCH_EVAPORATORS,
  SEARCH_SINGLE_EVAPORATOR,
  LOADING_BALANCE_PERFORMANCE,
  SHOW_BALANCE_PERFORMANCE,
  SELECT_EVAPORATOR,
  SELECT_CONDENSING_UNIT,
  SELECT_SINGLE_EVAPORATOR,
  SELECT_SINGLE_CONDENSING_UNIT,
  CLEAR_SELECTED_PRODUCTS,
  DISPLAY_CONDENSING_UNIT_FILTERS,
  DISPLAY_EVAPORATOR_FILTERS,
  DISPLAY_PIPE_SIZE_REFRIGERANT_OPTIONS,
  DISPLAY_EQUIPMENT_REFRIGERANT_OPTIONS,
  SELECT_CONDENSING_UNIT_FILTER,
  SELECT_EVAPORATOR_FILTER,
  DEFAULT_CONDENSING_UNIT_FILTERS_SELECTIONS,
  DEFAULT_EVAPORATOR_FILTERS_SELECTIONS,
  RESET_CONDENSING_UNIT_FILTERS,
  RESET_EVAPORATOR_UNIT_FILTERS,
  SET_CONDENSING_UNIT_SEARCH_STATUS,
  SET_EVAPORATOR_SEARCH_STATUS,
  SET_SINGLE_CONDENSING_UNIT_SEARCH_STATUS,
  SET_SINGLE_EVAPORATOR_SEARCH_STATUS,
  CLEAR_CONDENSING_UNIT_SEARCH,
  CLEAR_EVAPORATOR_SEARCH,
  ROOM_TEMP_BELOW_ZERO,
};
