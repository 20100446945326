import { default as productActions } from "./actions";

const searchCondensingUnits = productActions.searchCondensingUnits;
const searchSingleCondensingUnit = productActions.searchSingleCondensingUnit;
const searchEvaporators = productActions.searchEvaporators;
const searchSingleEvaporator = productActions.searchSingleEvaporator;
const selectEvaporator = productActions.selectEvaporator;
const selectCondensingUnit = productActions.selectCondensingUnit;
const selectSingleEvaporator = productActions.selectSingleEvaporator;
const selectSingleCondensingUnit = productActions.selectSingleCondensingUnit;
const clearSelectedProducts = productActions.clearSelectedProducts;
const loadingBalancePerformance = productActions.loadingBalancePerformance;

const displayCondensingUnitFilters =
  productActions.displayCondensingUnitFilters;
const selectCondensingUnitFilter = productActions.selectCondensingUnitFilter;
const displayEvaporatorFilters = productActions.displayEvaporatorFilters;
const selectEvaporatorFilter = productActions.selectEvaporatorFilter;
const displayPipeSizeRefrigerantOptions =
  productActions.displayPipeSizeRefrigerantOptions;
const displayEquipmentRefrigerantOptions =
  productActions.displayEquipmentRefrigerantOptions;
const defaultCondensingUnitFilterSelections =
  productActions.defaultCondensingUnitFilterSelections;
const defaultEvaporatorFilterSelections =
  productActions.defaultEvaporatorFilterSelections;
const resetCondensingUnitFilters = productActions.resetCondensingUnitFilters;
const resetEvaporatorFilters = productActions.resetEvaporatorFilters;
const setCondensingUnitSearchStatus =
  productActions.setCondensingUnitSearchStatus;
const setEvaporatorSearchStatus = productActions.setEvaporatorSearchStatus;
const setSingleCondensingUnitSearchStatus =
  productActions.setSingleCondensingUnitSearchStatus;
const setSingleEvaporatorSearchStatus =
  productActions.setSingleEvaporatorSearchStatus;

const clearCondensingUnitSearch = productActions.clearCondensingUnitSearch;
const clearEvaporatorSearch = productActions.clearEvaporatorSearch;

export default {
  searchCondensingUnits,
  searchSingleCondensingUnit,
  searchEvaporators,
  searchSingleEvaporator,
  selectEvaporator,
  selectCondensingUnit,
  selectSingleEvaporator,
  selectSingleCondensingUnit,
  clearSelectedProducts,
  loadingBalancePerformance,
  displayCondensingUnitFilters,
  selectCondensingUnitFilter,
  displayEvaporatorFilters,
  selectEvaporatorFilter,
  displayPipeSizeRefrigerantOptions,
  displayEquipmentRefrigerantOptions,
  defaultCondensingUnitFilterSelections,
  defaultEvaporatorFilterSelections,
  resetCondensingUnitFilters,
  resetEvaporatorFilters,
  setCondensingUnitSearchStatus,
  setEvaporatorSearchStatus,
  setSingleCondensingUnitSearchStatus,
  setSingleEvaporatorSearchStatus,
  clearCondensingUnitSearch,
  clearEvaporatorSearch,
};
