import React, { FC } from "react";
import { useSelector } from "react-redux";
import PrintTemplate from "../../../components/PrintTemplate/PrintTemplate";
import { CondensingUnitFilterModel } from "../../../types/CondensingUnitFilterModel";
import { CondensingUnitProductCapacity } from "../../../types/CondensingUnitProductCapacity";
import CondensingUnitInputs from "./CondensingUnitInputs";
import CondensingUnitPerformanceData from "./CondensingUnitPerformanceData";
import SelectedCondensingUnitPrint from "./SelectedCondensingUnitPrint";

const CondensingUnitPrint: FC = () => {
  const selectedCondensingUnit: CondensingUnitProductCapacity = useSelector(
    (state: any) => state.product.selectedSingleCondensingUnit
  );

  const condensingUnitSearch: CondensingUnitFilterModel = useSelector(
    (state: any) => {
      return state.product.condensingUnitSearchFilter;
    }
  );

  return (
    <PrintTemplate pageTitle="Equipment selection">
      <div className="page-break">
        <div className="page-header">Condensing unit inputs</div>

        <CondensingUnitInputs condensingUnitSearch={condensingUnitSearch} />

        <div className="page-header">Condensing unit selection</div>

        <CondensingUnitPerformanceData
          selectedCondensingUnit={selectedCondensingUnit}
        />

        <SelectedCondensingUnitPrint
          selectedCondensingUnit={selectedCondensingUnit}
          numberOfCondensingUnits={1}
          refrigerant={condensingUnitSearch.refrigerant}
        />
      </div>
    </PrintTemplate>
  );
};

export default CondensingUnitPrint;
