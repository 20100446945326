import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../BalancedPerformance/BalancedPerformance.module.css";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder/LoadingPlaceholder";
import { CondensingUnitPerformanceData } from "../../../types/CondensingUnitPerformanceData";
import { CondensingUnitProductCapacity } from "../../../types/CondensingUnitProductCapacity";

export const CondensingUnitPerformance: React.FC<{}> = () => {
  const [performanceData, setPerformanceData] =
    useState<CondensingUnitPerformanceData>({
      sst: 0,
      sct: 0,
      capacity: 0,
      power: 0,
      cop: 0,
    });

  const loadingBalancePerformance: boolean = useSelector(
    (state: any) => state.product.loadingBalancePerformance
  );

  const selectedCondensingUnit: CondensingUnitProductCapacity = useSelector(
    (state: any) => state.product.selectedSingleCondensingUnit
  );

  useEffect(() => {
    let capacity: number = selectedCondensingUnit.capacity;
    let power: number = selectedCondensingUnit.power;
    let sst: number = selectedCondensingUnit.evaporator_temperature;
    let sct: number = selectedCondensingUnit.condenser_temperature;

    if (sst !== undefined && sct !== undefined && capacity && power) {
      setPerformanceData({
        sst: sst,
        sct: sct,
        capacity: capacity,
        power: power,
        cop: capacity / power,
      });
    }
  }, [selectedCondensingUnit]);

  return (
    <section className={styles.container}>
      <div>
        <div className="section-header">Performance data</div>
        <div className={styles.performanceValues}>
          <div className={styles.performanceAttributeGrid}>
            <p>Saturated suction temp. (SST)</p>
            {loadingBalancePerformance && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!loadingBalancePerformance && (
              <p>
                <b>{performanceData.sst.toFixed(2)}</b> °C
              </p>
            )}
          </div>
          <div className={styles.performanceAttributeGrid}>
            <p>Saturated condensing temp. (SCT)</p>
            {loadingBalancePerformance && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!loadingBalancePerformance && (
              <p>
                <b>{performanceData.sct.toFixed(2)}</b> °C
              </p>
            )}
          </div>
          <div className={styles.performanceAttributeGrid}>
            <p>Capacity</p>
            {loadingBalancePerformance && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!loadingBalancePerformance && (
              <p>
                <b>{performanceData.capacity.toFixed(0)}</b> W
              </p>
            )}
          </div>
          <div className={styles.performanceAttributeGrid}>
            <p>Power</p>
            {loadingBalancePerformance && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!loadingBalancePerformance && (
              <p>
                <b>{performanceData.power.toFixed(0)}</b> W
              </p>
            )}
          </div>
          <div className={styles.performanceAttributeGrid}>
            <p>Coefficient of performance (COP)</p>
            {loadingBalancePerformance && (
              <div className={styles.textPlaceholder}>
                <LoadingPlaceholder />
              </div>
            )}
            {!loadingBalancePerformance && (
              <p>
                <b>{performanceData.cop.toFixed(2)}</b>
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
