import React, { FC } from "react";
import CircleChart from "../CircleChart/CircleChart";
import styles from "./CalculationSummary.module.css";
import Image from "../../../components/Image/Image";
import { HeatLoadTotalCapacity } from "../../types/HeatLoadCapacity";

const CalculationSummaryPieChart: FC<{
  heatLoadSummary: HeatLoadTotalCapacity;
}> = ({ heatLoadSummary }) => {
  const fanLoadTotal = heatLoadSummary.fanLoad.total / 1000;

  return (
    <div className={styles.donutContainer}>
      <CircleChart totalLoadCapacity={heatLoadSummary}>
        <div
          className={styles.donutTotalsValue}
          data-testid="pieWheelTotalCapacity"
        >
          {fanLoadTotal.toFixed(1)} kW
        </div>
        <div className={styles.donutTotalsLabel}>
          ({heatLoadSummary.fanLoad.total.toFixed(0)} W) <br />
          Required capacity
        </div>
        <div className={styles.runtimeContainer}>
          <Image filename="update_timer_black" altText="Runtime" />
          <div
            className={styles.runtimeText}
            data-testid="pieWheelTotalRuntime"
          >
            {heatLoadSummary.runTimeTotal.runtime} hr runtime
          </div>
        </div>
      </CircleChart>
    </div>
  );
};

export default CalculationSummaryPieChart;
