const data = new Map();
data.set(12, [50, 54]);
data.set(11, [55, 59]);
data.set(10, [60, 64]);
data.set(9, [65, 69]);
data.set(8, [70, 74]);
data.set(7, [75, 79]);
data.set(6, [80, 84]);
data.set(5, [85, 89]);
data.set(4, [90, 94]);
data.set(3, [95, 99]);

const humidityToTemperatureDifferenceMapping = new Map();
humidityToTemperatureDifferenceMapping.set(50, 12);
humidityToTemperatureDifferenceMapping.set(55, 11);
humidityToTemperatureDifferenceMapping.set(60, 10);
humidityToTemperatureDifferenceMapping.set(65, 9);
humidityToTemperatureDifferenceMapping.set(70, 8);
humidityToTemperatureDifferenceMapping.set(75, 7);
humidityToTemperatureDifferenceMapping.set(80, 6);
humidityToTemperatureDifferenceMapping.set(85, 5);
humidityToTemperatureDifferenceMapping.set(90, 4);
humidityToTemperatureDifferenceMapping.set(95, 3);

const humidityRangeMinimums = Array.from(
  humidityToTemperatureDifferenceMapping.keys()
);

const humidityToTd = (humidity: number) => {
  return humidityToTemperatureDifferenceMapping.get(humidity);
};

const tDToHumidity = (td: number) => {
  if (td < 3 || td > 12) {
    throw new Error("Invalid temperature difference");
  }
  return data.get(td);
};

const tDToHumidityFormat = (td: number) => {
  let n: number[] = tDToHumidity(td);
  return n[0];
};

const humidtyToMinimumInRange = (humidity: number): number => {
  for (let i = 1; i < humidityRangeMinimums.length; i++) {
    if (humidity < humidityRangeMinimums[i])
      return humidityRangeMinimums[i - 1];
  }
  return 95;
};

const formatHumidityToTemperatureDifference = (humidity: number) => {
  if (humidity < 50 || humidity > 99) {
    throw new Error("Invalid humidity value");
  }
  let min: number = humidtyToMinimumInRange(humidity);
  return humidityToTemperatureDifferenceMapping.get(min);
};

export default {
  humidityToTd,
  tDToHumidity,
  tDToHumidityFormat,
  humidtyToMinimumInRange,
  formatHumidityToTemperatureDifference,
};
