import React, { FC, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import isNaNReturnDefault from "../../../helpers/isNaNReturnNumber";
import ProductDetails from "./ProductDetails";
import actionSelector from "../../duck/selectors";
import { ProductLine } from "../../types/products";
import { ExtendedValues, ProductFilter } from "../../../types/ProductFilter";
import {
  FormProductItem,
  ProductDetailsForm,
} from "../../types/ProductDetailsForm";
import {
  isDirtyProducts,
  isValidateProducts,
} from "./ProductDetailsValidation";
import { EMPTY_FILTER } from "../../hooks/ExtractHeatloadFilters";
import {
  filterExtendedValues,
  buildProductGroupOptions,
} from "./ProductDetailsHelper";
import { GroupedOption } from "../../../types/SearchSelect";
import { logPageEventWithData } from "../../../helpers/amplitude";

const convertProduct = (
  productIndex: number,
  p: FormProductItem,
  extendedValues: ExtendedValues
) => {
  let product: ProductLine = {
    lineId: (productIndex + 1).toString(),
    product: {
      category: p.product.value,
      name: p.product.label,
      heatAboveFreeze: parseFloat(extendedValues["heatAboveFreeze"]),
      heatBelowFreeze: parseFloat(extendedValues["heatBelowFreeze"]),
      freezingPoint: parseFloat(extendedValues["freezingPoint"]),
      latentHeat: parseFloat(extendedValues["latentHeat"]),
      humidity: parseFloat(extendedValues["humidity"]),
    },
    massFlow: isNaNReturnDefault(p.amount ?? 0),
    temperatureIn: isNaNReturnDefault(p.enteringTemp ?? 0),
    temperatureOut: isNaNReturnDefault(p.finalTemp ?? 0),
    pullDownTime: isNaNReturnDefault(p.pullDownTime ?? 0),
    flowPeriod: isNaNReturnDefault(p.pullDownTime ?? 0),
  };
  return product;
};

const ProductDetailsContainer: FC<{
  preloadedValues: ProductDetailsForm;
  roomTemperature: number;
  productDetailsEnabled: boolean;
}> = ({ preloadedValues, roomTemperature, productDetailsEnabled }) => {
  let methods = useForm<ProductDetailsForm>({
    defaultValues: preloadedValues,
    mode: "onBlur",
  });
  let [defaultFormReady, setDefaultFormReady] = useState(true);

  const dispatch = useDispatch();
  const { watch } = methods;

  const { errors, dirtyFields, isValidating } = methods.formState;

  const productFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.miscellaneous
      ? state.heatLoad.formHeatLoadFilters.products["product"]
      : EMPTY_FILTER
  );

  const productCategoryFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.miscellaneous
      ? state.heatLoad.formHeatLoadFilters.products["product_category"]
      : EMPTY_FILTER
  );

  const [groupedOptions, setGroupOption] = useState<GroupedOption[]>([]);

  let removeProductCallBack = useCallback(
    (removeIndex, removeFromView) => {
      let newProducts: ProductLine[] = [];

      removeFromView(removeIndex);
      const products = watch("products");
      products.forEach((p, i) => {
        if (
          isValidateProducts(errors, i) &&
          p.product !== undefined &&
          p.product.value !== undefined
        ) {
          let extendedValues = filterExtendedValues(
            p.product.value,
            productFilter
          );

          if (extendedValues) {
            let product = convertProduct(i, p, extendedValues);
            newProducts.push(product);
          }
        }
      });
      dispatch(actionSelector.saveProductDetails(newProducts));
    },
    [dispatch, errors, productFilter, watch]
  );

  useEffect(() => {
    setGroupOption(
      buildProductGroupOptions(productFilter, productCategoryFilter)
    );
  }, [productFilter, productCategoryFilter, setGroupOption]);

  useEffect(() => {
    const products = watch("products");

    if (products && (isValidating || defaultFormReady)) {
      let newProducts: ProductLine[] = [];

      products.forEach((p, i) => {
        let isValid = isValidateProducts(errors, i);
        let isDirty = i === 0 ? true : isDirtyProducts(dirtyFields, i);

        if (isValid && isDirty && p.product !== undefined) {
          let extendedValues = filterExtendedValues(
            p.product.value,
            productFilter
          );

          if (extendedValues) {
            let product = convertProduct(i, p, extendedValues);
            newProducts.push(product);
          }
        }
      });

      if (newProducts.length > 0) {
        dispatch(actionSelector.saveProductDetails(newProducts));

        setDefaultFormReady(false);
        logPageEventWithData("Heat Load - Product Saved", {
          newProducts,
        });
      }
    }
  }, [
    dirtyFields,
    errors,
    isValidating,
    dispatch,
    productFilter,
    watch,
    defaultFormReady,
  ]);

  return (
    <FormProvider {...methods}>
      <form>
        <ProductDetails
          productFilter={productFilter}
          productGroupedOptions={groupedOptions}
          removeProductHandler={removeProductCallBack}
          roomTemperature={roomTemperature}
          formEnabled={productDetailsEnabled}
        />
      </form>
    </FormProvider>
  );
};

export default ProductDetailsContainer;
