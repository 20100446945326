import React, {
  FunctionComponent,
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
} from "react";
import styles from "./Tabs.module.css";

export interface TabSelectEvent {
  selectedTabName: string;
}

export interface TabSelectHandler {
  (event: TabSelectEvent): void;
}

export interface TabsProps {
  selectedTab: string;
  tabs: string[];
  testId: string;
  onSelect: TabSelectHandler;
}

const ALLOWED_TAB_SELECT_KEY_CODES = ["Enter", "Spacebar", " "];

const Tabs: FunctionComponent<TabsProps> = ({
  selectedTab,
  tabs,
  testId,
  onSelect,
}) => {
  const handleSelect = useCallback<MouseEventHandler<HTMLLIElement>>(
    (event) => {
      onSelect({ selectedTabName: event.currentTarget.textContent! });
    },
    [onSelect]
  );

  const handleKeyPress = useCallback<KeyboardEventHandler<HTMLLIElement>>(
    (event) => {
      if (ALLOWED_TAB_SELECT_KEY_CODES.includes(event.key)) {
        onSelect({ selectedTabName: event.currentTarget.textContent! });
      }
    },
    [onSelect]
  );

  return (
    <div className={styles.Tabs}>
      <ul data-testid={testId}>
        {tabs.map((tabLabel) => {
          return (
            <li
              tabIndex={0}
              key={tabLabel}
              className={tabLabel === selectedTab ? styles.ActiveTab : ""}
              onClick={handleSelect}
              onKeyPress={handleKeyPress}
            >
              {tabLabel}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;
