import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RoomDimensions } from "../../../types/Room";
import { StandardRoom } from "../../../../types/HeatLoad";
import DoorDetailsContainer, { DoorDetailsForm } from "./DoorDetailsContainer";
import InfoText from "../../../../components/InfoText/InfoText";

const DoorDetailsParent: FC<{}> = () => {
  const roomDetails: StandardRoom = useSelector((state: any) => {
    return state.heatLoad.room;
  });

  const doorDetailsEnabled: boolean = useSelector((state: any) => {
    return state.heatLoad.formSections.doorDetailsEnabled;
  });

  const roomDimensions: RoomDimensions = useSelector((state: any) => {
    return state.heatLoad.roomDimensions;
  });

  const roomTemperature = roomDetails.temperature;

  const roomVolume = roomDimensions.volume;

  let defaultValues: DoorDetailsForm | undefined;

  if (doorDetailsEnabled) {
    defaultValues = {
      doors: [
        {
          doorId: "1",
          type: "solid",
          material: "eps",
          usage: roomDetails.roomUsage,
          height: 2000,
          ...(roomVolume >= 80 && { width: 1500 }),
          ...(roomVolume < 80 && { width: 800 }),
          location: "1",
          numberOfDoors: 1,
          heatedFascia: roomTemperature >= 0 ? "9" : "16",
          heatedGlass: undefined,
        },
      ], //Initialise produce with 1 entry
    };
  }

  return doorDetailsEnabled && defaultValues !== undefined ? (
    <DoorDetailsContainer
      preloadedValues={defaultValues}
      roomDetails={roomDetails}
      doorDetailsEnabled={doorDetailsEnabled}
      roomDimensions={roomDimensions}
    />
  ) : (
    <InfoText testid="no-door-details">
      Add your Door details after you’ve defined your room and transmission
      details.
    </InfoText>
  );
};

export default DoorDetailsParent;
