import { default as heatLoadActions } from "./actions";

const saveStandardRoomDetails = heatLoadActions.saveStandardRoomDetails;
const saveWallDetails = heatLoadActions.saveWallDetails;
const saveCeilingDetails = heatLoadActions.saveCeilingDetails;
const saveFloorDetails = heatLoadActions.saveFloorDetails;
const saveRoomDimensions = heatLoadActions.saveRoomDimensions;
const saveProductDetails = heatLoadActions.saveProductDetails;
const saveHeatLoadCapacity = heatLoadActions.saveHeatLoadCapacity;
const saveHeatLoadFilters = heatLoadActions.saveHeatLoadFilters;
const saveDoorDetails = heatLoadActions.saveDoorDetails;
const savePeopleDetails = heatLoadActions.savePeopleDetails;
const saveSafetyAndRuntime = heatLoadActions.saveSafetyAndRuntime;
const saveLightingDetails = heatLoadActions.saveLightingDetails;
const saveForkliftDetails = heatLoadActions.saveForkliftDetails;
const saveVentilationDetails = heatLoadActions.saveVentilationDetails;
const saveFormSectionState = heatLoadActions.saveFormSectionState;
const saveTotalHeatLoad = heatLoadActions.saveTotalHeatLoad;
const saveAdvancedCalcState = heatLoadActions.saveAdvancedCalcState;
const clearAll = heatLoadActions.clearAll;

export default {
  saveStandardRoomDetails,
  saveWallDetails,
  saveCeilingDetails,
  saveFloorDetails,
  saveRoomDimensions,
  saveProductDetails,
  saveHeatLoadCapacity,
  saveHeatLoadFilters,
  saveDoorDetails,
  savePeopleDetails,
  saveSafetyAndRuntime,
  saveLightingDetails,
  saveForkliftDetails,
  saveVentilationDetails,
  saveFormSectionState,
  saveTotalHeatLoad,
  saveAdvancedCalcState,
  clearAll,
};
