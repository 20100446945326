import React from "react";
import styles from "./CommonInfoSection.module.css";

export interface CommonInfoSectionValues {
  labelText: string;
  value: number;
  uom: string;
}

interface CommonInfoSectionProps {
  commonInfo: CommonInfoSectionValues[];
  header: string;
}

const CommonInfoSection: React.FC<CommonInfoSectionProps> = ({
  commonInfo,
  header = "",
}) => {
  return (
    <section>
      <div>
        <div className="section-header">{header}</div>
        <div className={styles.valuesContainer}>
          {commonInfo.map((ci, i) => {
            return (
              <div className={styles.attributeGrid} key={i}>
                <p>{ci.labelText}</p>
                <p>
                  <b>{ci.value}</b> {ci.uom}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CommonInfoSection;
