import React from "react";

import { useQueryParams, useRoutes } from "hookrouter";
import ErrorPage from "./ErrorPage";
import ProductSelection from "./productSelection/page/ProductSelection";
import Dashboard from "./dashboard/page/Dashboard";
import PipeSizeCalculation from "./pipeSizeCalculation/page/PipeSizeCalculation";
import CondensingUnitSearch from "./productSelection/page/CondensingUnitSearch";
import EvaporatorSearch from "./productSelection/page/EvaporatorSearch";
import HeatLoad from "./heatLoad/page/HeatLoad";
import Login from "./authentication/page/Login";
import { getToken } from "./authentication/CognitoTokenService";
import { isValidCognitoUser } from "./authentication/CognitoUserPoolHelper";
import {
  readRedirectUrl,
  storeRedirectUrl,
} from "./helpers/applicationSession";

const authenticateRoutes = {
  // Based on https://github.com/Paratron/hookrouter/issues/38#issuecomment-484063929
  // Note: Do not include any leading "/"
  dashboard: () => <Dashboard />,
  "equipment-selection": () => <ProductSelection />,
  "condensing-unit-search": () => <CondensingUnitSearch />,
  "evaporator-search": () => <EvaporatorSearch />,
  "pipe-size-calculation": () => <PipeSizeCalculation />,
  "heat-load": () => <HeatLoad />,
};

const routes = {
  "/": () => <Login />,
  "/error": () => <ErrorPage />,
  "/auth-success": () => <OktaAuthSuccessful />,
  "/*": () => <AuthenticatePages />,
};

const AuthenticatePages = () => {
  const routeResult = useRoutes(authenticateRoutes);

  if (isValidCognitoUser()) {
    return routeResult || <ErrorPage />;
  } else {
    storeRedirectUrl(window.location.href);
    return <Login />;
  }
};

const OktaAuthSuccessful = () => {
  const [queryParams] = useQueryParams();
  const { code: authorizationCode = "" } = queryParams;

  if (authorizationCode) {
    let redirectUrl = dashboardUrl();
    if (readRedirectUrl()) redirectUrl = readRedirectUrl();

    getToken(redirectUrl, authorizationCode);
  }

  return <Login />;
};

const dashboardUrl = () => {
  let pathArray = window.location.href.split("/");
  return pathArray[0] + "//" + pathArray[2] + "/dashboard";
};

const AppRouter = () => {
  const routeResult = useRoutes(routes);
  return routeResult || <ErrorPage />;
};

export default AppRouter;
