import React from "react";
import Layout from "../../components/Layout/Layout";
import DashboardCard, {
  DashboardCardProps,
} from "../../components/DashboardCard/DashboardCard";
import styles from "./Dashboard.module.css";
import layoutStyles from "../../components/Layout/Layout.module.css";

const Dashboard: React.FC = () => {
  const cards: DashboardCardProps[] = [
    {
      title: "Heat load calculation",
      link: "/heat-load",
      imageFilename: "heatloadCalculation.svg",
    },
    {
      title: "Equipment selection",
      link: "/equipment-selection",
      imageFilename: "equipments.svg",
    },
    {
      title: "Pipe size calculation",
      link: "/pipe-size-calculation",
      imageFilename: "pipeCalculation.svg",
    },
    {
      title: "Accessories selection",
      link: "/",
      disabled: true,
      middleLabelText: "Coming soon",
      imageFilename: "accessories.svg",
    },
    {
      title: "Product documents",
      link: "/",
      disabled: true,
      middleLabelText: "Coming soon",
      imageFilename: "productDocumentation.svg",
    },
  ];

  const templateCards: DashboardCardProps[] = [
    {
      title: "Cool room",
      link: "/heat-load?type=cool",
      imageFilename: "coolRoom.svg",
    },
    {
      title: "Freezer room",
      link: "/heat-load?type=freezer",
      imageFilename: "freezerRoom.svg",
    },
  ];

  let givenName = sessionStorage.getItem("ve_user_given_name");
  let welcomeMessage = givenName !== null ? `Hi ${givenName}` : "Good day";

  return (
    <Layout>
      <div className={layoutStyles.contentContainer}>
        <h5>{welcomeMessage}</h5>

        <p>Tools that you can use today.</p>

        <div className={styles.dashboardCardContainer}>
          {cards.map((c) => {
            return (
              <DashboardCard
                title={c.title}
                link={c.link}
                disabled={c.disabled}
                imageFilename={c.imageFilename}
                middleLabelText={c.middleLabelText}
                key={c.title}
              />
            );
          })}
        </div>

        <p>Get started quickly with these templates.</p>

        <div className={styles.dashboardCardContainer}>
          {templateCards.map((c) => {
            return (
              <DashboardCard
                title={c.title}
                link={c.link}
                disabled={c.disabled}
                imageFilename={c.imageFilename}
                middleLabelText={c.middleLabelText}
                key={c.title}
              />
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
