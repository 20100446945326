import React, { FC } from "react";
import Section from "../Section/Section";
import Image from "../Image/Image";
import styles from "./AccordionSection.module.css";
import cx from "classnames";
export interface AccordionSectionProps {
  isOpen?: boolean;
  id: string;
  title: string;
  onClickHandler: (label: string) => void;
}

const AccordionSection: FC<AccordionSectionProps> = ({
  isOpen = false,
  id,
  title,
  children,
  onClickHandler,
}) => {
  const onClick = () => {
    onClickHandler(id);
  };

  return (
    <Section>
      <button
        onClick={onClick}
        className={styles.accordionContainer}
        data-testid={id}
      >
        <div className={styles.accordionTitle}>
          {title}
          <div style={{ float: "right" }}>
            <Image
              filename={isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
              altText={
                isOpen
                  ? `Close the ${title} section`
                  : `Open the ${title} section`
              }
            />
          </div>
        </div>
      </button>
      <div className={cx(!isOpen && styles.hideSection)}>{children}</div>
    </Section>
  );
};

export default AccordionSection;
