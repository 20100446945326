import React, { FC } from "react";
import { useSelector } from "react-redux";
import { HeatLoadCapacity } from "../../../calculations/heatload/load/TotalHeatLoad";
import { HeatLoadTotalCapacity } from "../../types/HeatLoadCapacity";
import HeatLoadLineDetails from "./HeatLoadLineDetails";

const HeatLoadLineDetailsContainer: FC = () => {
  const totalHeatLoad: HeatLoadCapacity[] = useSelector(
    (state: any) => state.heatLoad.totalHeatLoad
  );

  const heatLoadCapacity: HeatLoadTotalCapacity = useSelector(
    (state: any) => state.heatLoad.heatLoadCapacity
  );

  return (
    <HeatLoadLineDetails
      totalHeatLoad={totalHeatLoad}
      heatLoadCapacity={heatLoadCapacity}
    />
  );
};

export default HeatLoadLineDetailsContainer;
