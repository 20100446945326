import React, { FC } from "react";
import styles from "./PrintFooter.module.css";

const PrintFooter: FC = () => {
  return (
    <div className={styles.footerContainer}>
      <p>
        Disclaimer: While all care is taken in giving you the most accurate
        calculations for your specific application, Actrol accepts no
        responsibility for problems arising from results and/or application of
        the results in relation to equipment selection. Location data, product
        data and all other information contained herein is deemed to be correct
        at time of creation and no responsibility is accepted for
        variances/inaccuracy or otherwise.
      </p>
    </div>
  );
};

export default PrintFooter;
