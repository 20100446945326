import React from "react";
import { EvaporatorProductCapacity } from "../../../types/EvaporatorProductCapacity";
import styles from "../SelectedEquipment/SelectedEquipment.module.css";
import SelectedProductPlaceholder from "../../../components/SelectedProductPlaceholder/SelectedProductPlaceholder";
import EvaporatorCard from "../EvaporatorCard/EvaporatorCard";

type Props = Ready | Loading;

interface Ready {
  evaporator: EvaporatorProductCapacity;
  refrigerant: string;
  loading?: false;
}

interface Loading {
  evaporator?: EvaporatorProductCapacity;
  refrigerant?: string;
  loading?: true;
}

const SelectedEvaporator: React.FC<Props> = ({
  evaporator,
  refrigerant,
  loading,
}) => {
  return (
    <section className={styles.container}>
      <div className="section-header">Selected evaporator</div>

      {loading ? (
        <SelectedProductPlaceholder
          heading="No evaporator selected"
          subheading="Add details in search to select evaporator"
        />
      ) : (
        <EvaporatorCard evaporator={evaporator!} refrigerant={refrigerant!} />
      )}
    </section>
  );
};

export default SelectedEvaporator;
