import React from "react";
import styles from "./TableLoadingPlaceholder.module.css";
import { Table, Header, HeaderCell, Body, Cell, Row } from "./Table";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";
import ThumbnailLoadingPlaceholder from "../Thumbnail/ThumbnailLoadingPlaceholder";

const TextItem = () => (
  <div className={styles.textPlaceholder}>
    <LoadingPlaceholder />
  </div>
);

const TableLoadingPlaceholder = () => (
  <Table>
    <Header>
      <Row>
        <HeaderCell>
          <TextItem />
        </HeaderCell>
        <HeaderCell>
          <TextItem />
        </HeaderCell>
        <HeaderCell>
          <TextItem />
        </HeaderCell>
        <HeaderCell>
          <TextItem />
        </HeaderCell>
      </Row>
    </Header>
    <Body>
      {Array.from({ length: 8 }, (_, i) => (
        <Row key={i}>
          <Cell>
            <ThumbnailLoadingPlaceholder />
          </Cell>
          <Cell>
            <TextItem />
          </Cell>
          <Cell></Cell>
          <Cell></Cell>
        </Row>
      ))}
    </Body>
  </Table>
);

export default TableLoadingPlaceholder;
