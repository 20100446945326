import {
  clearAuthToken,
  clearVEUser,
  storeAuthToken,
  storeVEUser,
} from "../helpers/applicationSession";

declare const window: any;

const authServiceDomain = window.__runtime_configuration.ENDPOINT_AUTH;

const getToken = (redirectURL: string, authorizationCode: string) => {
  let url = new URL(`${authServiceDomain}/auth/get-security-token`);
  let params = { code: authorizationCode };
  url.search = new URLSearchParams(params).toString();

  // Use (Okta) authorization code and check it against AWS Cognito endpoint to obtain Cognito tokens
  fetch(url.href)
    .then((r) => r.json())
    .then((data) => {
      storeAuthToken({
        idToke: data.token.cognito_id_token,
        accessToke: data.token.cognito_access_token,
        refreshToken: data.token.cognito_refresh_token,
        expireIn: data.token.cognito_expires_in,
        tokenType: data.token.cognito_token_type,
      });

      storeVEUser({
        userId: null,
        email: data.token.email,
        givenName: data.token.given_name,
      });

      window.location.assign(redirectURL);
    })
    .catch((e) => {
      console.log(`Error occured while authenticating user: `, e);
    });

  // Dashboard is rendered for now while waiting for fetch response (which then redirects to the intended location)
  // Perhaps we need a loading spinner in the future?
  //window.location.assign(redirectURL);
};

const loginRedirect = () => {
  return `${authServiceDomain}/auth/create-session`;
};

const logoutRedirect = () => {
  clearAuthToken();
  clearVEUser();
  return `${authServiceDomain}/auth/delete-session`;
};

export { getToken, loginRedirect, logoutRedirect };
