import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PrintSectionHeader from "../../../components/PrintTemplate/PrintSectionHeader";
import PrintTemplate from "../../../components/PrintTemplate/PrintTemplate";
import styles from "../../../components/PrintTemplate/PrintTemplate.module.css";
import { StandardRoom } from "../../../types/HeatLoad";
import { HeatLoadTotalCapacity } from "../../types/HeatLoadCapacity";
import { ProductLine } from "../../types/products";
import CalculationSummaryPieChart from "../CalculationSummary/CalculationSummaryPieChart";
import CalculationSummaryTable from "../CalculationSummary/CalculationSummaryTable";
import { PreviewContainer } from "../RoomPreview/PreviewContainer";
import AdditionalLoadsOutput from "./AdditionalLoadsOutput";
import DoorsOutput from "./DoorsOutput";
import LocationAndRoomOutput from "./LocationAndRoomOutput";
import ProductsOutput from "./ProductsOutput";
import RoomConstructionOutput from "./RoomConstructionOutput";
import SafetyFactorAndRuntimeOutput from "./SafetyFactorAndRuntimeOutput";

const HeatLoadPrint: FC<{ showPrintTemplate: boolean }> = ({
  showPrintTemplate,
}) => {
  const roomDetails: StandardRoom = useSelector((state: any) => {
    return state.heatLoad.room;
  });

  const productLines: ProductLine[] = useSelector((state: any) => {
    return state.heatLoad.productLines;
  });

  const advancedCalcState = useSelector((state: any) => {
    return state.heatLoad.advancedCalculation;
  });

  const [heatLoadSummaryView, setHeatLoadSummaryView] = useState({
    productLoad: { sensible: 0, latent: 0 },
    transmissionLoad: { sensible: 0, latent: 0 },
    infiltrationLoad: { sensible: 0, latent: 0 },
    internalLoad: { sensible: 0, latent: 0 },
    totalLoad: { sensible: 0, latent: 0 },
    otherLoad: { total: 0 },
    safetyLoad: { safetyFactor: 0, total: 0 },
    fanLoad: { fanFactor: 0, fanLoad: 0, total: 0 },
    runTimeTotal: { runtime: 0, total: 0 },
  });

  const heatLoadCapacity: HeatLoadTotalCapacity = useSelector(
    (state: any) => state.heatLoad.heatLoadCapacity
  );

  useEffect(() => {
    setHeatLoadSummaryView(heatLoadCapacity);
  }, [heatLoadCapacity]);

  let productString = "";

  productLines.forEach((p, i) => {
    productString =
      productString +
      p.product.name +
      (productLines.length > 1 && i === productLines.length - 2
        ? " & "
        : i < productLines.length - 1
        ? ", "
        : "");
  });

  const fanLoadTotal = heatLoadSummaryView.fanLoad.total / 1000;

  return (
    <PrintTemplate
      pageTitle="Heat load calculation"
      showPrintTemplate={showPrintTemplate}
    >
      <div className="page-break">
        <div className={styles.pageTitleContainer}>
          <div>
            <div className="page-header">Calculation results</div>
            <div className={styles.subHeading}>
              {roomDetails.temperature >= 0 ? "Cool  " : "Freezer "} room
              designed for {productString}.
            </div>
          </div>
          <div className={styles.totalsContainer}>
            Required equipment capacity
            <div className={styles.totalsHeading}>
              {fanLoadTotal.toFixed(1)} kW
            </div>
            @ {heatLoadSummaryView.runTimeTotal.runtime} hr runtime
          </div>
        </div>
        <div>
          <table className={styles.tablePrint}>
            <tbody>
              <tr>
                <td width="45%">
                  <PreviewContainer />
                  <CalculationSummaryPieChart
                    heatLoadSummary={heatLoadSummaryView}
                  />
                </td>
                <td width="55%">
                  <CalculationSummaryTable
                    heatLoadSummary={heatLoadSummaryView}
                    roomTemperature={roomDetails.temperature}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  The heat load calculation above is based on the information
                  provided. Please check all inputs on the folllowing pages.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="page-break">
        <div className="page-header">Calculation inputs</div>

        <div className={styles.avoidPageBreak}>
          <PrintSectionHeader>Location and room details</PrintSectionHeader>

          <LocationAndRoomOutput
            roomDetails={roomDetails}
            advancedCalc={advancedCalcState}
          />
        </div>

        <div className={styles.avoidPageBreak}>
          <PrintSectionHeader>Room construction</PrintSectionHeader>

          <RoomConstructionOutput />
        </div>

        <div className={styles.avoidPageBreak}>
          <PrintSectionHeader>Doors</PrintSectionHeader>

          <DoorsOutput advancedCalc={advancedCalcState} />
        </div>

        <div className={styles.avoidPageBreak}>
          <PrintSectionHeader>Products</PrintSectionHeader>

          <ProductsOutput productLines={productLines} />
        </div>

        <div className={styles.avoidPageBreak}>
          <PrintSectionHeader>Additional loads</PrintSectionHeader>

          <AdditionalLoadsOutput />
        </div>

        <div className={styles.avoidPageBreak}>
          <PrintSectionHeader>Safety factor and runtime</PrintSectionHeader>

          <SafetyFactorAndRuntimeOutput />
        </div>
      </div>
    </PrintTemplate>
  );
};

export default HeatLoadPrint;
