import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BalancedPerformance from "./BalancedPerformance";
import { BalancePerformanceModel } from "../../../types/BalancePerformanceModel";
import { EvaporatorProductCapacity } from "../../../types/EvaporatorProductCapacity";
import { CapitalEquipmentFilterModel } from "../../../types/CapitalEquipmentFilterModel";

export const BalancedPerformanceContainer: React.FC<{}> = () => {
  const [balancePerformanceValue, setBalancePerformanceValue] =
    useState<BalancePerformanceModel>({
      sst: 0,
      sct: 0,
      capacity: 0,
      ktd: 0,
      runtime: 0,
      power: 0,
      cop: 0,
    });
  const filters: CapitalEquipmentFilterModel = useSelector(
    (state: any) => state.product.filter
  );

  const loadingBalancePerformance: boolean = useSelector(
    (state: any) => state.product.loadingBalancePerformance
  );

  const selectedEvaporator: EvaporatorProductCapacity = useSelector(
    (state: any) => state.product.selectedEvaporator
  );

  useEffect(() => {
    if (
      selectedEvaporator.balance_performance &&
      selectedEvaporator.operating_capacity
    ) {
      const capacity: number =
        selectedEvaporator.balance_performance.balance_capacity *
        filters.noOfCondensingUnits;
      const power: number =
        selectedEvaporator.balance_performance.balance_power;

      setBalancePerformanceValue({
        sst: selectedEvaporator.balance_performance.evaporator_temperature,
        sct: selectedEvaporator.balance_performance.condenser_temperature,
        capacity: capacity,
        ktd:
          filters.roomTemp -
          selectedEvaporator.balance_performance.evaporator_temperature,
        runtime:
          (filters.runtime * filters.load) /
          (selectedEvaporator.balance_performance.balance_capacity *
            filters.noOfCondensingUnits),
        power: power,
        cop: capacity / power,
      });
    }
  }, [filters, selectedEvaporator]);

  return (
    <>
      <BalancedPerformance
        balance={balancePerformanceValue}
        isLoading={loadingBalancePerformance}
      />
    </>
  );
};
