import React, { FC } from "react";
import SelectedProductPlaceholder from "../../components/SelectedProductPlaceholder/SelectedProductPlaceholder";
import {
  PipeSizeCalculations,
  UnitTypes,
} from "../../types/PipeSizeCalculationResponse";
import styles from "./SelectedPipes.module.css";
import isAnEmptyObject from "../../helpers/emptyObject";
import ProductCard from "../../components/ProductCard/ProductCard";

interface SelectedPipesProps {
  selectedLiquidLineHorizontalPipe: PipeSizeCalculations;
  selectedSuctionLineHorizontalPipe: PipeSizeCalculations;
  selectedDischargeLineHorizontalPipe: PipeSizeCalculations;
  selectedLiquidLineVerticalPipe: PipeSizeCalculations;
  selectedSuctionLineVerticalPipe: PipeSizeCalculations;
  selectedDischargeLineVerticalPipe: PipeSizeCalculations;
  selectedPressureDropUom: string;
  isLoading: boolean;
  unitTypes: UnitTypes;
}

interface IndividualPipeProps {
  title: string;
  selectedPipe: PipeSizeCalculations;
}

const SelectedPipes: FC<SelectedPipesProps> = ({
  selectedLiquidLineHorizontalPipe,
  selectedSuctionLineHorizontalPipe,
  selectedDischargeLineHorizontalPipe,
  selectedLiquidLineVerticalPipe,
  selectedSuctionLineVerticalPipe,
  selectedDischargeLineVerticalPipe,
  selectedPressureDropUom,
  isLoading,
  unitTypes,
}) => {
  const IndividualPipe: FC<IndividualPipeProps> = ({ title, selectedPipe }) => {
    let velocity =
      title === "Horizontal"
        ? selectedPipe.horizontal.velocity
        : selectedPipe.vertical.velocity;
    let saturatedTemp =
      title === "Horizontal"
        ? selectedPipe.horizontal.temperature_difference
        : selectedPipe.vertical.temperature_difference;
    let pressureDrop = 0;

    if (selectedPressureDropUom === "kPa") {
      pressureDrop =
        title === "Horizontal"
          ? selectedPipe.horizontal.total_pressure_drop
          : selectedPipe.vertical.total_pressure_drop;
    } else if (selectedPressureDropUom === "psi") {
      pressureDrop =
        title === "Horizontal"
          ? selectedPipe.horizontal.total_pressure_drop_psi
          : selectedPipe.vertical.total_pressure_drop_psi;
    }

    let productDescriptionValues = [
      {
        label: "Product code",
        value: selectedPipe.id.toString(),
      },
      {
        label: "Velocity",
        value: `${velocity} ${unitTypes["velocity"]}`,
      },
      {
        label: "Δ Pressure",
        value: `${pressureDrop} ${selectedPressureDropUom}`,
      },
      {
        label: "Δ Saturation temp",
        value: `${saturatedTemp} ${unitTypes["temperature_difference"]}`,
      },
    ];

    return (
      <div className={styles.productHeading}>
        <div>{title}</div>

        <ProductCard
          title={`${selectedPipe.od_in} ${unitTypes["od_in"]}`}
          productDescriptionValues={productDescriptionValues}
          linkId={selectedPipe.id}
        />
      </div>
    );
  };

  const SelectedPipeCards = () => {
    return (
      <>
        {(!isAnEmptyObject(selectedLiquidLineHorizontalPipe) ||
          !isAnEmptyObject(selectedLiquidLineVerticalPipe)) && (
          <div>
            <strong>Liquid</strong>
          </div>
        )}
        {!isAnEmptyObject(selectedLiquidLineHorizontalPipe) && (
          <IndividualPipe
            title="Horizontal"
            selectedPipe={selectedLiquidLineHorizontalPipe}
          />
        )}
        {!isAnEmptyObject(selectedLiquidLineVerticalPipe) && (
          <IndividualPipe
            title="Vertical"
            selectedPipe={selectedLiquidLineVerticalPipe}
          />
        )}
        {(!isAnEmptyObject(selectedSuctionLineHorizontalPipe) ||
          !isAnEmptyObject(selectedSuctionLineVerticalPipe)) && (
          <div>
            <strong>Suction</strong>
          </div>
        )}
        {!isAnEmptyObject(selectedSuctionLineHorizontalPipe) && (
          <IndividualPipe
            title="Horizontal"
            selectedPipe={selectedSuctionLineHorizontalPipe}
          />
        )}
        {!isAnEmptyObject(selectedSuctionLineVerticalPipe) && (
          <IndividualPipe
            title="Vertical"
            selectedPipe={selectedSuctionLineVerticalPipe}
          />
        )}
        {(!isAnEmptyObject(selectedDischargeLineHorizontalPipe) ||
          !isAnEmptyObject(selectedDischargeLineVerticalPipe)) && (
          <div>
            <strong>Discharge</strong>
          </div>
        )}
        {!isAnEmptyObject(selectedDischargeLineHorizontalPipe) && (
          <IndividualPipe
            title="Horizontal"
            selectedPipe={selectedDischargeLineHorizontalPipe}
          />
        )}
        {!isAnEmptyObject(selectedDischargeLineVerticalPipe) && (
          <IndividualPipe
            title="Vertical"
            selectedPipe={selectedDischargeLineVerticalPipe}
          />
        )}
      </>
    );
  };

  return (
    <section>
      <div className="section-header">Pipe selection</div>

      {isLoading ? (
        <SelectedProductPlaceholder
          heading="No pipes selected"
          subheading="Add details for pipe size calculation to select pipes"
        />
      ) : (
        <SelectedPipeCards />
      )}
    </section>
  );
};

export default SelectedPipes;
