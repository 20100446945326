import React, { forwardRef } from "react";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import styles from "./RadioGroup.module.css";
import cx from "classnames";
import { UseFormRegister } from "react-hook-form";

export interface RadioValue {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface RadioProps {
  labelText: string;
  placeholderText?: string;
  values: RadioValue[];
  hasErrors: boolean;
  errorMessage?: string;
  hasBorder?: boolean;
}

const RadioGroup = forwardRef<
  HTMLInputElement,
  RadioProps & ReturnType<UseFormRegister<any>>
>(
  (
    {
      name,
      labelText,
      values,
      hasErrors,
      errorMessage = "",
      hasBorder = false,
      onChange,
      onBlur,
    },
    ref
  ) => (
    <div>
      <label htmlFor={name}>{labelText}</label>
      <div
        className={cx(
          styles.radioGroup,
          hasBorder && styles.radioFlexContainer
        )}
      >
        {values.map((radio) => {
          return (
            <label
              key={radio.value}
              className={cx(
                styles.radioLabel,
                hasBorder && styles.radioWithBorder,
                radio.disabled && styles.radioLabelDisabled
              )}
            >
              <input
                name={name}
                type="radio"
                className={styles.radioStyle}
                data-lpignore="true"
                autoComplete="off"
                data-testid={name}
                value={radio.value}
                ref={ref}
                disabled={radio.disabled}
                onChange={onChange}
                onBlur={onBlur}
              />
              {radio.label}
            </label>
          );
        })}
      </div>
      {hasErrors && <ErrorMessage errorText={errorMessage} />}
    </div>
  )
);

export default RadioGroup;
